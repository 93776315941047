// src/hooks/useCreateLunchOrder.js
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { axiosClient } from '../axiosInstance';
import { toast } from "react-toastify";

// Create a new lunch order
export const useCreateLunchAndSupperOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (newOrder) => {
            const { orders } = newOrder;

            return axiosClient.post(`user/lunchAndSupperOrder/new`, { orders }).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('lunchAndSupperOrders');
            },
        }
    );
};
export const useCreateMultiLunchAndSupperOrder = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (newOrder) => {
            const { orders } = newOrder;
            return axiosClient.post(`user/lunchAndSupperOrder_multi/new`, { orders })
                .then((res) => {
                    toast(res.data.msg);
                });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('lunchAndSupperOrders');
            },
        }
    );
};

// Fetch orders by weekOfYear
export const useFetchLunchAndSupperOrders = (weekNumber) => {
    return useQuery(
        ['lunchAndSupperOrders', weekNumber],
        async () => {
            const { data } = await axiosClient.get(`user/lunchAndSupperOrder/fetch/${weekNumber.weekNumber}`);
            return {data};
        },
        {
            enabled: !!weekNumber, // Only run the query if weekOfYear is provided
        }
    );
};
export const useFetchLunchAndSupperOrdersAdmin = ({ weekNumber }) => {
    return useQuery(
        ['lunchAndSupperOrders', weekNumber], // Add userId to the query key
        async () => {
            const { data  }= await axiosClient.get(`user/lunchAndSupperOrder/fetch_for_admin/${weekNumber}`)
                return {data};
        },
        {
            enabled: !!weekNumber, // Only run the query if both weekNumber and userId are provided
        }
    );
};
export const useFetchLunchAndSupperOrdersForCalculate = ({ weekNumber, weekday, alternative }) => {
    return useQuery(
        ['lunchAndSupperOrders', { weekNumber, alternative, weekday }], // Add userId to the query key
        async () => {
            const { data } = await axiosClient.get(`user/lunchOrder/fetch_for_calculate/${weekNumber}&${alternative}&${weekday}`);
            return data;
        },
        {
            enabled: !!weekNumber, // Only run the query if both weekNumber and userId are provided
        }
    );
};

// Update lunch order
export const useUpdateLunchAndSupperOrder = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (orderUpdate) => {
            const { weekNumber,lunchOrders } = orderUpdate;
            return axiosClient.patch(`user/lunchAndSupperOrder/modify`, {
                weekNumber,lunchOrders
            }).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('useFetchLunchAndSupperOrders');
            },
        }
    );
};


export const useUpdateLunchAndSupperOrders = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ userId, weekNumber, lunchOrders }) => {
            return await axiosClient.put(`user/lunchAndSupperOrders/modify`, { userId, weekNumber, lunchOrders })
                .then((res) => {
                    toast(res.data.msg);
                })
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('fetchLunchAndSupperOrders'); // Refetch data after update
            }
        }
    )
}
// Delete entire lunch order
export const useDeleteLunchAndSupperOrders = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ userId, weekNumber }) => {
            return axiosClient.delete(`user/lunchAndSupperOrders/delete`, { data: { userId, weekNumber } }).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('fetchLunchAndSupperOrders');
            },
        }
    );
};
export const useDeleteSingleLunchAndSupperOrders = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ userId, weekNumber }) => {
            return axiosClient.delete(`user/lunchAndSupperOrders/singleUser/delete`, { data: { userId, weekNumber } }).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('fetchLunchAndSupperOrders');
            },
        }
    );
};