import React, { useEffect, useState } from "react";
import { CreateMenu, UpdateMenu, getPublicDishImages, getMenuTemplates, getSpecialType } from "../../../../../hooks/admin/superiorAdmin";
import { getMenuById } from '../../../../../hooks/menu/menu';
import { getSideTemplate, getSideTemplates, getMenuForSetting } from "../../../../../hooks/admin/superiorAdmin";
import { useQuery, useQueryClient } from "react-query";
import { useForm, Controller } from "react-hook-form";
import { getAllValueFromSelect } from '../../../../../utility/utility';
import SideIngredientSelection from './sideIngredientSelection';
import MenuUpdate from './menuUpdate';
import { Accordion, Modal, Row, Col, Card } from "react-bootstrap";
import Spinner from "../../../../../Partials/Spinner";
import TagIcons from "./tagIconsWindow";

const MenuSetting = () => {
  const queryClient = useQueryClient();

  // Tag icons setup
  const [showIcons, setShowIcons] = useState(false);
  const handleCloseIcon = () => setShowIcons(false);
  const handleShowIcon = () => setShowIcons(true);
  const [selectTagIcon, setSelectTagIcon] = useState({ tagIcon: './imgsAndVideos/defaultIcon.png' });
  const changeSelection = (newSelection) => setSelectTagIcon(newSelection);

  // Menu data
  const [id, setId] = useState('63793934371bc9e6500f982d');
  const [selectedOption, setSelectedOption] = useState({ _id: '63793934371bc9e6500f982d' });
  const [templateName, setTemplateName] = useState("");
  const [sideTemplateName, setSideTemplateName] = useState("");

  const { data: menu, error, isLoading, refetch } = useQuery(
    ['getMenu', { templateName, sideTemplateName }],
    () => getMenuForSetting({ templateName, sideTemplateName }),
    {
      enabled: !!templateName && !!sideTemplateName,
      onSuccess: (data) => {
        if (data?.data?.[0]) {
          setId(data.data[0]._id);
        }
      }
    }
  );

  const { data: menuById } = useQuery(['getMenuById', id], () => getMenuById(id), { enabled: !!id });
  const { data: publicImages } = useQuery('getPublicImages', getPublicDishImages);
  const { data: menuTemplates } = useQuery("getMenuTemplates", getMenuTemplates);

  const [path, setPath] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Toggle states
  const [userMakeOwnMainDish, setUserMakeOwnMainDish] = useState(false);
  const [userMakeOwnSide, setUserMakeOwnSide] = useState(false);
  const [userMakeOwnSauce, setUserMakeOwnSauce] = useState(false);
  const [group, setGroup] = useState('');

  const { data: getSides } = useQuery(
    ['getSideTemplates', group],
    () => getSideTemplates(group),
    { enabled: !!group }
  );

  const { data: SideTemplate } = useQuery(
    ['getSideTemplate', { group, sideTemplateName }],
    () => getSideTemplate({ group, sideTemplateName }),
    { enabled: !!group && !!sideTemplateName }
  );

  const { mutate: createMenu, isLoading: isCreating, error: createError } = CreateMenu();
  const { mutate: updateMenu, isLoading: isUpdating, error: updateError } = UpdateMenu();

  // Form setup with react-hook-form
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
    watch,
    reset,
    trigger
  } = useForm({
    mode: "onChange",
    defaultValues: {
      dishName: "",
      template: "",
      sideTemplate: "",
      mainIngredient: "Ingredients",
      mainIngredientPerGuest: "",
      mainIngredientUnit: "",
      sideIngredient: "",
      sideIngredientPerGuest: "",
      sideIngredientUnit: "",
      sauseSoupPerGuest: "0",
      completelyDish: false,
      extraSides: [],
    },
  });

  const { data: type } = useQuery("getSpecialtype", getSpecialType);
  const [chosenTypes, setChosenTypes] = useState([]);

  // Watch fields for dynamic validation
  const template = watch('template');
  const sideTemplate = watch('sideTemplate');
  const completelyDish = watch('completelyDish');

  // Sync templateName and sideTemplateName with form values
  useEffect(() => {
    if (template) {
      setTemplateName(template);
      if (sideTemplateName) {
        queryClient.invalidateQueries(['getMenu', { templateName: template, sideTemplateName }]);
      } else {
        queryClient.removeQueries(['getMenu']);
      }
    }
  }, [template, queryClient, sideTemplateName]);

  useEffect(() => {
    if (sideTemplate) {
      setSideTemplateName(sideTemplate);
      if (templateName) {
        queryClient.invalidateQueries(['getMenu', { templateName, sideTemplateName: sideTemplate }]);
      } else {
        queryClient.removeQueries(['getMenu']);
      }
    } else {
      setSideTemplateName("");
    }
  }, [sideTemplate, queryClient, templateName]);

  useEffect(() => {
    setGroup(selectedOption?.group);
  }, [selectedOption]);

  // Toggle special type
  const toggleSelection = (type) => {
    setChosenTypes(prev =>
      prev.includes(type)
        ? prev.filter(chosenType => chosenType !== type).sort()
        : [...prev, type].sort()
    );
  };

  // Handle toggle changes
  const handleUserMakeOwnMainDish = () => setUserMakeOwnMainDish(prev => !prev);
  const handleCompletelyDish = () => {
    trigger(['sideIngredient', 'sideIngredientPerGuest', 'sideIngredientUnit', 'extraSides']);
  };
  const handleUserMakeOwnSide = () => setUserMakeOwnSide(prev => !prev);
  const handleUserMakeOwnSauce = () => setUserMakeOwnSauce(prev => !prev);

  // Custom validation for sides
  const validateSides = (value, fieldName) => {
    if (completelyDish) return true;
    if (!value && value !== 0) return `Fyll i ${fieldName} när helrätt inte är vald`;
    if (typeof value === "number") {
      return value >= 0 || `Fyll i ett giltigt värde för ${fieldName}`;
    }
    return value.trim() !== "" || `Fyll i ${fieldName} när helrätt inte är vald`;
  };

  // Form submission
  const onSubmit = async (data) => {
    const imagePath = { path };
    const transformedExtraSides = (data.extraSides || []).map(side => ({
      extraSideIngredient: side.extraSideIngredient,
      extraSideIngredientPerGuest: Number(side.extraSideIngredientPerGuest),
      extraSideIngredientUnit: side.extraSideIngredientUnit
    }));

    const submitData = {
      ...data,
      allergic: chosenTypes.sort(),
      imagePath,
      templateName: data.template,
      group,
      templateActived: selectedOption?.templateActived,
      completelyDish,
      makeOwnMainDish: userMakeOwnMainDish,
      makeOwnSide: userMakeOwnSide,
      makeOwnSauce: userMakeOwnSauce,
      extraSides: transformedExtraSides,
    };

    try {
      if (!menu?.data || menu?.data.length === 0) {
        await createMenu(submitData, {
          onSuccess: (response) => {
            setId(response?.data?._id || menu?.data[0]?._id);
            reset({
              dishName: "",
              sideIngredient: "",
              sideIngredientPerGuest: "",
              sideIngredientUnit: "",
              sauseSoupPerGuest: "0",
              extraSides: [],
            });
          },
          onError: (err) => {
            console.error("Create error:", err.response?.data || err.message);
          },
        });
      } else if (menu?.data.length !== 0 && data.template !== menuById?.data[0].templateName) {
        await createMenu(submitData, {
          onSuccess: () => {
            reset({
              dishName: "",
              sideIngredient: "",
              sideIngredientPerGuest: "",
              sideIngredientUnit: "",
              sauseSoupPerGuest: "0",
              extraSides: [],
            });
          },
          onError: (err) => {
            console.error("Create error:", err.response?.data || err.message);
          },
        });
      } else {
        await updateMenu({ id, submitData }, {
          onSuccess: () => {
            reset({
              dishName: "",
              sideIngredient: "",
              sideIngredientPerGuest: "",
              sideIngredientUnit: "",
              sauseSoupPerGuest: "0",
              extraSides: [],
            });
          },
          onError: (err) => {
            console.error("Update error:", err.response?.data || err.message);
          },
        });
      }
    } catch (err) {
      console.error("Submission error:", err);
    }
  };

  if (error) return <div>Nånting gick fel</div>;
  if (isLoading) return (
    <div style={{ height: '100vh' }}>
      <Spinner />
    </div>
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" className="overflow">
        <div className="mt-5 mb-5 w-75 m-auto">
          {(createError || updateError) && (
            <div className="alert alert-danger">
              <p>Submission failed: {createError?.response?.data?.msg || updateError?.response?.data?.msg || "Unknown error"}</p>
            </div>
          )}
          {Object.keys(errors).length > 0 && (
            <div className="alert alert-danger">
              <p>Please fix the following errors:</p>
              <ul>
                {Object.entries(errors).map(([field, error]) => (
                  <li key={field}>{error.message || "Invalid value"}</li>
                ))}
              </ul>
            </div>
          )}
          {errors.dishName && <p className="ms-1 p-1 error fs-6">{errors.dishName.message}</p>}
          <input
            className="form-control"
            type="text"
            name="dishName"
            placeholder="Fyll i maträttens namnet"
            {...register("dishName", {
              required: "Fyll i maträttens namnet",
            })}
          />
          {errors.template && <p className="ms-1 p-1 error fs-6">{errors.template.message}</p>}
          <select
            name="template"
            className="form-select mt-4"
            {...register("template", {
              required: "Välj mallen",
            })}
            onBlur={(e) => getAllValueFromSelect(e, menuTemplates?.data?.templates, setSelectedOption)}
          >
            <option value="">Välj meny mallen</option>
            {menuTemplates?.data?.templates.map((item, index) => (
              <option key={index} value={item.templateName}>{item.templateName}</option>
            ))}
          </select>
          {errors.sideTemplate && <p className="ms-1 p-1 error fs-6">{errors.sideTemplate.message}</p>}
          <select
            name="sideTemplateName"
            className="form-select mt-4 mb-4"
            {...register("sideTemplate", {
              required: "Välj tillbehör mallen",
            })}
          >
            <option value="">Välj tillbehör mallen</option>
            {getSides?.data?.map((item) => (
              <option key={item._id} value={item.sideTemplateName}>
                {item.sideTemplateName}
              </option>
            ))}
          </select>
          <div className="mb-3">
            <span className="small mb-2 mt-2 ms-2 d-block">Mallen till rättens innehåll</span>
            <Accordion defaultActiveKey="0" className="w-100 m-auto">
              <Accordion.Item eventKey="1">
                <Accordion.Header className="routineLayout">Huvudkomponent</Accordion.Header>
                <Accordion.Body>
                  <textarea
                    className='mb-4 mt-2 w-100 form-control ms-2'
                    name='mainIngredient'
                    {...register('mainIngredient', { maxLength: 6000 })}
                  />
                  {errors.mainIngredientPerGuest && <p className="ms-1 p-1 error fs-6">{errors.mainIngredientPerGuest.message}</p>}
                  <input
                    className="form-control"
                    type="number"
                    step={0.001} // Note: should be a string "0.01" for HTML attribute
                    placeholder="Standard mängd per gäst"
                    {...register("mainIngredientPerGuest", {
                      required: "Fyll i standard mängd per gäst",
                      valueAsNumber: true, // Ensures the value is parsed as a number
                      min: { value: 0, message: "Värdet får inte vara negativt" }, // Optional: enforce non-negative
                    })}
                  />
                  {errors.mainIngredientUnit && <p className="ms-1 p-1 error fs-6">{errors.mainIngredientUnit.message}</p>}
                  <select
                    {...register("mainIngredientUnit", {
                      required: "Välj enhet",
                    })}
                    className="form-select mt-4 mb-4"
                  >
                    <option value="">Välj enhet</option>
                    <option value="Kilo">Kilo</option>
                    <option value="Liter">Liter</option>
                    <option value="Styck">Styck</option>
                    <option value="L-bleck">L-bleck</option>
                  </select>
                  <Row className="mb-3 mt-3 ms-4">
                    <Col xs={10}><span className="small mb-2 d-block">Utför egen huvudrätt</span></Col>
                    <Col xs={2}><input type="checkbox" checked={userMakeOwnMainDish} onChange={handleUserMakeOwnMainDish} /></Col>
                    <Col xs={10}><span className="small mb-2 d-block">Helrätt</span></Col>
                    <Col xs={2}>
                      <input
                        type="checkbox"
                        {...register("completelyDish", {
                          onChange: handleCompletelyDish,
                        })}
                      />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
              <SideIngredientSelection
                register={register}
                SideTemplate={SideTemplate}
                userMakeOwnSide={userMakeOwnSide}
                handleUserMakeOwnSide={handleUserMakeOwnSide}
                userMakeOwnSauce={userMakeOwnSauce}
                handleUserMakeOwnSauce={handleUserMakeOwnSauce}
                errors={errors}
                completelyDish={completelyDish}
                validateSides={validateSides}
                defaultExtraSides={menu?.data[0]?.extraSides || []}
              />
            </Accordion>
            <hr />
            <span className="text-light font-size-s pb-2">Innehåll</span>
            <div className="special-type-group font-size-md">
              {type?.data?.Special_type[0]?.special_type?.map((type, index) => (
                <button
                  className={chosenTypes.includes(type.s_type) ? "special_type_light button_size cursor" : 'button_size special_type cursor'}
                  key={index}
                  onClick={() => toggleSelection(type.s_type)}
                  type="button"
                >
                  {type.s_type}
                </button>
              ))}
            </div>
            <hr />
          </div>

          <Row>
            <Col xs={11}>
              <button onClick={handleShowIcon} type='button' className="w-100 text-light mainButton" id="choose">
                Välj tag för rätten
              </button>
            </Col>
            <Col xs={1}>
              <img className="mt-4" src={selectTagIcon.tagIcon} alt="tagIcon" />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 12 }} className='mb-3'>
              <span className="small mb-2 d-block">Välj bild från server</span>
              <button className="stats_card w-100" onClick={handleShow} type="button"><span>Välj bild</span></button>
            </Col>
            <Col md={{ span: 12 }}>
              <span className="small mb-2 d-block">Uppladda egen bild</span>
              <Controller
                control={control}
                name="dishImage"
                render={({ field: { value, onChange, ...field } }) => (
                  <input
                    {...field}
                    value={value?.fileName}
                    onChange={(event) => onChange(event.target.files[0])}
                    type="file"
                    id="dishImage"
                    className="mb-3 form-control"
                  />
                )}
              />
            </Col>
          </Row>
          <button
            className="stats_card w-100"
            type="submit"
            disabled={isSubmitting || isCreating || isUpdating}
          >
            <span>{isSubmitting || isCreating || isUpdating ? "Skickar..." : "Bekräfta"}</span>
          </button>
        </div>
      </form>
      <hr />
      <MenuUpdate menuData={menu?.data[0]?.menu} menuId={menu?.data[0]?._id} refetch={refetch} SideTemplate={SideTemplate} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Välj bild från server</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {publicImages?.data?.map((image, index) => (
            <Card className="mb-3" key={index}>
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_BE}/${image.path}`}
                alt={image.imgName}
              />
              <Card.Body>
                <Card.Text>{image.imgName.split('-')[0]}</Card.Text>
                <button className="stats_card w-100" onClick={() => { setPath(image.path); handleClose(); }} type="button">
                  Välj bilden
                </button>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
      </Modal>
      <Modal show={showIcons} size="sm" onHide={handleCloseIcon}>
        <Modal.Header closeButton>
          <h4>Välj tag för rätten</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='avatar-selection-area'>
            <TagIcons changeSelection={changeSelection} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="stats_card w-100" onClick={handleCloseIcon} type="button">Bekräfta</button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MenuSetting;