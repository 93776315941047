import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import { contextData } from "../../../../../ContextApi";
import logoImage from '../../../../../assets/Logo_black_text.png';
import { getMenu, getMenusTemplates } from '../../../../../hooks/menu/menu';
import { getWeeklyPicnicOrders } from "../../../../../hooks/producerHooks/producerHooks";

const WeeklyPicnicOrderList = () => {
  const { userdata } = useContext(contextData);

  const getCurrentWeek = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now - start;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    return Math.floor(diff / oneWeek) + 1;
  };

  const [requestedWeekNumber, setRequestedWeekNumber] = useState(getCurrentWeek());
  const [selectedGroup, setSelectedGroup] = useState('junior');
  const [templateName, setTemplateName] = useState('templateName');
  const [toggledCells, setToggledCells] = useState(() => {
    return JSON.parse(localStorage.getItem('toggledCells')) || {};
  });

  const { data: menuData } = useQuery(
    ['getMenu', { selectedGroup, templateName }],
    () => getMenu({ selectedGroup, templateName }),
    { enabled: !!{ selectedGroup, templateName } }
  );
  const { data: menuTemplate } = useQuery(
    ['getMenuTemplates', selectedGroup],
    () => getMenusTemplates(selectedGroup),
    { enabled: true }
  );
  const {
    data: apiResponse,
    refetch,
    isLoading,
    isError,
    error
  } = useQuery(
    ['getWeeklyPicnicOrders', { requestedWeekNumber, selectedGroup, templateName }],
    () => getWeeklyPicnicOrders({ weekNumber: requestedWeekNumber, group: selectedGroup, templateName }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const data = apiResponse?.data;

  const handleWeekChange = (e) => {
    setRequestedWeekNumber(parseInt(e.target.value));
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleFetchData = () => {
    refetch();
  };

  const handleToggle = (day, userId, dish, type) => {
    const key = `${day}-${userId}-${dish}-${type}`;
    const updatedToggledCells = { ...toggledCells, [key]: !toggledCells[key] };
    setToggledCells(updatedToggledCells);
    localStorage.setItem('toggledCells', JSON.stringify(updatedToggledCells));
  };

  const generatePDF = () => {
    if (!data || !data.data || Object.keys(data.data).length === 0) {
      alert('No valid picnic data available to generate PDF');
      return;
    }

    const doc = new jsPDF();
    const weekNum = data.weekNumber || requestedWeekNumber;

    const getWeekStartDate = (year, weekNum) => {
      const jan1 = new Date(year, 0, 1);
      const daysOffset = (weekNum - 1) * 7 - jan1.getDay() + 1;
      return new Date(year, 0, jan1.getDate() + daysOffset);
    };

    const weekdayOffsets = {
      'Måndag': 0, 'Tisdag': 1, 'Onsdag': 2, 'Torsdag': 3, 'Fredag': 4,
      'Lördag': 5, 'Söndag': 6
    };

    const year = 2025;
    const weekStartDate = getWeekStartDate(year, weekNum);
    const daysToInclude = Object.entries(data.data || {});

    daysToInclude.forEach(([day, dayData], index) => {
      if (!dayData.users || Object.keys(dayData.users).length === 0) return;

      const hasData = Object.values(dayData.users).some(userData => userData.customInfo?.length > 0);
      if (!hasData) return;

      if (index > 0) {
        doc.addPage();
      }

      doc.setFontSize(12);
      doc.text(`Vecka: ${weekNum}`, 15, 15);
      const kommun = userdata?.kommun || 'N/A';
      const formattedKommun = `${kommun.charAt(0).toUpperCase() + kommun.slice(1)}s kommun`;
      doc.addImage(logoImage, 'PNG', 168, 10, 20, 10);
      doc.setFontSize(10);
      doc.text(formattedKommun, 190, 25, { align: 'right' });

      const dayOffset = weekdayOffsets[day] || 0;
      const currentDayDate = new Date(weekStartDate);
      currentDayDate.setDate(weekStartDate.getDate() + dayOffset);
      const dateStr = currentDayDate.toLocaleDateString('sv-SE', { day: '2-digit', month: 'short' }).replace(' ', '-');

      const dayCapitalized = day.charAt(0).toUpperCase() + day.slice(1);
      doc.setFontSize(8);
      doc.text(`${dayCapitalized} ${dateStr} - Utflykt`, 15, 22);

      let startY = 30;
      Object.entries(dayData.users).forEach(([userId, userData]) => {
        if (!userData.customInfo?.length) return;

        doc.setFontSize(8);
        doc.text(`Beställare: ${userId}`, 15, startY);
        startY += 5;

        const tableData = [];
        userData.customInfo.forEach(info => {
          const beverages = Object.entries(userData.beverages || {})
            .map(([name, qty]) => `${name} (${qty})`)
            .join(', ') || 'N/A';
          const fruits = Object.entries(userData.fruits || {})
            .map(([name, qty]) => `${name} (${qty})`)
            .join(', ') || 'N/A';
          const sharedFields = [
            info.pickupTime || 'N/A',
            info.department || 'N/A',
            '', // Placeholder for Huvudrätt
            '', // Placeholder for Antal
            '', // Placeholder for Mängd
            '', // Placeholder for Dressing
            beverages,
            fruits,
            info.contactInfo || 'N/A',
            info.comments || ''
          ];

          let isFirstDish = true;
          Object.entries(userData.dishQuantity || {}).forEach(([dishName, quantities]) => {
            const mainValue = toggledCells[`${day}-${userId}-${dishName}-mainDish`]
              ? 'Utför-själv'
              : `${quantities.mainDishQuantity.value.toFixed(1)} ${quantities.mainDishQuantity.unit}`;
            const dressingValue = toggledCells[`${day}-${userId}-${dishName}-dressing`]
              ? 'Utför-själv'
              : `${quantities.dressingQuantity.value.toFixed(1)} ${quantities.dressingQuantity.unit}`;

            if (isFirstDish) {
              // For the first dish, include all shared fields
              tableData.push([
                sharedFields[0], // Upphämtningstid
                sharedFields[1], // Avdelning
                dishName,
                userData.mainDish[dishName.toLowerCase()] || 0,
                mainValue,
                dressingValue,
                sharedFields[6], // Dryck
                sharedFields[7], // Frukt
                sharedFields[8], // Kontakt
                sharedFields[9]  // Kommentar
              ]);
              isFirstDish = false;
            } else {
              // For subsequent dishes, only include dish-specific fields
              tableData.push([
                '', '', dishName, userData.mainDish[dishName.toLowerCase()] || 0, mainValue, dressingValue, '', '', '', ''
              ]);
            }
          });
        });

        if (tableData.length > 0) {
          autoTable(doc, {
            startY,
            head: [['Upphämtningstid', 'Avdelning', 'Huvudrätt', 'Antal', 'Mängd', 'Dressing', 'Dryck', 'Frukt', 'Kontakt', 'Kommentar']],
            body: tableData,
            styles: {
              fontSize: 6,
              lineWidth: 0.1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
              cellPadding: 1,
              overflow: 'linebreak',
            },
            headStyles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255],
              fontSize: 6,
            },
            columnStyles: {
              0: { cellWidth: 18 },
              1: { cellWidth: 18 },
              2: { cellWidth: 25 },
              3: { cellWidth: 10 },
              4: { cellWidth: 15 },
              5: { cellWidth: 15 },
              6: { cellWidth: 20 },
              7: { cellWidth: 20 },
              8: { cellWidth: 15 },
              9: { cellWidth: 18 }
            },
            didDrawCell: (data) => {
              const text = data.row.raw[data.column.index];
              if (text === 'Utför-själv') {
                doc.setTextColor(255, 0, 0);
                doc.text('Utför-själv', data.cell.x + 1, data.cell.y + data.cell.height / 2, { baseline: 'middle' });
                doc.setTextColor(0, 0, 0);
              }
            },
            didDrawPage: (data) => {
              startY = data.cursor.y + 5;
            }
          });
        }
      });
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);
      const footerLine = '---- Powered by Kitchaid.se All rights reserved ----';
      const now = new Date();
      const printDateTime = now.toLocaleString('sv-SE', {
        day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit',
        timeZone: 'Europe/Stockholm'
      }).replace(',', '');
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;

      doc.setTextColor(128, 128, 128);
      doc.text(footerLine, pageWidth / 2, pageHeight - 15, { align: 'center' });
      doc.text(`Utskriftsdatum: ${printDateTime}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      doc.setTextColor(0, 0, 0);
    }

    doc.autoPrint();
    const pdfUrl = doc.output('bloburl');
    window.open(pdfUrl);
  };

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h2 className='text-light'>Veckoutflyktslista</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Form.Group controlId="groupSelect">
            <Form.Label className='text-light font-size-xs'>Välj Grupp</Form.Label>
            <Form.Select value={selectedGroup} onChange={handleGroupChange}>
              <option value="junior">Skolan/Förskolan</option>
              <option value="senior">Äldreboende</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="d-flex">
          <div className="w-50 me-3">
          <select
            id="packageSelect"
            className="form-select mb-2"
            onChange={(e) => setTemplateName(e.target.value)}
          >
            <option>Välj mall</option>
            {menuTemplate?.data?.map((pkg) => (
              <option key={pkg?._id} value={pkg.templateName}>
                {pkg.templateName || `Paket ${pkg._id}`}
              </option>
            ))}
          </select>
            <select
              id="packageSelect"
              className="form-select"
              onChange={(e) => setTemplateName(e.target.value)}
            >
              <option>Välj meny</option>
              {menuData?.data?.map((pkg) => (
                <option key={pkg?._id} value={pkg.templateName}>
                  {pkg.templateName || `Paket ${pkg._id}`}
                </option>
              ))}
            </select>
          </div>
          <Form.Group controlId="weekSelect">
            <Form.Label className='text-light font-size-xs'>Välj veckonummer</Form.Label>
            <Form.Control
              type="number"
              value={requestedWeekNumber}
              onChange={handleWeekChange}
              min={1}
              max={52}
            />
          </Form.Group>
        </Col>
        <Col md={2} className="d-flex">
          <button
            className='mainButton text-light mt-4'
            onClick={handleFetchData}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Hämta Lista'}
          </button>
        </Col>
      </Row>

      {isError && (
        <Row>
          <Col>
            <div className="alert alert-danger">
              Error fetching data: {error.message}
            </div>
          </Col>
        </Row>
      )}

      {data && (
        <>
          <Row className="mb-3 d-flex">
            <Col className="d-flex align-items-center" sm={10}>
              <h3 className='text-light font-size-s'>
                Utflyktsbeställning för Vecka {data.weekNumber || requestedWeekNumber} - {selectedGroup === 'junior' ? 'Skolan/Förskolan' : 'Äldreboende'}
              </h3>
            </Col>
            <Col sm={2}>
              <button
                className="mainButtonLite ms-4 text-light"
                onClick={generatePDF}
                disabled={!data.data || Object.keys(data.data).length === 0}
              >
                <FontAwesomeIcon icon={faPrint} /> Utskriv lista
              </button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              {data.data && Object.keys(data.data).length > 0 ? (
                Object.entries(data.data)
                  .filter(([, dayData]) => dayData.users && Object.values(dayData.users).some(userData => userData.customInfo?.length > 0))
                  .map(([day, dayData]) => (
                    <React.Fragment key={day}>
                      <div className="mb-2">
                        <strong className="text-light">
                          {day.charAt(0).toUpperCase() + day.slice(1)} - Utflykt
                        </strong>
                      </div>
                      {Object.entries(dayData.users)
                        .filter(([, userData]) => userData.customInfo?.length > 0)
                        .map(([userId, userData]) => (
                          <React.Fragment key={userId}>
                            <div className="mb-2 text-light">
                              Beställare: {userId}
                            </div>
                            <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th style={{ width: '10%', fontSize: '0.8rem' }}>Upphämtningstid</th>
                                  <th style={{ width: '10%', fontSize: '0.8rem' }}>Avdelning</th>
                                  <th style={{ width: '15%', fontSize: '0.8rem' }}>Huvudrätt</th>
                                  <th style={{ width: '8%', fontSize: '0.8rem' }}>Antal</th>
                                  <th style={{ width: '10%', fontSize: '0.8rem' }}>Mängd</th>
                                  <th style={{ width: '10%', fontSize: '0.8rem' }}>Dressing</th>
                                  <th style={{ width: '12%', fontSize: '0.8rem' }}>Dryck</th>
                                  <th style={{ width: '12%', fontSize: '0.8rem' }}>Frukt</th>
                                  <th style={{ width: '10%', fontSize: '0.8rem' }}>Kontakt</th>
                                  <th style={{ width: '13%', fontSize: '0.8rem' }}>Kommentar</th>
                                </tr>
                              </thead>
                              <tbody>
                                {userData.customInfo.map(info => {
                                  const beverages = Object.entries(userData.beverages || {})
                                    .map(([name, qty]) => `${name} (${qty})`)
                                    .join(', ') || 'N/A';
                                  const fruits = Object.entries(userData.fruits || {})
                                    .map(([name, qty]) => `${name} (${qty})`)
                                    .join(', ') || 'N/A';
                                  return Object.entries(userData.dishQuantity || {}).map(([dishName, quantities], index) => (
                                    <tr key={`${info._id}-${dishName}`}>
                                      {index === 0 && (
                                        <>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem' }}>
                                            {info.pickupTime || 'N/A'}
                                          </td>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem' }}>
                                            {info.department || 'N/A'}
                                          </td>
                                        </>
                                      )}
                                      <td style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{dishName}</td>
                                      <td style={{ fontSize: '0.75rem' }}>{userData.mainDish[dishName.toLowerCase()] || 0}</td>
                                      <td style={{ fontSize: '0.75rem' }} onClick={() => handleToggle(day, userId, dishName, 'mainDish')}>
                                        {toggledCells[`${day}-${userId}-${dishName}-mainDish`] ? (
                                          <i className="fa-solid fa-ban fa-sm GDS-icon mt-2 ms-1">
                                            <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
                                          </i>
                                        ) : (
                                          `${quantities.mainDishQuantity.value.toFixed(1)} ${quantities.mainDishQuantity.unit}`
                                        )}
                                      </td>
                                      <td style={{ fontSize: '0.75rem' }} onClick={() => handleToggle(day, userId, dishName, 'dressing')}>
                                        {toggledCells[`${day}-${userId}-${dishName}-dressing`] ? (
                                          <i className="fa-solid fa-ban fa-sm GDS-icon mt-2 ms-1">
                                            <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
                                          </i>
                                        ) : (
                                          `${quantities.dressingQuantity.value.toFixed(1)} ${quantities.dressingQuantity.unit}`
                                        )}
                                      </td>
                                      {index === 0 && (
                                        <>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {beverages}
                                          </td>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                                            {fruits}
                                          </td>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem' }}>
                                            {info.contactInfo || 'N/A'}
                                          </td>
                                          <td rowSpan={Object.keys(userData.dishQuantity || {}).length} style={{ fontSize: '0.75rem' }}>
                                            {info.comments || ''}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  ));
                                })}
                              </tbody>
                            </Table>
                          </React.Fragment>
                        ))}
                    </React.Fragment>
                  ))
              ) : (
                <div className="alert alert-warning">
                  No picnic order data available for this week
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default WeeklyPicnicOrderList;