// src/components/UserOrderTable.js
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Table, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCreateLunchOrder, useFetchLunchOrders, useUpdateLunchOrder, useDeleteSingleLunchOrders } from '../../hooks/foodOrders/lunchOrders';
import Spinner from '../../Partials/Spinner';
import { toast } from 'react-toastify';

const ForwardedInput = React.forwardRef((props, ref) => (
    <Form.Control {...props} ref={ref} />
));
ForwardedInput.displayName = 'ForwardedInput';

const UserOrderTable = ({ weekNumber, weekOffset, weeklyMenuData }) => {
    const { control, handleSubmit, setValue, reset, getValues } = useForm();
    const createLunchOrder = useCreateLunchOrder();
    const updateLunchOrders = useUpdateLunchOrder();
    const deleteLunchOrders = useDeleteSingleLunchOrders();
    const { data: lunchOrders, isLoading: isLoadingOrders } = useFetchLunchOrders({ weekNumber });
    const [commentValues, setCommentValues] = useState({});

    useEffect(() => {
        reset();
        setCommentValues({});
        if (lunchOrders?.length > 0) {
            lunchOrders.forEach((lunchOrder) => {
                const amountKey = `${weekNumber}_alt${lunchOrder.alt}_day${lunchOrder.weekDay}`;
                const commentKey = `${weekNumber}_alt${lunchOrder.alt}_comment_day${lunchOrder.weekDay}`;
                setValue(amountKey, lunchOrder.amount);
                if (lunchOrder.comment) {
                    setCommentValues(prev => ({ ...prev, [commentKey]: lunchOrder.comment }));
                    setValue(commentKey, lunchOrder.comment);
                }
            });
        }
    }, [lunchOrders, setValue, reset, weekNumber]);

    if (isLoadingOrders) {
        return <Spinner />;
    }

    if (!weeklyMenuData?.data || !Array.isArray(weeklyMenuData?.data) || weeklyMenuData?.data?.length === 0) {
        return <div className="alert alert-info">No menu data available</div>;
    }

    const onSubmit = (data) => {
        if (!weekNumber) {
            toast('Välj vecka först!');
            return;
        }
        const orders = [];
        for (let alt = 1; alt <= 2; alt++) {
            for (let day = 1; day <= 5; day++) {
                const amountKey = `${weekNumber}_alt${alt}_day${day}`;
                const commentKey = `${weekNumber}_alt${alt}_comment_day${day}`;
                if (data[amountKey] > 0 || data[commentKey]) {
                    orders.push({
                        weekOfYear: weekNumber,
                        amount: data[amountKey] || 0,
                        weekDay: day,
                        alt: alt,
                        mealType: 'lunch',
                        comment: data[commentKey] || '',
                    });
                }
            }
        }
        if (lunchOrders?.length > 0) {
            toast('Användaren har redan beställt lunch!');
            return;
        } else {
            createLunchOrder.mutate({ orders });
        }
    };

    const dayOrder = {
        'Måndag': 1, 'Tisdag': 2, 'Onsdag': 3, 'Torsdag': 4, 'Fredag': 5, 'Lördag': 6, 'Söndag': 7,
    };

    const totalWeeks = weeklyMenuData?.data?.length || 0;
    const menuIndex = weekOffset % totalWeeks || 0;
    const currentWeek = weeklyMenuData?.data[menuIndex];
    const sortedDays = [...(currentWeek?.days || [])]
        .filter(day => day.lunch && day.lunch.some(meal => meal.dish))
        .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

    const getDishName = (dayIndex, alt) => {
        if (!sortedDays.length) return '';
        const day = sortedDays[dayIndex];
        const lunchItem = day?.lunch.find(item => item.alt === (alt - 1));
        return lunchItem?.dish || '';
    };

    const handleUpdate = () => {
        if (!lunchOrders?.length) {
            toast('Vänligen beställ först!');
            return;
        }

        const updatedOrders = [];
        const newOrders = [];

        for (let alt = 1; alt <= 2; alt++) {
            for (let day = 1; day <= 5; day++) {
                const amountKey = `${weekNumber}_alt${alt}_day${day}`;
                const commentKey = `${weekNumber}_alt${alt}_comment_day${day}`;
                const amount = getValues(amountKey);
                const comment = getValues(commentKey);

                if (amount >= 0 || comment) {
                    const existingLunchOrder = lunchOrders.find(
                        order => order.alt === alt && order.weekDay === day
                    );

                    if (existingLunchOrder) {
                        updatedOrders.push({
                            _id: existingLunchOrder._id,
                            weekDay: parseInt(day),
                            alt: parseInt(alt),
                            amount: parseInt(amount) || 0,
                            comment: comment || '',
                        });
                    } else if (amount > 0 || comment) {
                        newOrders.push({
                            weekDay: parseInt(day),
                            alt: parseInt(alt),
                            amount: parseInt(amount) || 0,
                            mealType: 'lunch',
                            comment: comment || '',
                        });
                    }
                }
            }
        }

        if (updatedOrders.length > 0) {
            updateLunchOrders.mutate({ weekNumber, lunchOrders: updatedOrders });
        }
        if (newOrders.length > 0) {
            createLunchOrder.mutate({
                orders: newOrders.map(order => ({
                    weekOfYear: weekNumber,
                    ...order,
                })),
            });
        }
        if (updatedOrders.length === 0 && newOrders.length === 0) {
            toast('Ingen ändring hittades.');
        }
    };

    const handleDelete = () => {
        if (!weekNumber) {
            toast('Välj en vecka först!');
            return;
        }
        if (window.confirm('Är du säker på att du vill ta bort lunchbeställningarna?')) {
            deleteLunchOrders.mutate({ weekNumber }, {
                onSuccess: () => {
                    reset();
                    setCommentValues({});
                    toast('Lunchbeställningarna har tagits bort.');
                },
            });
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Table hover className="user-order-table">
                    <thead className="text-light">
                        <tr>
                            <th></th>
                            {sortedDays.map((day, index) => (
                                <th key={index}>{day.day}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="text-light font-size-s">
                        {[1, 2].map((alt) => (
                            <React.Fragment key={alt}>
                                <tr>
                                    <td>{`Alt ${alt}`}</td>
                                    {Array.from({ length: sortedDays.length }).map((_, dayIndex) => {
                                        const day = dayIndex + 1;
                                        const amountKey = `${weekNumber}_alt${alt}_day${day}`;

                                        const renderTooltip = (props) => (
                                            <Tooltip {...props}>{getDishName(dayIndex, alt) || 'No dish'}</Tooltip>
                                        );

                                        return (
                                            <td key={dayIndex}>
                                                <Controller
                                                    name={amountKey}
                                                    control={control}
                                                    defaultValue={0}
                                                    render={({ field }) => (
                                                        <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={renderTooltip}>
                                                            <ForwardedInput
                                                                {...field}
                                                                type="number"
                                                                min={0}
                                                                placeholder="Amount"
                                                                onChange={(e) => field.onChange(e)}
                                                            />
                                                        </OverlayTrigger>
                                                    )}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                                <tr>
                                    <td>Kommentar Alt {alt}</td>
                                    {Array.from({ length: sortedDays.length }).map((_, dayIndex) => {
                                        const day = dayIndex + 1;
                                        const commentKey = `${weekNumber}_alt${alt}_comment_day${day}`;

                                        return (
                                            <td key={dayIndex}>
                                                <Controller
                                                    name={commentKey}
                                                    control={control}
                                                    defaultValue={commentValues[commentKey] || ''}
                                                    render={({ field }) => (
                                                        <Form.Control
                                                            as="textarea"
                                                            {...field}
                                                            placeholder="Meddelande till köket"
                                                            rows={2}
                                                            onChange={(e) => {
                                                                field.onChange(e);
                                                                setCommentValues(prev => ({ ...prev, [commentKey]: e.target.value }));
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </Form>
            <Row className='mt-4'>
                <Col sm={10}>
                    <button type="submit" className="mainButtonLite w-25 text-light" onClick={handleSubmit(onSubmit)}><span className='font-size-xs'>Skicka beställning</span></button>
                </Col>
                <Col sm={2} className="m-auto d-flex">
                    <button className='mainButtonLite w-25' onClick={() => handleUpdate()}>
                        <i className="fa-solid fa-file-pen glow text-light"></i>
                    </button>
                    <button className='mainButtonLite w-25 ms-3' onClick={() => handleDelete()}>
                        <i className="fa-solid fa-trash-can glow text-light"></i>
                    </button>
                </Col>
            </Row>
        </>
    );
};

export default UserOrderTable;