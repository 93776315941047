import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Table, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCreateMultiLunchAndSupperOrder, useFetchLunchAndSupperOrdersAdmin, useUpdateLunchAndSupperOrders, useDeleteLunchAndSupperOrders } from '../../../hooks/foodOrders/lunchAndSupperOrders';
import { useQuery } from 'react-query';
import { getSubKitchenId } from '../../../hooks/producerHooks/producerHooks';
import Spinner from '../../../Partials/Spinner';
import { toast } from 'react-toastify';

const ForwardedInput = React.forwardRef((props, ref) => (
    <Form.Control {...props} ref={ref} />
));
ForwardedInput.displayName = 'ForwardedInput';

const SeniorOrderTable = ({ weekNumber, weekOffset, weeklyMenuData,group }) => {
    const { control, handleSubmit, setValue, reset, getValues } = useForm();
    const createLunchAndSupperOrder = useCreateMultiLunchAndSupperOrder();
    const updateLunchAndSupperOrders = useUpdateLunchAndSupperOrders();
    const deleteLunchAndSupperOrders = useDeleteLunchAndSupperOrders();
    const [tillbehorValues, setTillbehorValues] = useState({});
    const selectedGroup = group;
    const { data: allUsers, isLoading: isLoadingUsers } = useQuery(["allUser",selectedGroup], ()=>getSubKitchenId(selectedGroup), {
        enabled: !!selectedGroup
    });
    const { data: lunchAndSupperOrders, isLoading: isLoadingOrders } = useFetchLunchAndSupperOrdersAdmin({ weekNumber });
    useEffect(() => {
        reset();
        setTillbehorValues({});
        if (lunchAndSupperOrders?.data && Array.isArray(lunchAndSupperOrders?.data) && lunchAndSupperOrders?.data.length > 0) {
            lunchAndSupperOrders?.data?.forEach(userOrder => {
                userOrder?.alts?.forEach(altOrder => {
                    altOrder?.lunchAndSupperOrders?.forEach(order => {
                        const key = `${userOrder._id.user}_${order.mealType}_alt${altOrder.alt}_day${order.weekDay}`;
                        setValue(key, order.amount);
                        if (altOrder.alt === 3) {
                            setTillbehorValues(prev => ({ ...prev, [key]: order.amount }));
                        }
                    });
                });
            });
        }
    }, [lunchAndSupperOrders, setValue, reset, weekNumber]);

    if (isLoadingOrders || isLoadingUsers) {
        return <Spinner />;
    }
    if (!weeklyMenuData?.data || !Array.isArray(weeklyMenuData?.data) || weeklyMenuData?.data?.length === 0) {
        return <div className="alert alert-info">No menu data available for Senior</div>;
    }

    const users = allUsers?.data.map(user => ({
        _id: user._id,
        username: user.username,
        group: user.group
    })).filter(user => user.group === group) || [];

    const onSubmit = (data) => {
        const orders = [];
        users.forEach(user => {
            ['lunch', 'supper'].forEach(mealType => {
                for (let alt = 1; alt <= 3; alt++) {
                    for (let day = 1; day <= 5; day++) {
                        const key = `${user._id}_${mealType}_alt${alt}_day${day}`;
                        if (data[key] > 0) {
                            orders.push({
                                userId: user._id,
                                weekOfYear: weekNumber,
                                amount: parseInt(data[key]),
                                weekDay: day,
                                alt: alt,
                                mealType
                            });
                        }
                    }
                }
            });
        });
        if (!weekNumber) {
            toast('Välj vecka först!');
            return;
        }
        if (lunchAndSupperOrders?.data?.length > 0) {
            return;
        } else {
            createLunchAndSupperOrder.mutate({ orders });
        }
    };

    const dayOrder = {
        'Måndag': 1, 'Tisdag': 2, 'Onsdag': 3, 'Torsdag': 4, 'Fredag': 5, 'Lördag': 6, 'Söndag': 7
    };

    const totalWeeks = weeklyMenuData?.data?.length || 0;
    const menuIndex = weekOffset % totalWeeks || 0;
    const currentWeek = weeklyMenuData?.data[menuIndex];
    const sortedDays = [...(currentWeek?.days || [])]
        .filter(day => (day.lunch || day.supper) && (day.lunch?.some(meal => meal.dish) || day.supper?.some(meal => meal.dish)))
        .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

    const getDishName = (dayIndex, alt, mealType) => {
        if (!sortedDays.length) return '';
        const day = sortedDays[dayIndex];
        const mealItems = mealType === 'lunch' ? day?.lunch : day?.supper;
        const item = mealItems?.find(item => item.alt === (alt - 1));
        return item?.dish || '';
    };

    const isCompletelyDish = (dayIndex, alt, mealType) => {
        if (!sortedDays.length) return false;
        const day = sortedDays[dayIndex];
        const mealItems = mealType === 'lunch' ? day?.lunch : day?.supper;
        const item = mealItems?.find(item => item.alt === (alt - 1));
        return item?.completelyDish || false;
    };

    const handleUpdate = (userId, weekNumber) => {
        if (!lunchAndSupperOrders?.data?.length) {
            toast('Vänligen beställ först!');
            return;
        }

        const updatedOrders = [];

        ['lunch', 'supper'].forEach(mealType => {
            for (let alt = 1; alt <= 3; alt++) {
                for (let day = 1; day <= 7; day++) {
                    const key = `${userId}_${mealType}_alt${alt}_day${day}`;
                    const amount = getValues(key);
                    if (amount >= 0) {
                        const existingUserOrder = lunchAndSupperOrders?.data?.find(order => String(order._id.user) === String(userId));
                        if (existingUserOrder) {
                            const existingAltOrder = existingUserOrder.alts?.find(altOrder => altOrder.alt === alt) || { lunchAndSupperOrders: [] };
                            const existingOrder = existingAltOrder.lunchAndSupperOrders?.find(order => 
                                String(order.weekDay) === String(day) && order.mealType === mealType
                            );
                        
                            updatedOrders.push({
                                _id: existingOrder?._id || null, // Null _id for new entries, handle on backend
                                weekDay: parseInt(day),
                                alt: parseInt(alt),
                                amount: parseInt(amount),
                                mealType
                            });
                        }
                    }
                }
            }
        });

        if (updatedOrders.length > 0) {
            updateLunchAndSupperOrders.mutate({ userId, weekNumber, lunchOrders: updatedOrders });
        }
        if (updatedOrders.length === 0) {
            toast('Ingen ändring hittades.');
        }
    };

    const handleDelete = (userId, weekNumber) => {
        if (window.confirm('Are you sure you want to delete this user\'s orders?')) {
            deleteLunchAndSupperOrders.mutate({ userId, weekNumber });
        }
    };

    const renderMealTable = (user, mealType) => {
        const tableTitle = mealType === 'lunch' ? 'Lunch' : 'Middag';
        return (
            <div key={`${user._id}_${mealType}`}>
                <h6 className='font-size-s text-light'>{tableTitle}</h6>
                <Table hover>
                    <thead className="text-light">
                        <tr>
                            <th></th>
                            {sortedDays.map((day, index) => (
                                <th key={index}>{day.day}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody className="text-light">
                        {[1, 2, 3].map(alt => (
                            <tr key={alt}>
                                <td>{alt === 3 ? 'Tillbehör' : `Alt ${alt}`}</td>
                                {Array.from({ length: sortedDays.length }).map((_, dayIndex) => {
                                    const day = dayIndex + 1;
                                    const key = `${user._id}_${mealType}_alt${alt}_day${day}`;
                                    const alt1Key = `${user._id}_${mealType}_alt1_day${day}`;
                                    const alt2Key = `${user._id}_${mealType}_alt2_day${day}`;
                                    const tillbehorKey = `${user._id}_${mealType}_alt3_day${day}`;

                                    const handleAltChange = () => {
                                        const alt1Value = Number(getValues(alt1Key) || 0);
                                        const alt2Value = Number(getValues(alt2Key) || 0);
                                        let sum = 0;
                                        if (!isCompletelyDish(dayIndex, 1, mealType)) sum += alt1Value;
                                        if (!isCompletelyDish(dayIndex, 2, mealType)) sum += alt2Value;
                                        setTillbehorValues(prev => ({ ...prev, [tillbehorKey]: sum }));
                                        setValue(tillbehorKey, sum);
                                    };

                                    const renderTooltip = (props) => (
                                        <Tooltip {...props}>{getDishName(dayIndex, alt, mealType) || 'No dish'}</Tooltip>
                                    );

                                    return (
                                        <td key={dayIndex}>
                                            <Controller
                                                name={key}
                                                control={control}
                                                defaultValue={tillbehorValues[key] || 0}
                                                render={({ field }) =>
                                                    alt !== 3 ? (
                                                        <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={renderTooltip}>
                                                            <ForwardedInput
                                                                {...field}
                                                                type="number"
                                                                min={0}
                                                                placeholder="Amount"
                                                                onChange={(e) => {
                                                                    field.onChange(e);
                                                                    handleAltChange();
                                                                }}
                                                            />
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <ForwardedInput
                                                            {...field}
                                                            type="number"
                                                            min={0}
                                                            placeholder="Tillbehör"
                                                            onBlur={(e) => {
                                                                field.onChange(e);
                                                                setTillbehorValues(prev => ({ ...prev, [key]: Number(e.target.value) }));
                                                            }}
                                                        />
                                                    )
                                                }
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            {users.map(user => (
                <div key={user._id}>
                    <Row>
                        <Col sm={8}>
                            <h5 className='font-size-s text-light'>{user.username}</h5>
                        </Col>
                        <Col sm={4} className="m-auto d-flex">
                            <button className='mainButton w-25' onClick={() => handleUpdate(user._id, weekNumber)}>
                                <i className="fa-solid fa-file-pen glow text-light"></i>
                            </button>
                            <button className='mainButton w-25' onClick={() => handleDelete(user._id, weekNumber)}>
                                <i className="fa-solid fa-trash-can glow text-light"></i>
                            </button>
                        </Col>
                    </Row>
                    {renderMealTable(user, 'lunch')}
                    {renderMealTable(user, 'supper')}
                </div>
            ))}
            <button type="submit" className="mainButton p-1 text-light">Beställ</button>
        </Form>
    );
};

export default SeniorOrderTable;