import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import logoImage from '../assets/Logo_black_text.png';
import { contextData } from "../ContextApi";

const MenuCalendar = ({ weeklyMenu, startDate, groupType }) => {
  const { userdata } = useContext(contextData);
  const dayOrder = {
    'Måndag': 1,
    'Tisdag': 2,
    'Onsdag': 3,
    'Torsdag': 4,
    'Fredag': 5,
    'Lördag': 6,
    'Söndag': 7,
  };
  if (!Array.isArray(weeklyMenu) || weeklyMenu.length === 0) {
    return <div className="alert alert-info">No menu data available</div>;
  } 
  const cycleStartDate = new Date(startDate);
  const today = new Date();
  const msPerWeek = 7 * 24 * 60 * 60 * 1000;
  const totalWeeks = weeklyMenu.length;

  const weeksElapsed = Math.floor((today - cycleStartDate) / msPerWeek);
  const initialWeekOffset = weeksElapsed >= 0 ? weeksElapsed : 0;

  const [weekOffset, setWeekOffset] = useState(initialWeekOffset);
  const [menuIndex, setMenuIndex] = useState(initialWeekOffset % totalWeeks);

  const getWeekDateRange = (offset) => {
    const weekStart = new Date(cycleStartDate);
    weekStart.setDate(cycleStartDate.getDate() + offset * 7);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6);

    const monthNames = [
      'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni',
      'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
    ];

    const startDay = weekStart.getDate();
    const startMonth = monthNames[weekStart.getMonth()];
    const endDay = weekEnd.getDate();
    const endMonth = monthNames[weekEnd.getMonth()];

    return `${startDay} ${startMonth} - ${endDay} ${endMonth}${weekStart.getFullYear() !== weekEnd.getFullYear() ? ` ${weekEnd.getFullYear()}` : ''}`;
  };

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    const yearStart = new Date(d.getFullYear(), 0, 1);
    return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
  };

  const handlePrevWeek = () => {
    setWeekOffset((prevOffset) => prevOffset - 1);
    setMenuIndex((prevIndex) => (prevIndex - 1 + totalWeeks) % totalWeeks);
  };

  const handleNextWeek = () => {
    setWeekOffset((prevOffset) => prevOffset + 1);
    setMenuIndex((prevIndex) => (prevIndex + 1) % totalWeeks);
  };

  const generatePDF = () => {
    const doc = new jsPDF();
    const currentWeek = weeklyMenu[menuIndex];
    const menuData = currentWeek.days || [];
    const weekStart = new Date(cycleStartDate);
    weekStart.setDate(cycleStartDate.getDate() + weekOffset * 7);
    let yPosition = 20;
    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 10;
    const maxWidth = pageWidth - 2 * margin;
    const pageHeight = doc.internal.pageSize.getHeight();
    const bottomThreshold = pageHeight; // Increased buffer for footer

    // Add logo image
    const kommun = userdata?.kommun || 'N/A';
    const formattedKommun = `${kommun.charAt(0).toUpperCase() + kommun.slice(1)}s kommun`;
    doc.addImage(logoImage, 'PNG', 168, 10, 20, 10);
    doc.setFontSize(10);
    doc.text(formattedKommun, 190, 25, { align: 'right' });

    // Header
    doc.setFontSize(18);
    doc.text(`Vecka: ${getWeekNumber(weekStart)}`, margin, yPosition);
    yPosition += 8;

    if (groupType && groupType.toLowerCase() === 'junior') {
      doc.setFontSize(10);
      doc.text('Skolan/Förskolan', margin, yPosition);
      yPosition += 8;
    } else if (groupType && groupType.toLowerCase() === 'senior') {
      doc.setFontSize(10);
      doc.text('Äldreboende', margin, yPosition);
      yPosition += 8;
    }

    // Menu list
    const sortedDays = [...menuData]
      .filter(day =>
        (day.lunch && day.lunch.some(meal => meal.dish)) ||
        (groupType && groupType.toLowerCase() === 'senior' && day.supper && day.supper.length > 0)
      )
      .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);
    const textStartX = 40;
    const tagIconSize = 4;

    sortedDays.forEach((day, index) => {
      const dayDate = new Date(weekStart);
      dayDate.setDate(weekStart.getDate() + index);
      const month = String(dayDate.getMonth() + 1).padStart(2, '0');
      const dateString = `${dayDate.getDate()}/${month}, ${day.day}`;

      // Check space for day header
      if (yPosition > bottomThreshold) {
        doc.addPage();
        yPosition = 20;
      }

      doc.setFontSize(12);
      doc.text(dateString, margin, yPosition);

      let mealYPosition = yPosition;

      // Lunch Section
      if (day.lunch && day.lunch.some(meal => meal.dish)) {
        mealYPosition += 4; // Space before lunch header
        // Estimate lunch section height (rough: header + items + sides)
        const lunchLines = day.lunch.filter(meal => meal.dish).reduce((total, meal) => {
          const dishLines = doc.splitTextToSize(meal.dish, maxWidth - (textStartX + 10 + margin)).length;
          const sideLines = meal.side && meal.side.length > 0 ? doc.splitTextToSize(meal.side.join(', '), maxWidth - (textStartX + 10 + margin)).length : 0;
          return total + dishLines + sideLines + 2; // +2 for header and spacing
        }, 0);
        const lunchHeight = lunchLines * 5 + 10; // 5 pt per line + header/spacing

        if (mealYPosition + lunchHeight > bottomThreshold) {
          doc.addPage();
          mealYPosition = 20;
        }

        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text('Lunch:', textStartX, mealYPosition);
        mealYPosition += 6;

        day.lunch
          .filter(meal => meal.dish)
          .forEach((meal, mealIndex) => {
            const altPrefix = `Alt ${mealIndex + 1}: `;
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            const altWidth = doc.getTextWidth(altPrefix);
            doc.text(altPrefix, textStartX, mealYPosition);

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(12);
            const dishStartX = textStartX + altWidth + 10;
            const dishLines = doc.splitTextToSize(meal.dish, maxWidth - dishStartX + margin);
            dishLines.forEach((line, lineIndex) => {
              doc.text(line, dishStartX, mealYPosition + (lineIndex * 5));
            });

            if (meal.tagIcon) {
              const tagIconX = dishStartX + doc.getTextWidth(dishLines[0]) + 2;
              const tagIconY = mealYPosition - 3;
              try {
                doc.addImage(meal.tagIcon, 'PNG', tagIconX, tagIconY, tagIconSize, tagIconSize);
              } catch (error) {
                console.error(`Failed to add tagIcon for ${meal.dish}:`, error);
              }
            }

            if (meal.side && meal.side.length > 0) {
              const sidePrefix = 'Tillbehör: ';
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(10);
              doc.text(sidePrefix, textStartX, mealYPosition + (dishLines.length * 5));

              doc.setFont('helvetica', 'normal');
              doc.setFontSize(12);
              const sideStartX = dishStartX;
              const sideLines = doc.splitTextToSize(meal.side.join(', '), maxWidth - sideStartX + margin);
              sideLines.forEach((line, lineIndex) => {
                doc.text(line, sideStartX, mealYPosition + ((dishLines.length + lineIndex) * 5));
              });
              mealYPosition += (dishLines.length + sideLines.length) * 5;
            } else {
              mealYPosition += dishLines.length * 5;
            }
            mealYPosition += 2;
          });
      }

      // Supper Section (only for Senior)
      if (groupType && groupType.toLowerCase() === 'senior' && day.supper && day.supper.length > 0) {
        mealYPosition += 4; // Space between lunch and supper
        // Estimate supper section height
        const supperLines = day.supper.filter(meal => meal.dish).reduce((total, meal) => {
          const dishLines = doc.splitTextToSize(meal.dish, maxWidth - (textStartX + 10 + margin)).length;
          const sideLines = meal.side && meal.side.length > 0 ? doc.splitTextToSize(meal.side.join(', '), maxWidth - (textStartX + 10 + margin)).length : 0;
          return total + dishLines + sideLines + 2; // +2 for header and spacing
        }, 0);
        const supperHeight = supperLines * 5 + 10;

        if (mealYPosition + supperHeight > bottomThreshold) {
          doc.addPage();
          mealYPosition = 20;
        }

        doc.setFontSize(10);
        doc.setFont('helvetica', 'bold');
        doc.text('Middag:', textStartX, mealYPosition);
        mealYPosition += 6;

        day.supper
          .filter(meal => meal.dish)
          .forEach((meal, mealIndex) => {
            const altPrefix = `Alt ${mealIndex + 1}: `;
            doc.setFont('helvetica', 'bold');
            doc.setFontSize(10);
            const altWidth = doc.getTextWidth(altPrefix);
            doc.text(altPrefix, textStartX, mealYPosition);

            doc.setFont('helvetica', 'normal');
            doc.setFontSize(12);
            const dishStartX = textStartX + altWidth + 10;
            const dishLines = doc.splitTextToSize(meal.dish, maxWidth - dishStartX + margin);
            dishLines.forEach((line, lineIndex) => {
              doc.text(line, dishStartX, mealYPosition + (lineIndex * 5));
            });

            if (meal.tagIcon) {
              const tagIconX = dishStartX + doc.getTextWidth(dishLines[0]) + 2;
              const tagIconY = mealYPosition - 3;
              try {
                doc.addImage(meal.tagIcon, 'PNG', tagIconX, tagIconY, tagIconSize, tagIconSize);
              } catch (error) {
                console.error(`Failed to add tagIcon for ${meal.dish}:`, error);
              }
            }

            if (meal.side && meal.side.length > 0) {
              const sidePrefix = 'Tillbehör: ';
              doc.setFont('helvetica', 'bold');
              doc.setFontSize(10);
              doc.text(sidePrefix, textStartX, mealYPosition + (dishLines.length * 5));

              doc.setFont('helvetica', 'normal');
              doc.setFontSize(12);
              const sideStartX = dishStartX;
              const sideLines = doc.splitTextToSize(meal.side.join(', '), maxWidth - sideStartX + margin);
              sideLines.forEach((line, lineIndex) => {
                doc.text(line, sideStartX, mealYPosition + ((dishLines.length + lineIndex) * 5));
              });
              mealYPosition += (dishLines.length + sideLines.length) * 5;
            } else {
              mealYPosition += dishLines.length * 5;
            }
            mealYPosition += 2;
          });
      }

      yPosition = mealYPosition + 4;
    });

    // Add footer to all pages
    // const pageCount = doc.internal.getNumberOfPages();
    // for (let i = 1; i <= pageCount; i++) {
    //   doc.setPage(i);
    //   doc.setFontSize(8);
    //   const pageHeight = doc.internal.pageSize.height;
    //   const footerLine = '---- Powered by Kitchaid.se All rights reserved ----';
    //   const now = new Date();
    //   const printDateTime = now.toLocaleString('sv-SE', {
    //     day: '2-digit',
    //     month: 'long',
    //     year: 'numeric',
    //     hour: '2-digit',
    //     minute: '2-digit',
    //     timeZone: 'Europe/Stockholm'
    //   }).replace(',', '');
    //   const pageWidth = doc.internal.pageSize.width;

    //   doc.setTextColor(128, 128, 128);
    //   doc.text(footerLine, pageWidth / 2, pageHeight - 15, { align: 'center' });
    //   doc.text(`Utskriftsdatum: ${printDateTime}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
    //   doc.setTextColor(0, 0, 0);
    // }

    doc.autoPrint();
    const pdfUrl = doc.output('bloburl');
    window.open(pdfUrl);
  };
  const currentWeek = weeklyMenu[menuIndex];
  const menuData = currentWeek.days || [];
  const sortedDays = [...menuData]
  .filter(day =>
    (day.lunch && day.lunch.some(meal => meal.dish)) ||
    (groupType && groupType.toLowerCase() === 'senior' && day.supper && day.supper.length > 0)
    )
    .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);
    console.log(sortedDays);

  return (
    <div className="menu-calendar m-auto">
      <div className="carousel-header">
        <button className="nav-button prev" onClick={handlePrevWeek}>
        <i className="fa-solid fa-arrow-left"></i>
        </button>
        <h2 className="carousel-title">
          {getWeekDateRange(weekOffset)}
          <span className='font-size-s'> (Vecka {getWeekNumber(new Date(cycleStartDate).setDate(cycleStartDate.getDate() + weekOffset * 7))})</span>
        </h2>
        <button className="nav-button next" onClick={handleNextWeek}>
        <i className="fa-solid fa-arrow-right"></i>
        </button>
        <button className="print-button" onClick={generatePDF}>
          <FontAwesomeIcon icon={faPrint} /> Utskriv
        </button>
      </div>
      <div className="week-section">
        <div className="calendar-grid">
          {sortedDays.length > 0 ? (
            sortedDays.map((day, index) => (
              <div key={index} className="day-column">
                <h3>{day.day}</h3>
                <div className="meal-options">
                  {/* Lunch Section */}
                  {day.lunch && day.lunch.length > 0 && (
                    <>
                      <h5 className='font-size-s'>Lunch</h5>
                      {day.lunch
                        .filter(meal => meal.dish)
                        .map((meal, mealIndex) => (
                          <div key={`lunch-${mealIndex}`} className="meal-row">
                            <span className="meal-number font-size-xs">Alt: {mealIndex + 1}</span>
                            <div className="meal-content">
                              <div className="dish-wrapper">
                                <span className="meal-text font-size-xs" title={meal.dish}>
                                  {meal.dish}
                                </span>
                                {meal.tagIcon && <img className="tagIcon" src={meal.tagIcon} alt="tagIcon" />}
                              </div>
                              {meal.side && meal.side.length > 0 && (
                                <span className="meal-side font-size-xs me-4" title={meal.side.join(', ')}>
                                  Tillbehör: {meal.side.join(', ')}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                  {/* Supper Section (only for Senior) */}
                  {groupType && groupType.toLowerCase() === 'senior' && day.supper && day.supper.length > 0 && (
                    <>
                      <h5 className='font-size-s'>Middag</h5>
                      {day.supper
                        .filter(meal => meal.dish)
                        .map((meal, mealIndex) => (
                          <div key={`supper-${mealIndex}`} className="meal-row">
                            <span className="meal-number font-size-xs">Alt: {mealIndex + 1}</span>
                            <div className="meal-content">
                              <div className="dish-wrapper">
                                <span className="meal-text font-size-xs" title={meal.dish}>
                                  {meal.dish}
                                </span>
                                {meal.tagIcon && <img className="tagIcon" src={meal.tagIcon} alt="tagIcon" />}
                              </div>
                              {meal.side && meal.side.length > 0 && (
                                <span className="meal-side font-size-xs me-4" title={meal.side.join(', ')}>
                                  Tillbehör: {meal.side.join(', ')}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="alert alert-light">No meals available for this week</div>
          )}
        </div>
      </div>
    </div>
  );
};

MenuCalendar.propTypes = {
  weeklyMenu: PropTypes.arrayOf(
    PropTypes.shape({
      weekNumber: PropTypes.number.isRequired,
      days: PropTypes.arrayOf(
        PropTypes.shape({
          day: PropTypes.string.isRequired,
          lunch: PropTypes.arrayOf(
            PropTypes.shape({
              alt: PropTypes.number,
              dish: PropTypes.string,
              side: PropTypes.arrayOf(PropTypes.string)
            })
          ),
          supper: PropTypes.arrayOf(
            PropTypes.shape({
              alt: PropTypes.number,
              dish: PropTypes.string,
              side: PropTypes.arrayOf(PropTypes.string)
            })
          )
        })
      )
    })
  ).isRequired,
  groupType: PropTypes.string
};

export default MenuCalendar;