import React, { useState, useEffect } from "react";
import { Accordion, Row, Col, Button } from "react-bootstrap";

const SideIngredientSelection = ({
  register,
  SideTemplate,
  userMakeOwnSide,
  handleUserMakeOwnSide,
  userMakeOwnSauce,
  handleUserMakeOwnSauce,
  errors,
  validateSides,
  defaultExtraSides,
}) => {
  const [extraSides, setExtraSides] = useState(defaultExtraSides || []);
  useEffect(() => {
    setExtraSides(defaultExtraSides || []);
  }, [defaultExtraSides]);

  const addExtraSide = () => {
    setExtraSides([
      ...extraSides,
      { extraSideIngredient: "", extraSideIngredientPerGuest: "", extraSideIngredientUnit: "" },
    ]);
  };

  const removeExtraSide = (index) => {
    setExtraSides(extraSides.filter((_, i) => i !== index));
  };

  return (
    <Accordion.Item eventKey="2">
      <Accordion.Header className="routineLayout">Tillbehör</Accordion.Header>
      <Accordion.Body>
        {/* Main Side Ingredient Select */}
        {errors?.sideIngredient && <p className="ms-1 p-1 error fs-6">{errors?.sideIngredient.message}</p>}
        <div className="ms-2 mt-4 mb-4 d-flex align-items-center">
          <select
            className="form-control"
            {...register("sideIngredient", {
              validate: value => validateSides(value, "tillbehör"),
            })}
          >
            <option value="">Välj tillbehör...</option>
            {SideTemplate?.data[0]?.side?.map((item, index) => (
              <option key={index} value={item?.sideIngredient}>
                {item?.sideIngredient}
              </option>
            ))}
          </select>
          <Button variant="success" className="ms-2" onClick={addExtraSide}>+</Button>
        </div>
        <label className="text-light font-size-xs" htmlFor="t.ex">Standard mängd per gäst t.ex 0.12 Kilo</label>
        <div className="ms-2">
          {errors?.sideIngredientPerGuest && <p className="ms-1 p-1 error fs-6">{errors?.sideIngredientPerGuest.message}</p>}
          <input
            className="form-control"
            type="number"
            min={0}
            step=".01"
            defaultValue={0}
            placeholder="Standard mängd per gäst"
            {...register("sideIngredientPerGuest", {
              validate: value => validateSides(value, "tillbehör mängd per gäst"),
            })}
          />
          {errors?.sideIngredientUnit && <p className="ms-1 p-1 error fs-6">{errors?.sideIngredientUnit.message}</p>}
          <select
            {...register("sideIngredientUnit", {
              validate: value => validateSides(value, "tillbehör enhet"),
            })}
            className="form-select mt-4 mb-4"
          >
            <option value="">Välj enhet</option>
            <option value="Kilo">Kilo</option>
            <option value="Liter">Liter</option>
            <option value="Styck">Styck</option>
          </select>
        </div>
        {/* Extra Side Ingredients Section */}
        {extraSides.map((extra, index) => (
          <div key={index} className="ms-2 mt-4 d-flex align-items-center">
            {/* Side Ingredient Select */}
            {errors?.extraSides?.[index]?.extraSideIngredient && (
              <p className="ms-1 p-1 error fs-6">{errors?.extraSides[index].extraSideIngredient.message}</p>
            )}
            <select
              className="form-control me-2 mb-4"
              {...register(`extraSides.${index}.extraSideIngredient`, {
                validate: value => validateSides(value, `extra tillbehör ${index + 1}`),
              })}
              defaultValue={extra?.extraSideIngredient}
            >
              <option value="">Välj tillbehör...</option>
              {SideTemplate?.data?.[0]?.side?.map((item, i) => (
                <option key={i} value={item?.sideIngredient}>
                  {item?.sideIngredient}
                </option>
              ))}
            </select>
            {/* Remove Button */}
            <Button variant="danger" className="ms-2 mb-4" onClick={() => removeExtraSide(index)}>
              -
            </Button>
            {/* Quantity Input */}
            {errors?.extraSides?.[index]?.extraSideIngredientPerGuest && (
              <p className="ms-1 p-1 error fs-6">{errors?.extraSides[index].extraSideIngredientPerGuest.message}</p>
            )}
            <input
              className="form-control me-2 mb-4 ms-2"
              type="number"
              step={0.01}
              placeholder="Mängd per gäst"
              {...register(`extraSides.${index}.extraSideIngredientPerGuest`, {
                validate: value => validateSides(value, `extra tillbehör mängd ${index + 1}`),
              })}
              defaultValue={extra.extraSideIngredientPerGuest || ""}
            />
            {/* Unit Select */}
            {errors?.extraSides?.[index]?.extraSideIngredientUnit && (
              <p className="ms-1 p-1 error fs-6">{errors?.extraSides[index].extraSideIngredientUnit.message}</p>
            )}
            <select
              className="form-select me-2 ms-2 mb-4"
              {...register(`extraSides.${index}.extraSideIngredientUnit`, {
                validate: value => validateSides(value, `extra tillbehör enhet ${index + 1}`),
              })}
              defaultValue={extra.extraSideIngredientUnit || ""}
            >
              <option value="">Välj enhet</option>
              <option value="Kilo">Kilo</option>
              <option value="Liter">Liter</option>
              <option value="Styck">Styck</option>
            </select>
          </div>
        ))}
        {/* Checkbox Options */}
        <Row className="mb-3 mt-3 ms-4">
          <Col xs={10}>
            <span className="small mb-2 d-block">Utför egen tillbehör</span>
          </Col>
          <Col xs={2}>
            <input type="checkbox" checked={userMakeOwnSide} onChange={handleUserMakeOwnSide} />
          </Col>
        </Row>
        <Row className="mb-3 ms-4">
          <Col xs={10}>
            <span className="small mb-2 d-block">Utför egen kall/varm såsss</span>
          </Col>
          <Col xs={2}>
            <input type="checkbox" checked={userMakeOwnSauce} onChange={handleUserMakeOwnSauce} />
          </Col>
        </Row>
        <label className="text-light font-size-xs" htmlFor="t.ex">Sås mängd per gäst t.ex 0.05 Liter</label>
        <div className="input-group">
          <input
            type="number"
            min={0}
            step=".01"
            className="form-control"
            defaultValue={0}
            placeholder="Sås mängd per gäst t.ex 0.05 Liter"
            {...register("sauseSoupPerGuest")}
          />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SideIngredientSelection;