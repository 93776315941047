/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { SubKitchenCalculatedConsumingData } from "../../../../../hooks/producerHooks/producerHooks";
import { useFetchLunchOrdersForCalculate } from "../../../../../hooks/foodOrders/lunchOrders";
import { useQuery } from "react-query";
import { toggleRow } from "../../../../../utility/utility";

export const CalculatorRowMain = ({
  data,
  dishName,
  sidesName,
  selectedDish,
  getTotalMain,
  isActive,
  toggleRowVisibility,
  weekday,
  alternative,
  weekNumber,
  userInfo,
}) => {
  const totalAvgGuestAmount = data.data.totalAvgGuestAmount ?? 0;
  const { data: lunchOrders } = useFetchLunchOrdersForCalculate({ weekNumber, weekday, alternative });
const getLunchOrderData = (orders, userId) => {
  const filteredOrder = orders?.find((order) => order?._id?.username === userId);
  if (filteredOrder?.alts?.length > 0) {
    const lunchOrder = filteredOrder.alts[0].lunchOrders;
    return {
      amount: lunchOrder ? lunchOrder.amount : 0,
      sides: lunchOrder ? lunchOrder.sides : []
    };
  }
  return { amount: 0, sides: [] };
};

const orderData = getLunchOrderData(lunchOrders, data?._id);
const [guestAmount, setGuestAmount] = useState(orderData.amount ?? 0);
const [sidesData, setSidesData] = useState(orderData.sides ?? []);

const [activeRows, setActiveRows] = useState([]);

const [params, setParams] = useState({});
useEffect(() => {
  setParams({
    dishName,
    sidesName,
    guestAmount: isActive && !activeRows.includes(data._id) ? guestAmount : 0,
    subKitchenName: data._id,
    sides: sidesData // Add sides to params if needed by SubKitchenCalculatedConsumingData
  });
}, [dishName, sidesName, guestAmount, data._id, isActive, activeRows, sidesData]);

const { data: consumingdata } = useQuery(
  ["SubKitchenConsumingDataMain", { params }],
  () => SubKitchenCalculatedConsumingData(params),
  { enabled: !!dishName && !!sidesName }
);

const customMain = consumingdata?.data[0]?.totalMainCustom ?? selectedDish.mainIngredientPerGuest * guestAmount;
const unitMain = consumingdata?.data[0]?.records.result.unitMain ?? selectedDish.mainIngredientUnit;

const handleInputChange = (value) => {
  const parsedValue = value === "" ? 0 : parseFloat(value) || 0;
  setGuestAmount(parsedValue);
};

useEffect(() => {
  const newOrderData = getLunchOrderData(lunchOrders, data?._id);
  setGuestAmount(newOrderData.amount ?? 0);
  setSidesData(newOrderData.sides ?? []);
}, [lunchOrders, weekday, alternative, weekNumber, dishName, activeRows, data?._id]);

  useEffect(() => {
    const initArray = userInfo
      .filter((element) => element.makeOwnMainDish === selectedDish.makeOwnMainDish && element.makeOwnMainDish)
      .map((element) => element.username);
    setActiveRows(initArray);
  }, [userInfo, selectedDish, dishName]);

  const [foodReadyTimeHours, setFRTH] = useState("");
  const [foodReadyTimeMinutes, setFRTM] = useState("");
  useEffect(() => {
    const user = userInfo.find((e) => e.username === data?._id);
    setFRTH(user?.foodReadyTime?.hours ?? "");
    setFRTM(user?.foodReadyTime?.minutes ?? "");
  }, [userInfo, data._id]);

  const [totalMainClass, setTotalMainClass] = useState([]);
  const [totalGuestClass, setTotalGuestClass] = useState([]);
  const [resultMain, setResultMain] = useState(0);
  const [resultGuest, setResultGuest] = useState(0);

  useEffect(() => {
    setTotalMainClass(Array.from(document.getElementsByClassName("totalMain")));
    setTotalGuestClass(Array.from(document.getElementsByClassName("totalGuestMain")));
  }, [activeRows]);

  useEffect(() => {
    const mainSum = totalMainClass.reduce((acc, el) => acc + (Number(el.innerText) || 0), 0);
    const guestSum = totalGuestClass.reduce((acc, el) => acc + (Number(el.value) || 0), 0);
    setResultMain(mainSum);
    setResultGuest(guestSum);
  }, [totalMainClass, totalGuestClass]);

  useEffect(() => {
    if (totalAvgGuestAmount === 0) return;
    const fetchGetTotal = async () => {
      try {
        await getTotalMain({
          resultMain,
          unitMain,
          resultGuest,
          username: data._id,
        });
      } catch (error) {
        console.error("Failed to get total:", error);
      }
    };
    fetchGetTotal();
  }, [totalAvgGuestAmount, resultMain, resultGuest, unitMain, data._id]);

  const [comment, setComment] = useState("");
  useEffect(() => {
    setComment(consumingdata?.data[0]?.records?.result?.sides?.comment ?? "");
  }, [consumingdata]);

  return (
    <tr className={isActive ? "" : "d-none"}>
      <th scope="row">
        <i className="fa-solid fa-eye-slash me-2" onClick={() => toggleRowVisibility(data._id)}></i>
        {foodReadyTimeHours}:{foodReadyTimeMinutes}
      </th>
      <td>{data._id}</td>
      <td>
        <input
          type="number"
          min={1}
          className={activeRows.includes(data._id) ? "" : "totalGuestMain"}
          value={guestAmount}
          onChange={(e) => handleInputChange(e.target.value)}
        />
      </td>
      <td onClick={() => toggleRow(data._id, setActiveRows)}>
        {activeRows.includes(data._id) ? (
          <>
            <i className="fa-solid fa-ban fa-lg GDS-icon mt-2">
              <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
            </i>
            <span>{customMain.toFixed(1)}</span>
          </>
        ) : (
          <span className="totalMain">{customMain.toFixed(1)}</span>
        )}
        <span className="font-size-xs"> ({unitMain})</span>
      </td>
      <td>
        <textarea
          className="font-size-xxs text-center text-danger"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </td>
      <td>
        {data?.data?.totalAverageMainPerGuest?.toFixed(3) ?? ""}
        <span className="font-size-xs">{unitMain}/pers</span>
      </td>
    </tr>
  );
};