import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Table, Form, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useCreateMultiLunchOrder, useFetchLunchOrdersAdmin, useUpdateLunchOrders, useDeleteLunchOrders } from '../../../hooks/foodOrders/lunchOrders';
import { useQuery } from 'react-query';
import { getSubKitchenId } from '../../../hooks/producerHooks/producerHooks';
import Spinner from '../../../Partials/Spinner';
import { toast } from 'react-toastify';

const ForwardedInput = React.forwardRef((props, ref) => (
    <Form.Control {...props} ref={ref} />
));
ForwardedInput.displayName = 'ForwardedInput';

const JuniorOrderTable = ({ weekNumber, weekOffset, weeklyMenuData, group }) => {
  const { control, handleSubmit, setValue, reset, getValues } = useForm({
    defaultValues: {}
  });
  const createLunchOrder = useCreateMultiLunchOrder();
  const updateLunchOrders = useUpdateLunchOrders();
  const deleteLunchOrders = useDeleteLunchOrders();
  const selectedGroup = group;
  
  const { data: allUsers, isLoading: isLoadingUsers } = useQuery(
    ["allUser", selectedGroup], 
    () => getSubKitchenId(selectedGroup), 
    { enabled: !!selectedGroup }
  );
  
  const { data: lunchOrders, isLoading: isLoadingOrders } = useFetchLunchOrdersAdmin({ weekNumber });

  // Track manual edits to "Tillbehör"
  const [manualEdits, setManualEdits] = useState({});

  useEffect(() => {
    reset();
    setManualEdits({}); // Reset manual edits for new week
    if (lunchOrders && Array.isArray(lunchOrders) && lunchOrders.length > 0) {
      lunchOrders.forEach(userOrder => {
        if (userOrder?.alts && Array.isArray(userOrder.alts)) {
          userOrder.alts.forEach(altOrder => {
            if (altOrder.alt <= 2 && altOrder?.orders && Array.isArray(altOrder.orders)) {
              altOrder.orders.forEach(order => {
                if (order.mealType === 'lunch') {
                  const key = `${userOrder.user}_alt${altOrder.alt}_day${order.weekDay}`;
                  const sideKey = `${key}_side0`;
                  setValue(key, order.amount);
                  if (order.sides && Array.isArray(order.sides) && order.sides.length > 0) {
                    setValue(sideKey, order.sides[0].sideAmount);
                  } else {
                    setValue(sideKey, order.amount);
                  }
                }
              });
            }
          });
        }
      });
    }
  }, [lunchOrders, setValue, reset, weekNumber]);

  if (isLoadingOrders || isLoadingUsers) {
    return <Spinner />;
  }
  if (!weeklyMenuData?.data || !Array.isArray(weeklyMenuData?.data) || weeklyMenuData?.data?.length === 0) {
    return <div className="alert alert-info">No menu data available for Junior</div>;
  }

  const users = allUsers?.data
    .map(user => ({
      _id: user._id,
      username: user.username,
      group: user.group
    }))
    .filter(user => user.group === group) || [];

  const onSubmit = (data) => {
    const orders = [];
    users.forEach(user => {
      for (let alt = 1; alt <= 2; alt++) {
        for (let day = 1; day <= 5; day++) {
          const key = `${user._id}_alt${alt}_day${day}`;
          const sideKey = `${key}_side0`;
          const amount = Number(data[key] || 0);
          const sideAmount = Number(data[sideKey] || 0);

          if (amount > 0) {
            orders.push({
              userId: user._id,
              weekOfYear: weekNumber,
              amount,
              weekDay: day,
              alt,
              mealType: 'lunch',
              sides: sideAmount > 0 ? [{ sideName: ' ', sideAmount }] : []
            });
          }
        }
      }
    });

    if (!weekNumber) {
      toast('Välj vecka först!');
      return;
    }
    if (lunchOrders?.length > 0) {
      return;
    } else {
      createLunchOrder.mutate({ orders });
    }
  };

  const dayOrder = {
    'Måndag': 1, 'Tisdag': 2, 'Onsdag': 3, 'Torsdag': 4, 'Fredag': 5, 'Lördag': 6, 'Söndag': 7
  };
  const totalWeeks = weeklyMenuData?.data?.length || 0;
  const menuIndex = weekOffset % totalWeeks || 0;
  const currentWeek = weeklyMenuData?.data[menuIndex];
  const sortedDays = [...(currentWeek?.days || [])]
    .filter(day => day.lunch && day.lunch.some(meal => meal.dish))
    .sort((a, b) => dayOrder[a.day] - dayOrder[b.day]);

  const getDishName = (dayIndex, alt) => {
    if (!sortedDays.length) return '';
    const day = sortedDays[dayIndex];
    const lunchItem = day?.lunch.find(item => item.alt === (alt - 1));
    return lunchItem?.dish || '';
  };

  const handleUpdate = (userId, weekNumber) => {
    if (!lunchOrders?.length) {
      toast('Vänligen beställ först!');
      return;
    }

    const updatedOrders = [];
    const newOrders = [];

    for (let alt = 1; alt <= 2; alt++) {
      for (let day = 1; day <= 5; day++) {
        const key = `${userId}_alt${alt}_day${day}`;
        const sideKey = `${key}_side0`;
        const amount = Number(getValues(key) || 0);
        const sideAmount = Number(getValues(sideKey) || 0);

        if (amount >= 0) {
          const existingUserOrder = lunchOrders.find(order => String(order.user) === String(userId));
          if (existingUserOrder) {
            const existingAltOrder = existingUserOrder.alts?.find(altOrder => altOrder.alt === alt);
            const existingOrder = existingAltOrder?.orders?.find(order => 
              order.weekDay === day && order.mealType === 'lunch'
            );

            if (existingOrder) {
              updatedOrders.push({
                _id: existingOrder._id,
                weekDay: day,
                alt,
                amount,
                mealType: 'lunch',
                sides: sideAmount > 0 ? [{ sideName: ' ', sideAmount }] : []
              });
            } else if (amount > 0) {
              newOrders.push({
                weekDay: day,
                alt,
                amount,
                mealType: 'lunch',
                sides: sideAmount > 0 ? [{ sideName: ' ', sideAmount }] : []
              });
            }
          } else if (amount > 0) {
            newOrders.push({
              weekDay: day,
              alt,
              amount,
              mealType: 'lunch',
              sides: sideAmount > 0 ? [{ sideName: ' ', sideAmount }] : []
            });
          }
        }
      }
    }

    if (updatedOrders.length > 0) {
      updateLunchOrders.mutate({ userId, weekNumber, orders: updatedOrders });
    }
    if (newOrders.length > 0) {
      createLunchOrder.mutate({
        orders: newOrders.map(order => ({
          userId,
          weekOfYear: weekNumber,
          ...order
        }))
      });
    }
    if (updatedOrders.length === 0 && newOrders.length === 0) {
      toast('Ingen ändring hittades.');
    }
  };

  const handleDelete = (userId, weekNumber) => {
    if (window.confirm('Are you sure you want to delete this user\'s orders?')) {
      deleteLunchOrders.mutate({ userId, weekNumber });
    }
  };

  const handleAmountChange = (userId, alt, day, value) => {
    const key = `${userId}_alt${alt}_day${day}`;
    const sideKey = `${key}_side0`;
    setValue(key, value);
    const currentSideValue = getValues(sideKey);
    console.log(`Alt Key: ${key}, Value: ${value}, Side Key: ${sideKey}, Current Side: ${currentSideValue}`);
    
    // Sync if side is empty or hasn’t been manually edited to a different value
    const isManuallyEdited = manualEdits[sideKey] && currentSideValue !== value;
    if (!isManuallyEdited) {
      setValue(sideKey, value);
      console.log(`Syncing Side Key: ${sideKey} to ${value}`);
    }
  };

  const handleSideChange = (sideKey, value) => {
    setManualEdits(prev => ({ ...prev, [sideKey]: true }));
    setValue(sideKey, value);
    console.log(`Side Key: ${sideKey}, Manually Updated to: ${value}`);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {users.map(user => (
        <div key={user._id}>
          <Row>
            <Col sm={8}>
              <h5 className='font-size-s text-light'>{user.username}</h5>
            </Col>
            <Col sm={4} className="m-auto d-flex">
              <button className='mainButton w-25' onClick={() => handleUpdate(user._id, weekNumber)}>
                <i className="fa-solid fa-file-pen glow text-light"></i>
              </button>
              <button className='mainButton w-25' onClick={() => handleDelete(user._id, weekNumber)}>
                <i className="fa-solid fa-trash-can glow text-light"></i>
              </button>
            </Col>
          </Row>
          <Table hover>
            <thead className="text-light">
              <tr>
                <th></th>
                {sortedDays.map((day, index) => (
                  <th key={index}>{day.day}</th>
                ))}
              </tr>
            </thead>
            <tbody className="text-light">
              {[1, 2].map(alt => (
                <React.Fragment key={alt}>
                  <tr>
                    <td>{`Alt ${alt}`}</td>
                    {Array.from({ length: sortedDays.length }).map((_, dayIndex) => {
                      const day = dayIndex + 1;
                      const key = `${user._id}_alt${alt}_day${day}`;
                      const renderTooltip = (props) => (
                        <Tooltip {...props}>{getDishName(dayIndex, alt) || 'No dish'}</Tooltip>
                      );

                      return (
                        <td key={dayIndex}>
                          <Controller
                            name={key}
                            control={control}
                            render={({ field }) => (
                              <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={renderTooltip}>
                                <ForwardedInput
                                  {...field}
                                  type="number"
                                  min={0}
                                  placeholder="Mängd"
                                  value={field.value ?? ''} 
                                  onChange={(e) => {
                                    const value = Number(e.target.value) || 0;
                                    field.onChange(value);
                                    handleAmountChange(user._id, alt, day, value);
                                  }}
                                />
                              </OverlayTrigger>
                            )}
                          />
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td>Tillbehör</td>
                    {Array.from({ length: sortedDays.length }).map((_, dayIndex) => {
                      const day = dayIndex + 1;
                      const sideKey = `${user._id}_alt${alt}_day${day}_side0`;
                      return (
                        <td key={dayIndex}>
                          <Controller
                            name={sideKey}
                            control={control}
                            render={({ field }) => (
                              <ForwardedInput
                                {...field}
                                type="number"
                                min={0}
                                placeholder="Mängd"
                                value={field.value ?? ''} 
                                onChange={(e) => {
                                  const value = Number(e.target.value) || 0;
                                  field.onChange(value);
                                  handleSideChange(sideKey, value);
                                }}
                              />
                            )}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      ))}
      <button type="submit" className="mainButton p-1 text-light">Beställ</button>
    </Form>
  );
};

export default JuniorOrderTable;