import React from 'react';
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";

function CycleForm({
  newCycle, cycleName, setCycleName, loopInterval, setLoopInterval, templateName,
  setTemplateName, setTemplateId, setSelectedGroup, menuTemplates, saveBothMenus,
  weeklyMenuMutation, menuCycleMutation, refetchCycles, templateId, selectedGroup, weeks
}) {
  const handleTemplateSelect = (e) => {
    const selectedTemplateName = e.target.value;
    const selectedIndex = e.target.selectedIndex;
    const selectedOptionData = menuTemplates?.data?.templates[selectedIndex - 1];
    if (selectedOptionData) {
      setTemplateId(selectedOptionData._id);
      setTemplateName(selectedTemplateName);
      setSelectedGroup(selectedOptionData.group);
    }
  };

  const handleSave = async () => {
    const weeksMenuData = { weeks, templateId, group: selectedGroup };
    const menuCycleData = { cycleName, loopInterval, isLooping: false, templateId, group: selectedGroup };
    try {
      await saveBothMenus({ weeksMenuData, menuCycleData });
      toast.success('Menu Cycle & Weekly Menus saved successfully!');
      refetchCycles();
    } catch (error) {
      toast.error('Error saving data');
    }
  };

  return (
    <Row className='mb-4'>
      <Col sm={4}>
        <select onChange={handleTemplateSelect} className="form-control" value={templateName}>
          <option value="">Välj mallen</option>
          {menuTemplates?.data?.templates?.map((item) => (
            <option key={item._id} value={item.templateName}>
              {item.templateName} ({item.group})
            </option>
          ))}
        </select>
      </Col>
      <Col sm={8}>
        <div className="d-flex gap-2">
          <input
            type="text"
            className="form-control"
            value={cycleName}
            placeholder='Loop namn:'
            onChange={(e) => setCycleName(e.target.value)}
          />
          <input
            type="number"
            className="form-control"
            value={loopInterval}
            placeholder="Loop Intervall:"
            min="1"
            onChange={(e) => setLoopInterval(parseInt(e.target.value, 10) || 1)}
          />
          {newCycle && (
            <button
              className="mainButtonLite text-light"
              onClick={handleSave}
              disabled={weeklyMenuMutation.isLoading || menuCycleMutation.isLoading}
            >
              {weeklyMenuMutation.isLoading || menuCycleMutation.isLoading ? 'Spara...' : 'Spara'}
            </button>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default CycleForm;