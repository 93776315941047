import React from "react";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useQueryClient } from "react-query";
import Hero from './Hero';

function Sidebar({ routes }) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const logout = () => {
        localStorage.clear();
        queryClient.clear();
        navigate("/"); // Redirect to login page
    };

    const handleNavigation = (path) => {
        navigate(path); // Navigate without reloading the main layout
    };

    const groupedRoutes = routes.reduce((groups, route) => {
        const key = route.function || "default";
        if (!groups[key]) groups[key] = [];
        groups[key].push(route);
        return groups;
    }, {});

    const sectionOrder = ["meal", "plan", "control", "default"];
    const sectionTitles = {
        meal: "Måltid",
        plan: "Planera",
        control: "Kontroll",
        default: "General",
    };

    return (
        <div className="sidebar">
            <div className="sidebar-wrapper">
                <div className="logo d-flex align-items-center justify-content-start">
                        <div className="logo-img">
                            <Hero />
                        </div>
                </div>
                <Nav className="flex-column">
                    {sectionOrder.map((group, index) => (
                        groupedRoutes[group] && (
                            <React.Fragment key={index}>
                                <div className="section-title mt-3 mb-2">
                                    <span>{sectionTitles[group]}</span>
                                </div>
                                <hr className="my-2" />
                                {groupedRoutes[group].map((prop, key) => (
                                    !prop.redirect && (
                                        <Nav.Item key={key}>
                                            <div
                                                className="nav-link cursor"
                                                onClick={() => handleNavigation(prop.path)}
                                            >
                                                <i className={prop.icon} />
                                                <span className="ms-2 font-size-s">{prop.name}</span>
                                            </div>
                                        </Nav.Item>
                                    )
                                ))}
                            </React.Fragment>
                        )
                    ))}
                    <hr className="my-2" />
                    <Nav.Item>
                        <div className="nav-link cursor" onClick={logout}>
                            <i className="fa-solid fa-right-from-bracket fa-xl"></i>
                            <span className="ms-2 font-size-s">Logout</span>
                        </div>
                    </Nav.Item>
                </Nav>
            </div>
        </div>
    );
}


export default Sidebar;
