import React, { useState, useContext } from 'react';
import { Container, Row, Col, Form, Table } from 'react-bootstrap';
import { SubKitchenWeeklyCalculatedConsumingData } from "../../../../../hooks/producerHooks/producerHooks";
import { useQuery } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import { autoTable } from 'jspdf-autotable';
import { contextData } from "../../../../../ContextApi";
import logoImage from '../../../../../assets/Logo_black_text.png';
import { getMenusTemplates } from '../../../../../hooks/menu/menu';

const WeeklyOrderList = () => {
  const { userdata } = useContext(contextData);

  const getCurrentWeek = () => {
    const now = new Date();
    const start = new Date(now.getFullYear(), 0, 1);
    const diff = now - start;
    const oneWeek = 1000 * 60 * 60 * 24 * 7;
    return Math.floor(diff / oneWeek) + 1;
  };

  const [requestedWeekNumber, setRequestedWeekNumber] = useState(getCurrentWeek());
  const [selectedGroup, setSelectedGroup] = useState('junior');
  const [templateName, setTemplateName] = useState('templateName');
  const [toggledCells, setToggledCells] = useState(() => {
    return JSON.parse(localStorage.getItem('toggledCells')) || {};
  });
  const [comments, setComments] = useState({});

  const {
    data: apiResponse,
    refetch,
    isLoading,
    isError,
    error
  } = useQuery(
    ['SubKitchenWeeklyCalculatedConsumingData', { requestedWeekNumber, selectedGroup, templateName }],
    () => SubKitchenWeeklyCalculatedConsumingData({ requestedWeekNumber, selectedGroup, templateName }),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const initialComments = {};
        Object.entries(data.data || {}).forEach(([day, dayData]) => {
          Object.entries(dayData || {}).forEach(([alt, altData]) => {
            Object.entries(altData.statsByUser || {}).forEach(([dept, stats]) => {
              const key = `${day}-${alt}-${dept}`;
              if (stats.comment) {
                initialComments[key] = stats.comment;
              }
            });
          });
        });
        setComments(initialComments);
      }
    }
  );

  const data = apiResponse?.data;

  const { data: menuTemplate } = useQuery(
    ['getMenuTemplates', selectedGroup],
    () => getMenusTemplates(selectedGroup),
    { enabled: true }
  );

  const handleWeekChange = (e) => {
    setRequestedWeekNumber(parseInt(e.target.value));
  };

  const handleGroupChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleFetchData = () => {
    refetch();
  };

  const handleToggle = (day, alt, dept, type) => {
    const key = `${day}-${alt}-${dept}-${type}`;
    const updatedToggledCells = { ...toggledCells, [key]: !toggledCells[key] };
    setToggledCells(updatedToggledCells);
    localStorage.setItem('toggledCells', JSON.stringify(updatedToggledCells));
  };

  const handleCommentChange = (day, alt, dept, value) => {
    const key = `${day}-${alt}-${dept}`;
    setComments(prev => ({
      ...prev,
      [key]: value
    }));
  };

  const generatePDF = () => {
    if (!data || !data.data || Object.keys(data.data).length === 0) {
      console.error('Invalid or empty data for PDF generation:', data);
      alert('No valid data available to generate PDF');
      return;
    }

    const dataCopy = JSON.parse(JSON.stringify(data));
    const doc = new jsPDF();
    const weekNum = data.weekNumber || requestedWeekNumber;

    const getWeekStartDate = (year, weekNum) => {
      const jan1 = new Date(year, 0, 1);
      const daysOffset = (weekNum - 1) * 7 - jan1.getDay() + 1;
      return new Date(year, 0, jan1.getDate() + daysOffset);
    };

    const weekdayOffsets = {
      'måndag': 0,
      'tisdag': 1,
      'onsdag': 2,
      'torsdag': 3,
      'fredag': 4
    };

    const year = 2025;
    const weekStartDate = getWeekStartDate(year, weekNum);
    const daysToInclude = Object.entries(dataCopy.data).filter(([day]) =>
      selectedGroup !== 'junior' || (day !== 'lördag' && day !== 'söndag')
    );

    const adjustSidesForSingleOrders = (day, dayData) => {
      const alts = Object.entries(dayData || {});
      if (alts.length !== 2) return { ...dayData };

      const [alt1Key, alt1Data] = alts[0];
      const [alt2Key, alt2Data] = alts[1];
      const usersAlt1 = Object.keys(alt1Data.statsByUser || {});
      const usersAlt2 = Object.keys(alt2Data.statsByUser || {});
      const allUsers = [...new Set([...usersAlt1, ...usersAlt2])];

      const updatedDayData = JSON.parse(JSON.stringify(dayData));

      allUsers.forEach((user) => {
        const alt1Stats = alt1Data.statsByUser?.[user] || {
          customInput: 0,
          totalSideCustom: 0,
          totalSauceCustom: 0,
          extraSides: [],
          lunchOrderSides: []
        };
        const alt2Stats = alt2Data.statsByUser?.[user] || {
          customInput: 0,
          totalSideCustom: 0,
          totalSauceCustom: 0,
          extraSides: [],
          lunchOrderSides: []
        };

        const alt1Ordered = (alt1Stats.customInput || 0) > 0;
        const alt2Ordered = (alt2Stats.customInput || 0) > 0;

        if (alt1Ordered && !alt2Ordered && alt1Data.completelyDish) {
          if (!updatedDayData[alt2Key].statsByUser) updatedDayData[alt2Key].statsByUser = {};

          const matchingExtraSide = alt1Data.extraSides.find(side =>
            (side.extraSideName || side.extraSideIngredient) === alt2Data.sidesName
          );

          let totalSideCustom = alt1Stats.totalSideCustom; // Use backend value directly
          if (matchingExtraSide && alt1Data.sidesName !== alt2Data.sidesName) {
            totalSideCustom = parseFloat(matchingExtraSide.extraSideIngredientPerGuest || 0) * alt1Stats.customInput;
          }

          if (!updatedDayData[alt2Key].statsByUser[user]) {
            updatedDayData[alt2Key].statsByUser[user] = { ...alt2Stats };
          }
          updatedDayData[alt2Key].statsByUser[user] = {
            ...updatedDayData[alt2Key].statsByUser[user],
            totalSideCustom,
            totalSauceCustom: alt1Stats.totalSauceCustom || 0,
            extraSides: alt1Stats.extraSides || [],
            lunchOrderSides: alt1Stats.lunchOrderSides || [],
            makeOwnSide: alt1Data.makeOwnSide && alt1Stats.makeOwnSide,
            makeOwnSauce: alt1Data.makeOwnSauce && alt1Stats.makeOwnSauce,
            foodReadyTime: alt1Stats.foodReadyTime || alt2Stats.foodReadyTime,
            singleOrderMarker: true,
          };

          if (!updatedDayData[alt1Key].statsByUser[user]) {
            updatedDayData[alt1Key].statsByUser[user] = { ...alt1Stats };
          }
          updatedDayData[alt1Key].statsByUser[user] = {
            ...updatedDayData[alt1Key].statsByUser[user],
            totalSideCustom: 0,
            totalSauceCustom: 0,
            extraSides: alt1Stats.extraSides || [],
            lunchOrderSides: alt1Stats.lunchOrderSides || [],
            sideTransferred: true,
            sauceTransferred: true
          };
        } else if (alt2Ordered && !alt1Ordered && alt2Data.completelyDish) {
          if (!updatedDayData[alt1Key].statsByUser) updatedDayData[alt1Key].statsByUser = {};

          const matchingExtraSide = alt2Data.extraSides.find(side =>
            (side.extraSideName || side.extraSideIngredient) === alt1Data.sidesName
          );

          let totalSideCustom = alt2Stats.totalSideCustom; // Use backend value directly
          if (matchingExtraSide && alt2Data.sidesName !== alt1Data.sidesName) {
            totalSideCustom = parseFloat(matchingExtraSide.extraSideIngredientPerGuest || 0) * alt2Stats.customInput;
          }

          if (!updatedDayData[alt1Key].statsByUser[user]) {
            updatedDayData[alt1Key].statsByUser[user] = { ...alt2Stats };
          }
          updatedDayData[alt1Key].statsByUser[user] = {
            ...updatedDayData[alt1Key].statsByUser[user],
            totalSideCustom,
            totalSauceCustom: alt2Stats.totalSauceCustom || 0,
            extraSides: alt2Stats.extraSides || [],
            lunchOrderSides: alt2Stats.lunchOrderSides || [],
            makeOwnSide: alt2Data.makeOwnSide && alt2Stats.makeOwnSide,
            makeOwnSauce: alt2Data.makeOwnSauce && alt2Stats.makeOwnSauce,
            foodReadyTime: alt2Stats.foodReadyTime || alt1Stats.foodReadyTime,
            singleOrderMarker: true,
          };

          if (!updatedDayData[alt2Key].statsByUser[user]) {
            updatedDayData[alt2Key].statsByUser[user] = { ...alt2Stats };
          }
          updatedDayData[alt2Key].statsByUser[user] = {
            ...updatedDayData[alt2Key].statsByUser[user],
            totalSideCustom: 0,
            totalSauceCustom: 0,
            extraSides: alt2Stats.extraSides || [],
            lunchOrderSides: alt2Stats.lunchOrderSides || [],
            sideTransferred: true,
            sauceTransferred: true
          };
        } else {
          if (!updatedDayData[alt1Key].statsByUser) updatedDayData[alt1Key].statsByUser = {};
          if (!updatedDayData[alt2Key].statsByUser) updatedDayData[alt2Key].statsByUser = {};
          updatedDayData[alt1Key].statsByUser[user] = { ...alt1Stats };
          updatedDayData[alt2Key].statsByUser[user] = { ...alt2Stats };
        }
      });

      return updatedDayData;
    };

    const aggregateSidesForDay = (day, dayData) => {
      const alts = Object.entries(dayData || {});
      if (alts.length !== 2) return { ...dayData };

      const [alt1Key, alt1Data] = alts[0];
      const [alt2Key, alt2Data] = alts[1];

      const alt1SidesName = alt1Data.sidesName || '';
      const alt2SidesName = alt2Data.sidesName || '';
      const alt1ExtraSides = alt1Data.extraSides || [];
      const alt2ExtraSides = alt2Data.extraSides || [];

      let shouldAggregate = alt1SidesName === alt2SidesName && alt1SidesName && (alt1Data.completelyDish || alt2Data.completelyDish);

      if (!shouldAggregate && alt1ExtraSides.length > 0) {
        shouldAggregate = alt1ExtraSides.some(side => side.extraSideIngredient === alt2SidesName);
      }
      if (!shouldAggregate && alt2ExtraSides.length > 0) {
        shouldAggregate = alt2ExtraSides.some(side => side.extraSideIngredient === alt1SidesName);
      }

      if (shouldAggregate) {
        const allUsers = [
          ...new Set([
            ...Object.keys(alt1Data.statsByUser || {}),
            ...Object.keys(alt2Data.statsByUser || {}),
          ]),
        ];

        const updatedDayData = JSON.parse(JSON.stringify(dayData));

        allUsers.forEach((user) => {
          const alt1Stats = alt1Data.statsByUser?.[user] || {
            totalSideCustom: 0,
            totalSauceCustom: 0,
            makeOwnSide: false,
            makeOwnSauce: false,
            sideTransferred: false,
            sauceTransferred: false,
            customInput: 0,
            extraSides: [],
            lunchOrderSides: []
          };
          const alt2Stats = alt2Data.statsByUser?.[user] || {
            totalSideCustom: 0,
            totalSauceCustom: 0,
            makeOwnSide: false,
            makeOwnSauce: false,
            sideTransferred: false,
            sauceTransferred: false,
            customInput: 0,
            extraSides: [],
            lunchOrderSides: []
          };

          const alt1Ordered = (alt1Stats.customInput || 0) > 0;
          const alt2Ordered = (alt2Stats.customInput || 0) > 0;

          let totalSideCustom, totalSauceCustom;

          if (alt1Ordered && !alt2Ordered && alt1Data.completelyDish) {
            totalSideCustom = alt2Stats.totalSideCustom || 0; // Use backend value
            totalSauceCustom = alt2Stats.totalSauceCustom || 0;
            const alt1ExtraMatch = alt1Stats.extraSides.find(side => side.extraSideName === alt2SidesName);
            if (alt1ExtraMatch) {
              totalSideCustom += parseFloat(alt1ExtraMatch.extraSideIngredientPerGuest || 0) * alt1Stats.customInput;
            }
          } else if (alt2Ordered && !alt1Ordered && alt2Data.completelyDish) {
            totalSideCustom = alt1Stats.totalSideCustom || 0; // Use backend value
            totalSauceCustom = alt1Stats.totalSauceCustom || 0;
            const alt2ExtraMatch = alt2Stats.extraSides.find(side => side.extraSideName === alt1SidesName);
            if (alt2ExtraMatch) {
              totalSideCustom += parseFloat(alt2ExtraMatch.extraSideIngredientPerGuest || 0) * alt2Stats.customInput;
            }
          } else {
            const alt1Side = alt1Stats.sideTransferred ? 0 : (alt1Stats.totalSideCustom || 0);
            const alt2Side = alt2Stats.sideTransferred ? 0 : (alt2Stats.totalSideCustom || 0);
            totalSideCustom = alt1Side + alt2Side;

            const alt1ExtraMatch = alt1Stats.extraSides.find(side => side.extraSideName === alt2SidesName);
            if (alt1ExtraMatch) {
              totalSideCustom += parseFloat(alt1ExtraMatch.extraSideIngredientPerGuest || 0) * alt1Stats.customInput;
            }
            const alt2ExtraMatch = alt2Stats.extraSides.find(side => side.extraSideName === alt1SidesName);
            if (alt2ExtraMatch) {
              totalSideCustom += parseFloat(alt2ExtraMatch.extraSideIngredientPerGuest || 0) * alt2Stats.customInput;
            }

            const alt1Sauce = alt1Stats.sauceTransferred ? 0 : (alt1Stats.totalSauceCustom || 0);
            const alt2Sauce = alt2Stats.sauceTransferred ? 0 : (alt2Stats.totalSauceCustom || 0);
            totalSauceCustom = alt1Sauce + alt2Sauce;
          }

          const makeOwnSide = alt1Stats.makeOwnSide || alt2Stats.makeOwnSide;
          const makeOwnSauce = alt1Stats.makeOwnSauce || alt2Stats.makeOwnSauce;

          if (!updatedDayData[alt1Key].statsByUser) updatedDayData[alt1Key].statsByUser = {};
          if (!updatedDayData[alt2Key].statsByUser) updatedDayData[alt2Key].statsByUser = {};

          updatedDayData[alt1Key].statsByUser[user] = {
            ...alt1Stats,
            totalSideCustom,
            totalSauceCustom,
            makeOwnSide,
            makeOwnSauce,
          };
          updatedDayData[alt2Key].statsByUser[user] = {
            ...alt2Stats,
            totalSideCustom,
            totalSauceCustom,
            makeOwnSide,
            makeOwnSauce,
          };
        });

        return updatedDayData;
      }

      return { ...dayData };
    };

    let isFirstAlt = true;

    daysToInclude.forEach(([day, dayData]) => {
      if (!dayData) return;

      let adjustedDayData = adjustSidesForSingleOrders(day, dayData);
      adjustedDayData = aggregateSidesForDay(day, adjustedDayData);

      Object.entries(adjustedDayData).forEach(([alt, altData]) => {
        if (!altData) return;

        if (!isFirstAlt) {
          doc.addPage();
        }
        isFirstAlt = false;

        doc.setFontSize(12);
        doc.text(`Vecka: ${weekNum}`, 15, 15);
        const kommun = userdata?.kommun || 'N/A';
        const formattedKommun = `${kommun.charAt(0).toUpperCase() + kommun.slice(1)}s kommun`;
        doc.addImage(logoImage, 'PNG', 168, 10, 20, 10);
        doc.setFontSize(10);
        doc.text(formattedKommun, 190, 25, { align: 'right' });

        const dayOffset = weekdayOffsets[day] || 0;
        const currentDayDate = new Date(weekStartDate);
        currentDayDate.setDate(weekStartDate.getDate() + dayOffset);
        const dateStr = currentDayDate.toLocaleDateString('sv-SE', { day: '2-digit', month: 'short' }).replace(' ', '-');

        const dayCapitalized = day.charAt(0).toUpperCase() + day.slice(1);
        const dishTitleLines = [
          `${dayCapitalized} ${dateStr}`,
          `Alternative ${alt === 'alt1' ? '1' : '2'} : ${altData.dishName || 'N/A'}`
        ];
        doc.setFontSize(8);
        doc.text(dishTitleLines, 15, 22);

        const mainTableData = [];
        const statsByUser = altData.statsByUser || {};
        // eslint-disable-next-line no-unused-vars
        const sortedStats = Object.entries(statsByUser).sort(([_, a], [__, b]) => {
          const timeA = a.foodReadyTime ? `${a.foodReadyTime.hours}:${a.foodReadyTime.minutes}` : 'ZZ:ZZ';
          const timeB = b.foodReadyTime ? `${b.foodReadyTime.hours}:${b.foodReadyTime.minutes}` : 'ZZ:ZZ';
          return timeA.localeCompare(timeB);
        });

        sortedStats.forEach(([dept, stats]) => {
          const deliveryTime = stats.foodReadyTime
            ? `${stats.foodReadyTime.hours}:${stats.foodReadyTime.minutes}`
            : 'N/A';
          const mainValue = (altData.makeOwnMainDish && stats.makeOwnMainDish) || toggledCells[`${day}-${alt}-${dept}-mainDish`]
            ? ''
            : `${(stats.totalMainCustom || 0).toFixed(1)} ${altData.mainIngredientUnit || 'Liter'}`;
          const deptDisplay = stats.singleOrderMarker ? `${dept} *` : dept;
          const commentKey = `${day}-${alt}-${dept}`;

          let extraSidesText = '';
          if (stats.extraSides && stats.extraSides.length > 0) {
            extraSidesText = stats.extraSides.map((side, index) =>
              `Val ${index + 1}: ${side.extraSideName} ${side.totalExtraSideCustom.toFixed(1)} ${side.sideUnit}`
            ).join('\n');
          }

          mainTableData.push([
            deliveryTime,
            deptDisplay,
            (stats.customInput || 0).toFixed(1),
            mainValue,
            comments[commentKey] || stats.comment || '',
            extraSidesText
          ]);
        });

        let lastY = 30;
        if (mainTableData.length > 0) {
          autoTable(doc, {
            startY: 30,
            head: [['Leveranstid', 'Avdelning', 'Ätande', 'Huvudrätt', 'Kommentar']],
            body: mainTableData,
            styles: {
              fontSize: 10,
              lineWidth: 0.1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
            },
            headStyles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255]
            },
            columnStyles: {
              0: { cellWidth: 30 },
              1: { cellWidth: 50 },
              2: { cellWidth: 25 },
              3: { cellWidth: 40 },
              4: { cellWidth: 35 }
            },
            pageBreak: 'avoid',
            didDrawCell: (data) => {
              const originalText = data.row.raw[data.column.index];
              if (originalText === '' && data.column.index === 3) {
                const dept = data.row.raw[1].replace(' *', '');
                if ((altData.makeOwnMainDish && sortedStats.find(([d]) => d === dept)[1].makeOwnMainDish) || toggledCells[`${day}-${alt}-${dept}-mainDish`]) {
                  doc.setTextColor(255, 0, 0);
                  doc.text('Utför-själv', data.cell.x + 1.6, data.cell.y + data.cell.height / 2, { baseline: 'middle' });
                  doc.setTextColor(0, 0, 0);
                }
              }
            },
            didDrawPage: (data) => {
              lastY = data.cursor.y;
            }
          });

          const totals = {
            customInput: 0,
            main: 0,
          };
          sortedStats.forEach(([_, stats]) => {
            const isMainSelf = (altData.makeOwnMainDish && stats.makeOwnMainDish) || toggledCells[`${day}-${alt}-${_}-mainDish`];
            if (!isMainSelf) {
              totals.customInput += stats.customInput || 0;
              totals.main += stats.totalMainCustom || 0;
            }
          });

          const totalsTable = [
            [
              'Totalt',
              totals.customInput.toFixed(1),
              `${totals.main.toFixed(1)} ${altData.mainIngredientUnit || 'Liter'}`,
              '',
              ''
            ]
          ];
          autoTable(doc, {
            startY: lastY + 10,
            head: [['', 'Ätande', 'Huvudrätt']],
            body: totalsTable,
            styles: {
              fontSize: 10,
              lineWidth: 0.1,
              lineColor: [0, 0, 0],
              textColor: [0, 0, 0],
            },
            headStyles: {
              fillColor: [0, 0, 0],
              textColor: [255, 255, 255]
            },
            columnStyles: {
              0: { cellWidth: 105 },
              1: { cellWidth: 30 },
              2: { cellWidth: 45 }
            },
            pageBreak: 'avoid',
          });
        }

        if (!altData.completelyDish) {
          doc.addPage();
          doc.setFontSize(12);
          doc.text(`Vecka: ${weekNum}`, 15, 15);
          doc.addImage(logoImage, 'PNG', 168, 5, 20, 10);
          doc.setFontSize(10);
          doc.text(formattedKommun, 190, 20, { align: 'right' });

          const titleX = 15;
          const titleY = 22;
          const padding = 2;

          const firstLine = `${dayCapitalized} ${dateStr}`;
          doc.setFontSize(8);
          doc.setTextColor(0, 0, 0);
          doc.text(firstLine, titleX, titleY);

          const altPrefix = `Alternative ${alt === 'alt1' ? '1' : '2'} : `;
          const dishName = `${altData.dishName || 'N/A'}  | `;
          const tillbehorText = `Tillbehör: ${altData.sidesName || 'N/A'}`;

          const secondLineY = titleY + 5;
          let currentX = titleX;

          doc.setTextColor(0, 0, 0);
          doc.text(altPrefix, currentX, secondLineY);
          currentX += doc.getTextWidth(altPrefix);

          doc.text(dishName, currentX, secondLineY);
          currentX += doc.getTextWidth(dishName);

          const tillbehorWidth = doc.getTextWidth(tillbehorText);
          doc.setFillColor(0, 0, 0);
          doc.rect(currentX - padding, secondLineY - 4, tillbehorWidth + 2 * padding, 6, 'F');
          doc.setTextColor(255, 255, 255);
          doc.text(tillbehorText, currentX, secondLineY);
          doc.setTextColor(0, 0, 0);

          const sideSauceTableData = [];
          sortedStats.forEach(([dept, stats]) => {
            const deliveryTime = stats.foodReadyTime
              ? `${stats.foodReadyTime.hours}:${stats.foodReadyTime.minutes}`
              : 'N/A';
            const sideValue = (altData.makeOwnSide && stats.makeOwnSide) || toggledCells[`${day}-${alt}-${dept}-sideDish`]
              ? ''
              : `${(stats.totalSideCustom || 0).toFixed(1)} ${altData.sideIngredientUnit || 'Liter'}`;
            const sauceValue = (altData.makeOwnSauce && stats.makeOwnSauce) || toggledCells[`${day}-${alt}-${dept}-sauce`]
              ? ''
              : `${(stats.totalSauceCustom || 0).toFixed(1)} Liter`;
            const deptDisplay = stats.singleOrderMarker ? `${dept} *` : dept;
            const commentKey = `${day}-${alt}-${dept}`;

            let extraSidesText = '';
            if (stats.extraSides && stats.extraSides.length > 0) {
              extraSidesText = stats.extraSides.map((side, index) =>
                `Val ${index + 1}: ${side.extraSideName} ${side.totalExtraSideCustom.toFixed(1)} ${side.sideUnit}`
              ).join('\n');
            }

            sideSauceTableData.push([
              deliveryTime,
              deptDisplay,
              sideValue,
              sauceValue,
              comments[commentKey] || stats.comment || '',
              extraSidesText
            ]);
          });

          if (sideSauceTableData.length > 0) {
            autoTable(doc, {
              startY: 30,
              head: [['Leveranstid', 'Avdelning', 'Tillbehör', 'Sås', 'Kommentar', 'Tillbehör Alternative']],
              body: sideSauceTableData,
              styles: {
                fontSize: 9,
                lineWidth: 0.1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0],
              },
              headStyles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
              },
              columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 50 },
                2: { cellWidth: 25 },
                3: { cellWidth: 30 },
                4: { cellWidth: 30 },
                5: { cellWidth: 35 }
              },
              pageBreak: 'avoid',
              didDrawCell: (data) => {
                const originalText = data.row.raw[data.column.index];
                if (originalText === '') {
                  const dept = data.row.raw[1].replace(' *', '');
                  const stats = sortedStats.find(([d]) => d === dept)[1];
                  if ((data.column.index === 2 && ((altData.makeOwnSide && stats.makeOwnSide) || toggledCells[`${day}-${alt}-${dept}-sideDish`])) ||
                    (data.column.index === 3 && ((altData.makeOwnSauce && stats.makeOwnSauce) || toggledCells[`${day}-${alt}-${dept}-sauce`]))) {
                    doc.setTextColor(255, 0, 0);
                    doc.text('Utför-själv', data.cell.x + 1.6, data.cell.y + data.cell.height / 2, { baseline: 'middle' });
                    doc.setTextColor(0, 0, 0);
                  }
                }
              },
              didDrawPage: (data) => {
                lastY = data.cursor.y;
              }
            });

            const totals = {
              side: 0,
              sauce: 0,
            };
            sortedStats.forEach(([_, stats]) => {
              if (!((altData.makeOwnSide && stats.makeOwnSide) || toggledCells[`${day}-${alt}-${_}-sideDish`])) {
                totals.side += stats.totalSideCustom || 0;
              }
              if (!((altData.makeOwnSauce && stats.makeOwnSauce) || toggledCells[`${day}-${alt}-${_}-sauce`])) {
                totals.sauce += stats.totalSauceCustom || 0;
              }
            });

            const totalsTable = [
              [
                'Totalt',
                `${totals.side.toFixed(1)} ${altData.sideIngredientUnit || 'Liter'}`,
                `${totals.sauce.toFixed(1)} Liter`,
                '',
                ''
              ]
            ];
            autoTable(doc, {
              startY: lastY + 10,
              head: [['', 'Tillbehör', 'Sås']],
              body: totalsTable,
              styles: {
                fontSize: 10,
                lineWidth: 0.1,
                lineColor: [0, 0, 0],
                textColor: [0, 0, 0],
              },
              headStyles: {
                fillColor: [0, 0, 0],
                textColor: [255, 255, 255]
              },
              columnStyles: {
                0: { cellWidth: 105 },
                1: { cellWidth: 35 },
                2: { cellWidth: 50 }
              },
              pageBreak: 'avoid',
            });
          }
        }
      });
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(8);

      const footnote = '* Endast en rätt beställd';
      const pageHeight = doc.internal.pageSize.height;
      doc.text(footnote, 10, pageHeight - 20);

      const footerLine = '---- Powered by Kitchaid.se All rights reserved ----';
      const now = new Date();
      const printDateTime = now.toLocaleString('sv-SE', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/Stockholm'
      }).replace(',', '');
      const pageWidth = doc.internal.pageSize.width;

      doc.setTextColor(128, 128, 128);
      doc.text(footerLine, pageWidth / 2, pageHeight - 15, { align: 'center' });
      doc.text(`Utskriftsdatum: ${printDateTime}`, pageWidth / 2, pageHeight - 10, { align: 'center' });
      doc.setTextColor(0, 0, 0);
    }

    doc.autoPrint();
    const pdfUrl = doc.output('bloburl');
    window.open(pdfUrl);
  };

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h5 className='text-light'>Veckobeställningslista</h5>
          <hr className='text-light'></hr>
        </Col>
      </Row>
      <Row className="mb-3">
      <Col md={4}>
          <Form.Group controlId="groupSelect">
            <Form.Label className='text-light font-size-xs'>Välj Grupp</Form.Label>
            <Form.Select value={selectedGroup} onChange={handleGroupChange}>
            <option>Välj grupp</option>
              <option value="junior">Skolan/Förskolan</option>
              <option value="senior">Äldreboende</option>
            </Form.Select>
          </Form.Group>
        </Col>
        <Col md={4} className="d-flex">
            <div className="w-50 me-3">
              <label htmlFor="packageSelect" className="form-label text-light font-size-xs">
                Välj mall
              </label>
              <select
                id="packageSelect"
                className="form-select"
                onChange={(e) => setTemplateName(e.target.value)}
              >
                <option>Välj mall</option>
                {menuTemplate?.data?.map((pkg) => (
                  <option key={pkg?._id} value={pkg.templateName}>
                    {pkg.templateName || `Paket ${pkg._id}`}
                  </option>
                ))}
              </select>
            </div>
          <Form.Group controlId="weekSelect">
            <Form.Label className='text-light font-size-xs'>Välj veckonummer</Form.Label>
            <Form.Control
              type="number"
              value={requestedWeekNumber}
              onChange={handleWeekChange}
              min={1}
              max={52}
            />
          </Form.Group>
        </Col>
        
        <Col md={2} className="d-flex">
          <button
            className='mainButton text-light mt-4'
            onClick={handleFetchData}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Hämta Lista'}
          </button>
        </Col>
      </Row>

      {isError && (
        <Row>
          <Col>
            <div className="alert alert-danger">
              Error fetching data: {error.message}
            </div>
          </Col>
        </Row>
      )}

      {data && (
        <>
          <Row className="mb-3 d-flex">
            <Col className="d-flex align-items-center" sm={10}>
              <h6 className='text-light font-size-s'>
                Beställning för Vecka {data.weekNumber || requestedWeekNumber} - {selectedGroup === 'junior' ? 'Skolan/Förskolan' : 'Äldreboende'}
              </h6>
            </Col>
            <Col sm={2}></Col>
          </Row>
          <Row>
            <Col>
              {data.data && Object.keys(data.data).length > 0 ? (
                Object.entries(data.data)
                  .filter(([day]) => selectedGroup !== 'junior' || (day !== 'lördag' && day !== 'söndag'))
                  .map(([day, dayData]) =>
                    Object.entries(dayData || {}).map(([alt, altData]) => (
                      <React.Fragment key={`${day}-${alt}`}>
                        <Row>
                          <Col sm={11}>
                            <div className='d-flex'>
                              <div>
                                <strong className="text-light">
                                  {day.charAt(0).toUpperCase() + day.slice(1)} | {alt === 'alt1' ? 'Alt 1' : 'Alt 2'}
                                </strong>
                              </div>
                              <div className="text-light">
                                {altData.dishName.charAt(0).toUpperCase() + altData.dishName.slice(1) || 'N/A'}
                                Tillbehör: {altData.sidesName || 'N/A'}
                              </div>
                            </div>
                          </Col>
                          <Col sm={1}>
                            <button
                              className="mainButtonLite text-light p-2"
                              onClick={generatePDF}
                              disabled={!data.data || Object.keys(data.data).length === 0}
                            >
                              <FontAwesomeIcon icon={faPrint} />
                            </button>
                          </Col>
                        </Row>
                        <hr className='text-light'></hr>
                        <Table striped bordered hover>
                          <thead>
                            <tr>
                              <th>Leveranstid</th>
                              <th>Avdelning</th>
                              <th>Ätande</th>
                              <th>Huvudrätt</th>
                              <th>Tillbehör</th>
                              <th>Sås</th>
                              <th>Kommentar</th>
                              <th>Tillbehör Alternative</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(altData.statsByUser || {})
                              // eslint-disable-next-line no-unused-vars
                              .sort(([_, a], [__, b]) => {
                                const timeA = a.foodReadyTime ? `${a.foodReadyTime.hours}:${a.foodReadyTime.minutes}` : 'ZZ:ZZ';
                                const timeB = b.foodReadyTime ? `${b.foodReadyTime.hours}:${b.foodReadyTime.minutes}` : 'ZZ:ZZ';
                                return timeA.localeCompare(timeB);
                              })
                              .map(([dept, stats]) => (
                                <tr key={`${day}-${alt}-${dept}`}>
                                  <td>{stats.foodReadyTime ? `${stats.foodReadyTime.hours}:${stats.foodReadyTime.minutes}` : 'N/A'}</td>
                                  <td>{dept}</td>
                                  <td>{(stats.customInput || 0).toFixed(1)}</td>
                                  <td onClick={() => handleToggle(day, alt, dept, 'mainDish')}>
                                    {(altData.makeOwnMainDish && stats.makeOwnMainDish) || toggledCells[`${day}-${alt}-${dept}-mainDish`] || toggledCells[`${day}-${alt}-all-mainDish`] ? (
                                      <i className="fa-solid fa-ban fa-sm GDS-icon mt-2 ms-1">
                                        <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
                                      </i>
                                    ) : (
                                      `${(stats.totalMainCustom || 0).toFixed(1)} ${altData.mainIngredientUnit || 'Liter'}`
                                    )}
                                  </td>
                                  <td onClick={() => handleToggle(day, alt, dept, 'sideDish')}>
                                    {(altData.makeOwnSide && stats.makeOwnSide) || toggledCells[`${day}-${alt}-${dept}-sideDish`] || toggledCells[`${day}-${alt}-all-sideDish`] ? (
                                      <i className="fa-solid fa-ban fa-sm GDS-icon mt-2 ms-1">
                                        <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
                                      </i>
                                    ) : (
                                      `${(stats.totalSideCustom || 0).toFixed(1)} ${altData.sideIngredientUnit || 'Liter'}`
                                    )}
                                  </td>
                                  <td onClick={() => handleToggle(day, alt, dept, 'sauce')}>
                                    {(altData.makeOwnSauce && stats.makeOwnSauce) || toggledCells[`${day}-${alt}-${dept}-sauce`] || toggledCells[`${day}-${alt}-all-sauce`] ? (
                                      <i className="fa-solid fa-ban fa-sm GDS-icon mt-2 ms-1">
                                        <span className="font-size-xs GDS-text ms-1">Utför-själv</span>
                                      </i>
                                    ) : (
                                      `${(stats.totalSauceCustom || 0).toFixed(1)} Liter`
                                    )}
                                  </td>
                                  <td>
                                    <Form.Control
                                      as="textarea"
                                      rows={1}
                                      value={comments[`${day}-${alt}-${dept}`] || stats.comment || ''}
                                      onChange={(e) => handleCommentChange(day, alt, dept, e.target.value)}
                                      placeholder="Skriv kommentar..."
                                      className="comment-textarea"
                                    />
                                  </td>
                                  <td>
                                    {stats.extraSides && stats.extraSides.length > 0 ? (
                                      stats.extraSides.map((side, index) => (
                                        <div key={index}>
                                          Val {index + 1}: {side.extraSideName} {side.totalExtraSideCustom.toFixed(1)} {side.sideUnit}
                                        </div>
                                      ))
                                    ) : (
                                      '-'
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </React.Fragment>
                    ))
                  )
              ) : (
                <div className="alert alert-warning">
                  No detailed data available for this week
                </div>
              )}
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default WeeklyOrderList;