// src/hooks/useCreateLunchOrder.js
import { useMutation, useQueryClient } from 'react-query';
import { axiosClient } from '../axiosInstance';
import { toast } from "react-toastify";

// Create a new lunch order
export const useCreatePicnicOrder = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (newOrder) => {
            return axiosClient.post(`user/picnicOrder/new`, newOrder).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('picnicOrders');
            },
        }
    );
};

export const useFetchPicnicOrders = async (weekNumber) => {
    return await axiosClient.get(`user/picnicOrder/fetch?weekNumber=${weekNumber}`).catch((err) => console.log(err));
  };


export const useDeletePicnicOrder = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async (orderId) => {
            return axiosClient.delete(`user/picnicOrders/delete?orderId=${orderId}`).then((res) => {
                toast(res.data.msg);
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('picnicOrders');
            },
        }
    );
};