import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row } from "react-bootstrap";
import { useQuery } from "react-query";
import Spinner from "../../../../../../Partials/Spinner";
import { toast } from "react-toastify";
import { getMenuForAdmin } from '../../../../../../hooks/menu/menu';
import {
  getMenuCycles,
  getWeeklyMenu,
  getMenuTemplates,
  useSaveMenuCycle,
  UpdateMenuCycle,
  UpdateWeeklyMenu,
  DeleteMenuCycleById
} from "../../../../../../hooks/admin/superiorAdmin";
import CycleSelector from './CycleSelector';
import CycleForm from './CycleForm';
import MealOptionsControls from './MealOptionsControls';
import WeeklyPlanner from './WeeklyPlanner';
import DishesMenu from './DishesMenu';
import ActivationModal from './ActivationModal';

function App() {
  const [cycleName, setCycleName] = useState('');
  const [loopInterval, setLoopInterval] = useState(6);
  const [isLooping, setIsLooping] = useState(false);
  const [templateId, setTemplateId] = useState('');
  const [cycleId, setCycleId] = useState('');
  const [weeklyMenuId, setWeeklyMenuId] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [lunchCount, setLunchCount] = useState(1);
  const [supperCount, setSupperCount] = useState(1);
  const [weeks, setWeeks] = useState([]);
  const [newCycle, setNewCycle] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data: menu } = useQuery(['getMenu', templateName], () => getMenuForAdmin(templateName), { enabled: !!templateName });
  const { data: menuCycles, refetch: refetchCycles } = useQuery('menuCycles', getMenuCycles);
  const { data: weeklyMenu } = useQuery(['weeklyMenu', weeklyMenuId], () => getWeeklyMenu(weeklyMenuId), { enabled: !!weeklyMenuId });
  const { data: menuTemplates, error, isLoading } = useQuery("getMenuTemplates", getMenuTemplates);

  const { saveBothMenus, weeklyMenuMutation, menuCycleMutation } = useSaveMenuCycle();
  const { mutate: updateCycleMutation } = UpdateMenuCycle();
  const { mutate: updateWeeklyMenuMutation } = UpdateWeeklyMenu();
  const { mutate: deleteMenuCycleById } = DeleteMenuCycleById();

  useEffect(() => {
    if (weeklyMenu?.data?.menu?.weeks) {
      setWeeks(weeklyMenu.data.menu.weeks);
      setLoopInterval(weeklyMenu.data.menu.weeks.length);
    }
  }, [weeklyMenu]);

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination) return;

    if (source.droppableId === 'dishesMenu') {
      const parts = destination.droppableId.split('-');
      const weekIndex = parseInt(parts[1], 10);
      const dayIndex = parseInt(parts[3], 10);
      const mealType = parts[5];
      const altIndex = parseInt(parts[7], 10);

      const droppedDish = menu?.data[0]?.menu?.find(d => d.dishName === draggableId);
      if (!droppedDish) return;

      const defaultSide = droppedDish?.sideIngredient || "";
      const extraSides = droppedDish?.extraSideIngredient?.map(extra => extra.extraSideIngredient) || [];
      const sideDish = [defaultSide, ...extraSides].filter(Boolean);

      setWeeks(prevWeeks => {
        const newWeeks = [...prevWeeks];
        newWeeks[weekIndex].days[dayIndex][mealType][altIndex] = {
          alt: altIndex,
          dish: droppedDish._id,
          side: sideDish,
        };
        return newWeeks;
      });
    }
  };

  if (error) return <div>Nånting gick fel</div>;
  if (isLoading) return <div style={{ height: '100vh' }}><Spinner /></div>;

  return (
    <div className="App">
      <h4 className="text-light mb-4">Menycykelplanerare</h4>
      <CycleSelector
        menuCycles={menuCycles}
        setCycleId={setCycleId}
        setWeeklyMenuId={setWeeklyMenuId}
        setCycleName={setCycleName}
        setLoopInterval={setLoopInterval}
        setIsLooping={setIsLooping}
        setTemplateId={setTemplateId}
        setSelectedGroup={setSelectedGroup}
        setNewCycle={setNewCycle}
        newCycle={newCycle}
        setWeeks={setWeeks}
        setTemplateName={setTemplateName}
        setShowModal={setShowModal}
        lunchCount={lunchCount}
        supperCount={supperCount}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        {(newCycle || cycleId) && (
          <>
            <CycleForm
              newCycle={newCycle}
              cycleName={cycleName}
              setCycleName={setCycleName}
              loopInterval={loopInterval}
              setLoopInterval={setLoopInterval}
              templateName={templateName}
              setTemplateName={setTemplateName}
              setTemplateId={setTemplateId}
              setSelectedGroup={setSelectedGroup}
              menuTemplates={menuTemplates}
              saveBothMenus={saveBothMenus}
              weeklyMenuMutation={weeklyMenuMutation}
              menuCycleMutation={menuCycleMutation}
              refetchCycles={refetchCycles}
              templateId={templateId}
              selectedGroup={selectedGroup}
              weeks={weeks}
            />
            <MealOptionsControls
              lunchCount={lunchCount}
              setLunchCount={setLunchCount}
              supperCount={supperCount}
              setSupperCount={setSupperCount}
              setWeeks={setWeeks}
            />
            <div className="planner-container">
              <Row>
                <WeeklyPlanner
                  weeks={weeks}
                  setWeeks={setWeeks}
                  selectedGroup={selectedGroup}
                  loopInterval={loopInterval}
                  newCycle={newCycle}
                  cycleId={cycleId}
                  lunchCount={lunchCount}
                  supperCount={supperCount}
                  dishes={menu?.data[0]?.menu}
                />
                <DishesMenu dishes={menu?.data[0]?.menu} weeks={weeks} />
              </Row>
            </div>
            {!newCycle && cycleId && (
              <div className="d-flex gap-3">
                <button className="mainButtonLite text-light" onClick={() => handleUpdate({
                  cycleId, weeklyMenuId, weeks, cycleName, startDate, isLooping, loopInterval, templateId, selectedGroup,
                  updateCycleMutation, updateWeeklyMenuMutation, refetchCycles
                })}>
                  Uppdatera
                </button>
                <button className="mainButtonLite text-danger" onClick={() => handleDelete({ cycleId, deleteMenuCycleById, refetchCycles, setCycleId, setWeeks })}>
                  Radera
                </button>
              </div>
            )}
          </>
        )}
      </DragDropContext>
      <ActivationModal
        show={showModal}
        setShow={setShowModal}
        cycleId={cycleId}
        setCycleId={setCycleId}
        startDate={startDate}
        setStartDate={setStartDate}
        isLooping={isLooping}
        setIsLooping={setIsLooping}
        menuCycles={menuCycles}
        selectedGroup={selectedGroup}
        updateCycleMutation={updateCycleMutation}
      />
    </div>
  );
}

const handleUpdate = async ({ cycleId, weeklyMenuId, weeks, cycleName, startDate, isLooping, loopInterval, templateId, selectedGroup, updateCycleMutation, updateWeeklyMenuMutation, refetchCycles }) => {
  const weeklyMenuData = { weeks };
  const updateData = { cycleName, startDate, isLooping, loopInterval, weeks, templateId, group: selectedGroup };
  try {
    await updateWeeklyMenuMutation({ weeklyMenuId, weeklyMenuData }, {
      onSuccess: () => toast.success('Vecko meny updatera!'),
      onError: () => toast.error('Error updating weekly menu'),
    });
    await updateCycleMutation({ cycleId, updateData }, {
      onSuccess: () => {
        toast.success('Cycle updated successfully!');
        refetchCycles();
      },
      onError: () => toast.error('Error updating cycle'),
    });
  } catch (error) {
    toast.error('Error saving data');
  }
};

const handleDelete = ({ cycleId, deleteMenuCycleById, refetchCycles, setCycleId, setWeeks }) => {
  deleteMenuCycleById(cycleId, {
    onSuccess: () => {
      toast.success('Cycle deleted successfully!');
      refetchCycles();
      setCycleId('');
      setWeeks([]);
    },
    onError: () => toast.error('Error deleting cycle'),
  });
};

export default App;