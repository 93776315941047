/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from "react";
import { useQuery, useQueryClient } from "react-query"; // Import useQueryClient
import { DeleteOneDish, getPublicDishImages, UpdateDish, getSpecialType } from "../../../../../hooks/admin/superiorAdmin";
import { useForm, Controller } from "react-hook-form";
import { Accordion, Modal, Button, Card, Row, Col } from "react-bootstrap";
import SideIngredientSelection from './sideIngredientSelection';
import TagIcons from "./tagIconsWindow";

function MenuTable(props) {
  const queryClient = useQueryClient(); // Initialize queryClient

  // State and handlers remain unchanged
  const [showIcons, setShowIcons] = useState(false);
  const handleCloseIcon = () => setShowIcons(false);
  const handleShowIcon = () => setShowIcons(true);
  const [selectTagIcon, setSelectTagIcon] = useState({ tagIcon: `${props?.tagIcon}` });
  const changeSelection = (newSelection) => setSelectTagIcon(newSelection);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showImage, setShowImage] = useState(false);
  const handleImageClose = () => setShowImage(false);
  const handleImageShow = () => setShowImage(true);
  const [smShow, setSmShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);
  const [completelyDish, setComletelyDish] = useState(props?.completelyDish);
  const [userMakeOwnMainDish, setUserMakeOwnMainDish] = useState(props?.makeOwnMainDish);
  const [userMakeOwnSide, setUserMakeOwnSide] = useState(props?.makeOwnSide);
  const [userMakeOwnSauce, setUserMakeOwnSauce] = useState(props?.makeOwnSauce);
  const [path, setPath] = useState('');
  let id = props?.menuId;
  let dishId = props?._id;

  const { data: publicImages } = useQuery('getPublicImages', getPublicDishImages);
  const { mutate: deleteOneDish } = DeleteOneDish();
  const handelDelete = () => {
    let isDeleted = true;
    deleteOneDish({ id, dishId, isDeleted });
    setSmShow(false);
    setShow(false);
  };
  // Sides
  const SideTemplate = props.SideTemplate;
  // Update dish with onSuccess callback
  const { mutate: updateDish } = UpdateDish({
    onSuccess: () => {
      // Trigger parent refetch to update props
      if (props.refetchDish) {
        props.refetchDish();
      }
      // Invalidate queries to refetch data after successful update
      queryClient.invalidateQueries("getSideTemplate"); // Refetch SideTemplate
      queryClient.invalidateQueries("getPublicImages"); // Refetch public images
      queryClient.invalidateQueries("getSpecialtype");  // Refetch special types

      // Optionally, refetch the dish data if it’s part of a larger query
      // e.g., queryClient.invalidateQueries(["dishes", id]);

      // Close modals
      setUpdateShow(false);
      setShow(false);
    },
    onError: (error) => {
      console.error("Update failed:", error);
      // Handle error (e.g., show a notification)
    },
  });

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
  } = useForm({ mode: 'onBlur' });

  useEffect(() => {
    if (props) {
      setValue('mainIngredient', props?.mainIngredient);
      setValue('sideIngredient', props?.sideIngredient);
      setValue('mainIngredientPerGuest', props?.mainIngredientPerGuest);
      setValue('sideIngredientPerGuest', props?.sideIngredientPerGuest);
      setValue('mainIngredientUnit', props?.mainIngredientUnit);
      setValue('sideIngredientUnit', props?.sideIngredientUnit);
      setValue('sauseSoupPerGuest', props?.sauseSoupPerGuest);
      setValue('completelyDish', props?.completelyDish);
      setValue('makeOwnMainDish', props?.makeOwnMainDish);
      setValue('makeOwnSide', props?.makeOwnSide);
      setValue('makeOwnSauce', props?.makeOwnSauce);
      setValue('allergic', props?.allergic);
    }
  }, [props, setValue]);

  const handleUserMakeOwnMainDish = () => setUserMakeOwnMainDish(!userMakeOwnMainDish);
  const handleComletelyDish = () => setComletelyDish(!completelyDish);
  const handleUserMakeOwnSide = () => setUserMakeOwnSide(!userMakeOwnSide);
  const handleUserMakeOwnSauce = () => setUserMakeOwnSauce(!userMakeOwnSauce);

  const { data: type } = useQuery("getSpecialtype", getSpecialType);
  const [chosenTypes, setChosenTypes] = useState(props?.allergic || []);

  const toggleSelection = (type) => {
    if (chosenTypes?.includes(type)) {
      const updatedChosenTypes = chosenTypes.filter(chosenType => chosenType !== type);
      setChosenTypes(updatedChosenTypes.sort());
    } else {
      setChosenTypes([...chosenTypes, type]);
    }
  };

  const validateSides = (value, fieldName) => {
    if (completelyDish) return true;
    if (!value && value !== 0) return `Fyll i ${fieldName} när helrätt inte är vald`;
    if (typeof value === "number") {
      return value >= 0 || `Fyll i ett giltigt värde för ${fieldName}`;
    }
    return value.trim() !== "" || `Fyll i ${fieldName} när helrätt inte är vald`;
  };

  const onSubmit = (data) => {
    const imagePath = { path };
    // Ensure extraSideIngredient is always included, defaulting to [] if not present
    const extraSideIngredient = data.extraSideIngredient || props?.extraSideIngredient || [];
    const submitData = {
      ...data,
      allergic: chosenTypes.sort(),
      imagePath,
      makeOwnMainDish: userMakeOwnMainDish,
      completelyDish: completelyDish,
      makeOwnSide: userMakeOwnSide,
      makeOwnSauce: userMakeOwnSauce,
      tagIcon: selectTagIcon.tagIcon,
      extraSideIngredient, // Explicitly include extraSideIngredient
    }; // Check the output here
    updateDish({ id, dishId, submitData });
    reset(); // Reset form after submission
  };

  const renderExtraSides = () => {
    if (props?.extraSideIngredient) {
      return props?.extraSideIngredient.map((item, index) => (
        <React.Fragment key={index}>
          <br />
          <span>
            {item?.extraSideIngredient} : {item?.extraSideIngredientPerGuest}{" "}
            <span className="font-size-xs">{item?.extraSideIngredientUnit}/Pers</span>
          </span>
        </React.Fragment>
      ));
    }
    return null;
  };

  const image = () => {
    if (props?.data && props?.contentType) {
      return `data:${props?.contentType};base64,${props?.data?.toString('base64')}`;
    }
    return `${process.env.REACT_APP_BE}/${props?.path}`;
  };
  const imageSrc = image();

  return (
    // JSX remains unchanged, omitted for brevity
    // Include all your modals, forms, and UI elements as before
    <>
      <button className="stats_card w-100 mb-3 fs-6" onClick={handleShow}>
        {props?.dishName}
      </button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <span>{props?.dishName}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image">
            <img
              width="400"
              src={imageSrc == undefined ? imageSrc : "/imgsAndVideos/Logo.png"}
              alt="logo"
            />
          </div>
          <span className="fs-6 d-block m-3">Rättens innehåll</span>
          <table className="table table-striped font-size-s">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Huvudkomponent</th>
                <th scope="col">Tillbehör</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row"></th>
                <td>{props?.mainIngredient}</td>
                <td>
                  {props?.sideIngredient} : {props?.sideIngredientPerGuest}
                  <span className="font-size-xs">
                    {props?.sideIngredientUnit}/Pers
                  </span>
                  {renderExtraSides()}
                </td>
                <td>Kall sås</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>
                  {props?.mainIngredientPerGuest}{" "}
                  <span className="font-size-xs">{props?.mainIngredientUnit}/Pers</span>
                </td>
                <td></td>
                <td>
                  {props?.sauseSoupPerGuest}{" "}
                  <span className="font-size-xs">Liter/Pers</span>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="statsDeleteButton mt-4"
            onClick={() => setSmShow(true)}
          >
            Radera
          </Button>
          <Button
            variant="warning"
            className="statsDeleteButton mt-4"
            onClick={() => setUpdateShow(true)}
          >
            Uppdatera
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Är du säker att radera rätten?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            variant="danger"
            className="statsDeleteButton w-100 mt-2"
            onClick={() => handelDelete()}
          >
            Radera
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={updateShow}
        onHide={() => setUpdateShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="mt-5 mb-5 w-75 m-auto">
              <div className="">
                <input
                  className="form-control"
                  type="text"
                  name="dishName"
                  defaultValue={props?.dishName}
                  {...register("dishName", { required: true })}
                />
              </div>
            </div>
            <div className="mb-3">
              <span className="small mb-2 mt-2 ms-2 d-block">
                Mallen till rättens innehåll
              </span>
              <Accordion defaultActiveKey="0" className="w-100 m-auto">
                <Accordion.Item eventKey="1">
                  <Accordion.Header className="routineLayout">
                    Huvudkomponent
                  </Accordion.Header>
                  <Accordion.Body>
                    <textarea
                      className="mb-4 mt-2 w-100 form-control ms-2"
                      name="mainIngredient"
                      defaultValue={props?.mainIngredient}
                      {...register("mainIngredient", { maxLength: 6000 })}
                    />
                    <div className="ms-2">
                      <input
                        className="form-control"
                        type="number"
                        step={0.01}
                        defaultValue={props?.mainIngredientPerGuest}
                        placeholder="Standard mängd per gäst"
                        {...register("mainIngredientPerGuest", { required: true })}
                      />
                      <div>
                        <select
                          {...register("mainIngredientUnit", { required: true })}
                          className="form-select mt-4 mb-4"
                          defaultValue={props?.mainIngredientUnit}
                        >
                          <option value=" ">Välj enhet</option>
                          <option value="Kilo">Kilo</option>
                          <option value="Liter">Liter</option>
                          <option value="Styck">Styck</option>
                          <option value="L-bleck">L-bleck</option>
                        </select>
                      </div>
                    </div>
                    <Row className="mb-3 mt-3 ms-4">
                      <Col xs={10}>
                        <span className="small mb-2 d-block text-dark">
                          Utför egen huvudrätt
                        </span>
                      </Col>
                      <Col xs={2}>
                        <input
                          type="checkbox"
                          checked={userMakeOwnMainDish}
                          onChange={handleUserMakeOwnMainDish}
                        />
                      </Col>
                      <Col xs={10}>
                        <span className="small mb-2 d-block text-dark">Helrätt</span>
                      </Col>
                      <Col xs={2}>
                        <input
                          type="checkbox"
                          checked={completelyDish}
                          onChange={handleComletelyDish}
                        />
                      </Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <SideIngredientSelection
                  register={register}
                  SideTemplate={SideTemplate}
                  userMakeOwnSide={userMakeOwnSide}
                  handleUserMakeOwnSide={handleUserMakeOwnSide}
                  userMakeOwnSauce={userMakeOwnSauce}
                  handleUserMakeOwnSauce={handleUserMakeOwnSauce}
                  defaultExtraSides={props?.extraSideIngredient}
                  validateSides={validateSides}
                />
              </Accordion>
              <hr />
              <span className="text-light font-size-s pb-2">Innehåll</span>
              <div className="special-type-group font-size-md">
                {type?.data?.Special_type[0]?.special_type?.map((type, index) => (
                  <button
                    className={
                      chosenTypes?.includes(type.s_type)
                        ? "special_type_light button_size cursor"
                        : "button_size special_type cursor"
                    }
                    key={index}
                    onClick={() => toggleSelection(type.s_type)}
                  >
                    {type.s_type}
                  </button>
                ))}
              </div>
              <hr />
            </div>
            <div className="image">
              <img
                width="400"
                src={imageSrc == undefined ? imageSrc : "/imgsAndVideos/Logo.png"}
                alt="logo"
              />
            </div>
            <Row>
              <Col xs={11}>
                <button
                  onClick={handleShowIcon}
                  type="button"
                  className="w-100 text-light mainButton"
                  id="choose"
                >
                  Välj tag för rätten
                </button>
              </Col>
              <Col xs={1}>
                <img
                  className="mt-4"
                  src={selectTagIcon.tagIcon || props?.tagIcon}
                  alt="tagIcon"
                />
              </Col>
            </Row>
            <Col md={{ span: 12 }} className="mb-3">
              <span className="small mb-2 d-block">Välj bild från server</span>
              <Button className="stats_card w-100" onClick={handleImageShow}>
                <span>Välj bild</span>
              </Button>
            </Col>
            <span>Ladda upp egen bild</span>
            <Controller
              control={control}
              name="dishImage"
              render={({ field: { value, onChange, ...field } }) => (
                <input
                  {...field}
                  value={value?.fileName}
                  onChange={(event) => onChange(event.target.files[0])}
                  type="file"
                  id="dishImage"
                  className="mb-3 form-control"
                />
              )}
            />
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="stats_card w-100 mt-4">
              Uppdatera
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showImage} onHide={handleImageClose}>
        <Modal.Header closeButton>
          <Modal.Title>Välj bild från server</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {publicImages?.data?.map((image, index) => (
            <Card className="mb-3" key={index}>
              <Card.Img
                variant="top"
                src={`${process.env.REACT_APP_BE}/${image.path}`}
                alt={image.imgName}
              />
              <Card.Body>
                <Card.Text>{image.imgName.split("-")[0]}</Card.Text>
                <Button
                  className="stats_card w-100"
                  onClick={() => {
                    setPath(image.path);
                    handleImageClose();
                  }}
                >
                  Välj bilden
                </Button>
              </Card.Body>
            </Card>
          ))}
        </Modal.Body>
      </Modal>
      <Modal show={showIcons} size="sm" onHide={handleCloseIcon}>
        <Modal.Header closeButton>
          <h4>Välj tag för rätten</h4>
        </Modal.Header>
        <Modal.Body>
          <TagIcons changeSelection={changeSelection} />
        </Modal.Body>
        <Modal.Footer>
          <button className="stats_card w-100" onClick={handleCloseIcon}>
            Bekräfta
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MenuTable;