import React, { useState, useEffect } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Col } from "react-bootstrap";
import { createEmptyWeek } from '../../../../../../utility/utility';

function WeeklyPlanner({
  weeks, setWeeks, selectedGroup, loopInterval, newCycle, cycleId, lunchCount, supperCount, dishes
}) {
  const [activeWeekIndex, setActiveWeekIndex] = useState(0);

  useEffect(() => {
    if (newCycle || cycleId) {
      const currentWeekCount = weeks.length;
      const targetWeekCount = loopInterval || 1;

      if (currentWeekCount < targetWeekCount) {
        const newWeeks = [...weeks];
        const templateWeek = weeks[weeks.length - 1] || createEmptyWeek(0, lunchCount, supperCount);
        for (let i = currentWeekCount; i < targetWeekCount; i++) {
          const newWeek = {
            weekNumber: i + 1,
            days: templateWeek.days.map(day => ({
              ...day,
              lunch: day.lunch.map(lunch => ({ ...lunch })),
              supper: day.supper.map(supper => ({ ...supper }))
            }))
          };
          newWeeks.push(newWeek);
        }
        setWeeks(newWeeks);
      } else if (currentWeekCount > targetWeekCount) {
        setWeeks(weeks.slice(0, targetWeekCount));
      }
    }
  }, [loopInterval, newCycle, cycleId, weeks, setWeeks, lunchCount, supperCount]);

  const getDishNameById = (dishId) => dishes?.find(d => d._id === dishId)?.dishName || '';

  return (
    <Col md={8} xs={12} className="mb-3">
      <div className="weeks-table-wrapper">
        {weeks?.map((week, weekIndex) => (
          <div
            key={week.weekNumber}
            className={`week-row ${activeWeekIndex === weekIndex ? 'active' : ''}`}
            onClick={() => setActiveWeekIndex(weekIndex)}
            onKeyDown={(e) => (e.key === 'Enter' || e.key === ' ') && setActiveWeekIndex(weekIndex)}
            tabIndex={0}
            role="button"
          >
            <div className="week">
              <h5>Vecka {week.weekNumber}</h5>
              <div className="days">
                {week.days
                  .filter((day, dayIndex) => selectedGroup !== "junior" || dayIndex < 5)
                  .map((day, dayIndex) => (
                    <div key={day.day} className="day-cell">
                      <h6>{day.day}</h6>
                      <div className="admin-meal-section">
                        <strong>Lunch</strong>
                        {day.lunch.map((slot, altIndex) => {
                          const droppedDish = dishes?.find(d => d._id === slot.dish);
                          const defaultSide = droppedDish?.sideIngredient || "";
                          const extraSides = droppedDish?.extraSideIngredient?.map(extra => extra.extraSideIngredient) || [];
                          return (
                            <Droppable
                              key={`week-${weekIndex}-day-${dayIndex}-meal-lunch-alt-${altIndex}`}
                              droppableId={`week-${weekIndex}-day-${dayIndex}-meal-lunch-alt-${altIndex}`}
                            >
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="drop-zone">
                                  {slot.dish ? (
                                    <span className="font-size-s admin-meal-text" data-full-text={getDishNameById(slot.dish)}>
                                      {getDishNameById(slot.dish)}
                                    </span>
                                  ) : (
                                    "Släpp maträtten här"
                                  )}
                                  {provided.placeholder}
                                  <span className="font-size-xs mt-2">Tillbehör:</span>
                                  {(defaultSide || extraSides.length > 0) && (
                                    <ul className="side-dish-list">
                                      {defaultSide && (
                                        <li data-full-text={defaultSide}>{defaultSide}</li>
                                      )}
                                      {extraSides.map((extraSide, index) => (
                                        <li key={index} data-full-text={extraSide}>{extraSide}</li>
                                      ))}
                                    </ul>
                                  )}
                                </div>
                              )}
                            </Droppable>
                          );
                        })}
                      </div>
                      {selectedGroup === "senior" && (
                        <div className="admin-meal-section">
                          <strong>Middag</strong>
                          {day.supper.map((slot, altIndex) => {
                            const droppedDish = dishes?.find(d => d._id === slot.dish);
                            const defaultSide = droppedDish?.sideIngredient || "";
                            const extraSides = droppedDish?.extraSideIngredient?.map(extra => extra.extraSideIngredient) || [];
                            return (
                              <Droppable
                                key={`week-${weekIndex}-day-${dayIndex}-meal-supper-alt-${altIndex}`}
                                droppableId={`week-${weekIndex}-day-${dayIndex}-meal-supper-alt-${altIndex}`}
                              >
                                {(provided) => (
                                  <div ref={provided.innerRef} {...provided.droppableProps} className="drop-zone">
                                    {slot.dish ? (
                                      <span className="font-size-xs admin-meal-text" data-full-text={getDishNameById(slot.dish)}>
                                        {getDishNameById(slot.dish)}
                                      </span>
                                    ) : (
                                      "Släpp maträtten här"
                                    )}
                                    {provided.placeholder}
                                    <span className="font-size-xs mt-2">Tillbehör:</span>
                                    {(defaultSide || extraSides.length > 0) && (
                                      <ul className="side-dish-list">
                                        {defaultSide && (
                                          <li data-full-text={defaultSide}>{defaultSide}</li>
                                        )}
                                        {extraSides.map((extraSide, index) => (
                                          <li key={index} data-full-text={extraSide}>{extraSide}</li>
                                        ))}
                                      </ul>
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Col>
  );
}

export default WeeklyPlanner;