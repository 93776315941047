import "react-router-dom";
import {axiosClient} from '../axiosInstance';

//fetch menu admin
export const getMenuForAdmin = async (templateName) => {
  return await axiosClient.get(`user/get_menu?templateName=${templateName}`).catch((err) => console.log(err));
};
//fetch menu by id
export const getMenuById = async (id) => {
  return await axiosClient.get(`user/get_menu_by_id?id=${id}`).catch((err) => console.log(err));
};
//fetch menu user
export const getMenu = async ({selectedGroup,templateName}) => {
  return await axiosClient.get(`user/get_menu_for_user?templateName=${templateName}`,{
    params: {
      group: selectedGroup,
    }
  }).catch((err) => console.log(err));
};
export const getMenusTemplates = async (selectedGroup) => {
  return await axiosClient.get(`user/get_menus_templates`,{
    params: {
      group: selectedGroup,
    }
  }).catch((err) => console.log(err));
};
//fetch weekly menu user
export const getWeeklyMenus = async (weeklyMenuId,templateId) => {
  return await axiosClient.get(`user/get_weekly_menus_for_user?weeklyMenuId=${weeklyMenuId}&templateId=${templateId}`).catch((err) => console.log(err));
};
//fetch weekly menu user
export const getMenuCycle = async (group) => {
  return await axiosClient.get(`user/get_menus_cycle`,{
    params: {
      group: group,
    }
  }).catch((err) => console.log(err));
};
//fetch weekly menu user
export const getMenuCycleForUser = async (templateId) => {
  return await axiosClient.get(`user/get_menus_cycle_for_user?templateId=${templateId}`).catch((err) => console.log(err));
};

//fetch delete menu
export const getDeleteMenu = async () => {
  return await axiosClient.get(`user/get_deleted_menu`).catch((err) => console.log(err));
};