import React, { useState, useContext } from "react";
import Spinner from '../../../Partials/Spinner';
import MenuCalendar from "../../../Partials/MenuCalendar";
import { getMenuCycle, getWeeklyMenus } from "../../../hooks/menu/menu";
import { useQuery } from 'react-query';
import { contextData } from "../../../ContextApi"

function WeeklyMenus() {
    const { userdata } = useContext(contextData);
    const userGroup = userdata?.group;
    const isAdmin = userdata?.group === 'admin'; // Assuming role is available in userdata
    
    // Only use state for group when user is admin
    const [selectedGroup, setSelectedGroup] = useState(null);
    
    // Determine which group to use based on admin status
    const effectiveGroup = isAdmin ? selectedGroup : userGroup;

    // Fetch menuCycle using the effective group
    const {
        data: menuCycle,
        error: cycleError,
        isLoading: cycleLoading
    } = useQuery(
        ['getMenuCycle', effectiveGroup],
        () => getMenuCycle(effectiveGroup),
        { enabled: !!effectiveGroup }
    );

    const startDate = menuCycle?.data?.startDate;
    const templateId = menuCycle?.data?.templateId;
    const weeklyMenus = menuCycle?.data?.weeklyMenus;
    // Fetch combined weekly menus
    const { 
        data: weeklyMenuData, 
        error: weeklyMenuDataError, 
        isLoading: weeklyMenuDataLoading 
    } = useQuery(
        ['getWeeklyMenuDataForUser', { weeklyMenus, templateId }],
        () => getWeeklyMenus(weeklyMenus, templateId),
        { enabled: !!weeklyMenus && !!templateId && !!effectiveGroup }
        );

    // Error and Loading states
    if (cycleError) return <div>Nånting gick fel vid hämtning av menycykeln</div>;
    if (cycleLoading) return <div><Spinner /></div>;
    if (weeklyMenuDataError) return <div>Nånting gick fel vid hämtning av veckomenyn</div>;
    if (weeklyMenuDataLoading) return <div><Spinner /></div>;

    return (
        <div className="m-auto">
            {isAdmin && (  // Only show group selector for admin
                <div className="mb-3">
                    <select
                        value={selectedGroup || ""}
                        onChange={(e) => setSelectedGroup(e.target.value || null)}
                        className="form-select w-75 m-auto mt-3"
                    >
                        <option value="">Välj en grupp</option>
                        <option value="junior">Skolan/Förskolan</option>
                        <option value="senior">Äldreomsorg</option>
                    </select>
                </div>
            )}
            {effectiveGroup && (
                <MenuCalendar
                    weeklyMenu={weeklyMenuData?.data}
                    startDate={startDate}
                    groupType={effectiveGroup}
                />
            )}
        </div>
    );
}

export default WeeklyMenus;