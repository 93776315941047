import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Icon from './tagIcon';

function TagIcons({ changeSelection }) {
    const [selectTagIcon, setSelectTagIcon] = useState({ tagIcon: './imgsAndVideos/defaultIcon.png' });

    // Sync the selected tag icon with the parent component after render
    useEffect(() => {
        changeSelection(selectTagIcon);
    }, [selectTagIcon, changeSelection]); // Runs when selectTagIcon or changeSelection changes

    return (
        <>
            <Row xs={2} md={2} xlg={6} className="g-4">
                {Array.from(Icon).map((img, index) => (
                    <Col key={index} className="mb-3">
                        <Form>
                            <label htmlFor={`Icon-${index}`} name="tagIcon">
                                <img src={img.tagIcon_img} alt="Icon" />
                            </label>
                            <Form.Check
                                style={{ display: 'none' }}
                                label={`Icon-${index}`}
                                name="tagIcon"
                                value={img.tagIcon_img}
                                type="radio"
                                id={`Icon-${index}`}
                                onClick={(e) => setSelectTagIcon({ tagIcon: e.target.value })}
                            />
                        </Form>
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default TagIcons;