const Icon = [
    {
        tagIcon_img: 'imgsAndVideos/beef.png'
    },
    {
        tagIcon_img: 'imgsAndVideos/chicken.png'
    },
    {
        tagIcon_img: 'imgsAndVideos/fish.png'
    },
    {
        tagIcon_img: 'imgsAndVideos/leaf.png'
    },
    {
        tagIcon_img: 'imgsAndVideos/defaultIcon.png'
    },
    {
        tagIcon_img: 'imgsAndVideos/pig.png'
    }
]

export default Icon;