import React from 'react';
import { Col } from "react-bootstrap";
import { Droppable, Draggable } from 'react-beautiful-dnd';

function DishesMenu({ dishes, weeks }) {
  return (
    <Col md={4} xs={12} className="mb-3">
      {weeks.length > 0 && (
        <div className="dishes-menu">
          <h2>Maträtter</h2>
          <Droppable droppableId="dishesMenu" isDropDisabled={true}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className="dishes-list">
                {dishes?.map((dish, index) => (
                  <Draggable key={dish._id} draggableId={dish.dishName} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="dish-item"
                      >
                        {dish?.dishName}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Col>
  );
}

export default DishesMenu;