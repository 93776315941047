import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useCreatePicnicOrder, useFetchPicnicOrders, useDeletePicnicOrder } from "../../hooks/foodOrders/picnicOrder";
import { useQuery, useQueryClient } from 'react-query';
import { toast } from "react-toastify";

const PicnicOrderTable = ({ weekNumber, weekOffset, selectedPackage }) => {
    const queryClient = useQueryClient();

    // Fetch orders with error handling
    const {
        data: picnicOrders = [],
        refetch,
        isLoading,
        isError,
        error
    } = useQuery(
        ['getOrders', weekNumber],
        () => useFetchPicnicOrders(weekNumber),
        {
            enabled: !!weekNumber,
            onError: (err) => {
                console.error('Fetch error:', err);
                if (err.response?.status === 404) {
                    return [];
                }
            },
            select: (data) => data || [],
        }
    );
    const days = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag'];

    // State to manage selections, quantities, and customInfo per day
    const [orders, setOrders] = useState({
        mainDish: {},
        beverages: {},
        fruits: {},
        customInfo: {}
    });

    // Temporary selections and quantities for dropdowns
    const [tempSelections, setTempSelections] = useState({
        mainDish: {},
        beverages: {},
        fruits: {}
    });
    const [tempQuantities, setTempQuantities] = useState({
        mainDish: {},
        beverages: {},
        fruits: {}
    });

    // Temporary state for customInfo inputs
    const [tempCustomInfo, setTempCustomInfo] = useState({
        department: {},
        pickupTime: {},
        contactInfo: {},
        comments: {}
    });

    // New state to track touched customInfo fields
    const [touchedCustomInfo, setTouchedCustomInfo] = useState({
        department: {},
        pickupTime: {},
        contactInfo: {},
        comments: {}
    });

    // Get unique main dishes
    const mainDishes = [...new Set(selectedPackage?.map(item => item.dishName) || [])];

    // Helper functions (unchanged)
    const getDishDataForDay = (day) => {
        const selectedDishNames = (orders.mainDish[day] || []).map(order => order.item);
        return selectedPackage?.filter(item => selectedDishNames.includes(item.dishName)) || [];
    };

    const getTotalQuantity = (category, day) => {
        return (orders[category][day] || []).reduce((sum, order) => sum + order.quantity, 0);
    };

    const getAllergicItemsForDay = (day) => {
        const dishData = getDishDataForDay(day);
        const allAllergies = dishData.flatMap(dish => dish.allergic || []);
        return [...new Set(allAllergies)];
    };

    const getUniqueSidesForDay = (day) => {
        const dishData = getDishDataForDay(day);
        const allSides = dishData.length > 0 ? dishData.flatMap(d => [
            ...(d.sideIngredient ? [d.sideIngredient] : []),
            ...(d.extraSideIngredient?.filter(side => side.extraSideIngredient)
                .map(side => side.extraSideIngredient) || [])
        ]) : [];
        return [...new Set(allSides)];
    };

    const handleTempSelectionChange = (category, day, value) => {
        setTempSelections(prev => ({
            ...prev,
            [category]: { ...prev[category], [day]: value }
        }));
    };

    const handleTempQuantityChange = (category, day, value) => {
        setTempQuantities(prev => ({
            ...prev,
            [category]: { ...prev[category], [day]: value === '' ? 0 : parseInt(value) || 0 }
        }));
    };

    // Handler for customInfo input changes with touch tracking
    const handleCustomInfoChange = (field, day, value) => {
        setTempCustomInfo(prev => ({
            ...prev,
            [field]: { ...prev[field], [day]: value }
        }));
        // Mark field as touched if it has a value
        if (value) {
            setTouchedCustomInfo(prev => ({
                ...prev,
                [field]: { ...prev[field], [day]: true }
            }));
        }
    };

    // Add customInfo to orders and clear touched state
    const handleAddCustomInfo = (day) => {
        const department = tempCustomInfo.department[day] || '';
        const pickupTime = tempCustomInfo.pickupTime[day] || '';
        const contactInfo = tempCustomInfo.contactInfo[day] || '';
        const comments = tempCustomInfo.comments[day] || '';

        if (!department && !pickupTime && !contactInfo && !comments) {
            return; // No action if all fields are empty
        }

        setOrders(prev => ({
            ...prev,
            customInfo: {
                ...prev.customInfo,
                [day]: [
                    ...(prev.customInfo[day] || []),
                    { department, pickupTime, contactInfo, comments }
                ]
            }
        }));

        // Reset temp inputs and touched state
        setTempCustomInfo(prev => ({
            ...prev,
            department: { ...prev.department, [day]: '' },
            pickupTime: { ...prev.pickupTime, [day]: '' },
            contactInfo: { ...prev.contactInfo, [day]: '' },
            comments: { ...prev.comments, [day]: '' }
        }));
        setTouchedCustomInfo(prev => ({
            ...prev,
            department: { ...prev.department, [day]: false },
            pickupTime: { ...prev.pickupTime, [day]: false },
            contactInfo: { ...prev.contactInfo, [day]: false },
            comments: { ...prev.comments, [day]: false }
        }));
    };

    // Remove customInfo entry (unchanged)
    const handleRemoveCustomInfo = (day, index) => {
        setOrders(prev => ({
            ...prev,
            customInfo: {
                ...prev.customInfo,
                [day]: (prev.customInfo[day] || []).filter((_, i) => i !== index)
            }
        }));
    };

    const handleAddItem = (category, day) => {
        const selectedItem = tempSelections[category][day];
        const quantity = tempQuantities[category][day] || 0;
        if (!selectedItem || !quantity) return;

        if (category !== 'mainDish') {
            const mainDishTotal = getTotalQuantity('mainDish', day);
            const currentTotal = getTotalQuantity(category, day);
            const newTotal = currentTotal + quantity;

            if (!mainDishTotal || newTotal > mainDishTotal) {
                alert(`Total ${category} (${newTotal}) cannot exceed main dish total (${mainDishTotal || 0})`);
                return;
            }
        }

        setOrders(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [day]: [...(prev[category][day] || []), { item: selectedItem, quantity }]
            }
        }));
        setTempSelections(prev => ({
            ...prev,
            [category]: { ...prev[category], [day]: '' }
        }));
        setTempQuantities(prev => ({
            ...prev,
            [category]: { ...prev[category], [day]: 0 }
        }));
    };

    const handleRemoveItem = (category, day, index) => {
        setOrders(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [day]: (prev[category][day] || []).filter((_, i) => i !== index)
            }
        }));
    };

    const getWeekDates = () => {
        const today = new Date();
        if (isNaN(today.getTime())) {
            console.error('Invalid base date');
            return days.map(() => 'Invalid Date');
        }

        const startDate = new Date(today);
        const dayOfWeek = startDate.getDay();
        const daysToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
        startDate.setDate(startDate.getDate() + daysToMonday);
        startDate.setDate(startDate.getDate() + (weekOffset * 7));

        return days.map((_, index) => {
            const date = new Date(startDate);
            date.setDate(startDate.getDate() + index);
            if (isNaN(date.getTime())) {
                console.error(`Invalid date for ${days[index]}:`, date);
                return 'Invalid Date';
            }
            return date.toISOString().split('T')[0];
        });
    };

    const weekDates = getWeekDates();
    const createPicnicOrder = useCreatePicnicOrder();

    // Warn user if customInfo fields are touched but not added
    const handleSendOrder = () => {
        // Check for touched but unadded customInfo
        const hasUnaddedCustomInfo = days.some(day => {
            return (
                (touchedCustomInfo.department[day] && tempCustomInfo.department[day]) ||
                (touchedCustomInfo.pickupTime[day] && tempCustomInfo.pickupTime[day]) ||
                (touchedCustomInfo.contactInfo[day] && tempCustomInfo.contactInfo[day]) ||
                (touchedCustomInfo.comments[day] && tempCustomInfo.comments[day])
            );
        });

        if (hasUnaddedCustomInfo) {
            toast.warn("Du har ändrat anpassad info men inte lagt till den. Klicka på '+' för att spara dina ändringar innan du skickar.");
            return;
        }

        const orderData = {
            weekNumber,
            days: days.reduce((acc, day, index) => {
                const dayOrders = {
                    mainDish: orders.mainDish[day] || [],
                    beverages: orders.beverages[day] || [],
                    fruits: orders.fruits[day] || [],
                    customInfo: orders.customInfo[day] || []
                };
                if (dayOrders.mainDish.length > 0 || dayOrders.beverages.length > 0 || dayOrders.fruits.length > 0 || dayOrders.customInfo.length > 0) {
                    acc[day] = { date: weekDates[index], ...dayOrders };
                }
                return acc;
            }, {})
        };
        createPicnicOrder.mutate(orderData);
    };

    const deletePicnicOrder = useDeletePicnicOrder();
    const handleDeleteOrder = (orderId) => {
        deletePicnicOrder.mutate(orderId, {
            onSuccess: () => {
                queryClient.invalidateQueries(['getOrders', weekNumber]);
            },
            onError: (error) => {
                console.error('Failed to delete order:', error);
                alert('Could not delete order. Please try again.');
            }
        });
    };

    const renderRow = (category, label = true) => (
        <tr>
            <td>{label}</td>
            {days.map((day, index) => {
                const uniqueSides = getUniqueSidesForDay(day);
                const beverages = uniqueSides.filter(side =>
                    ['mjölk', 'vatten', 'juice'].some(drink => side.toLowerCase().includes(drink))
                );
                const fruits = uniqueSides.filter(side =>
                    ['äpple', 'päron', 'banan', 'mandarin'].includes(side.toLowerCase())
                );
                const items = category === 'mainDish' ? mainDishes : category === 'beverages' ? beverages : fruits;

                if (category === 'allergic') {
                    const allergicItems = getAllergicItemsForDay(day);
                    return (
                        <td key={index}>
                            {allergicItems.length > 0 ? (
                                <ul className="allergic-list">
                                    {allergicItems.map((item, idx) => (
                                        <li key={idx}>{item}</li>
                                    ))}
                                </ul>
                            ) : (
                                'Inga allergener'
                            )}
                        </td>
                    );
                }

                if (category === 'customInfo') {
                    return (
                        <td key={index}>
                            <div className="input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Avdelning (t.ex. 8B)"
                                    value={tempCustomInfo.department[day] || ''}
                                    onChange={(e) => handleCustomInfoChange('department', day, e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Hämtningstid (t.ex. 12:00)"
                                    value={tempCustomInfo.pickupTime[day] || ''}
                                    onChange={(e) => handleCustomInfoChange('pickupTime', day, e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-2">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Kontaktinfo (t.ex. 12-3456-789 John Doe)"
                                    value={tempCustomInfo.contactInfo[day] || ''}
                                    onChange={(e) => handleCustomInfoChange('contactInfo', day, e.target.value)}
                                />
                            </div>
                            <div className="input-group mb-2">
                                <textarea
                                    className="form-control"
                                    placeholder="Kommentarer (t.ex. specialkost)"
                                    value={tempCustomInfo.comments[day] || ''}
                                    onChange={(e) => handleCustomInfoChange('comments', day, e.target.value)}
                                />
                                <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={() => handleAddCustomInfo(day)}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>
                            <ul className="order-list">
                                {(orders.customInfo[day] || []).map((info, idx) => (
                                    <li key={idx}>
                                        <span>
                                            Avdelning: {info.department}, Hämtningstid: {info.pickupTime}, Kontakt: {info.contactInfo}, Kommentar: {info.comments}
                                        </span>
                                        <button
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() => handleRemoveCustomInfo(day, idx)}
                                        >
                                            <FontAwesomeIcon icon={faMinus} />
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    );
                }

                return (
                    <td key={index}>
                        <div className="input-group">
                            <select
                                className="form-select mb-2"
                                value={tempSelections[category][day] || ''}
                                onChange={(e) => handleTempSelectionChange(category, day, e.target.value)}
                                disabled={!items.length}
                            >
                                <option value="">Välj {label.toLowerCase()}</option>
                                {items.map((item, idx) => (
                                    <option key={idx} value={item}>{item}</option>
                                ))}
                            </select>
                            <button
                                className="btn btn-outline-primary btn-sm mb-2"
                                onClick={() => handleAddItem(category, day)}
                                disabled={!tempSelections[category][day] || !tempQuantities[category][day]}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                        <input
                            type="number"
                            className="form-control mb-2"
                            min="0"
                            placeholder="Antal"
                            value={tempQuantities[category][day] || ''}
                            onChange={(e) => handleTempQuantityChange(category, day, e.target.value)}
                        />
                        <ul className="order-list">
                            {(orders[category][day] || []).map((order, idx) => (
                                <li key={idx}>
                                    <span>{order.item}: {order.quantity}</span>
                                    <button
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={() => handleRemoveItem(category, day, idx)}
                                    >
                                        <FontAwesomeIcon icon={faMinus} />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </td>
                );
            })}
        </tr>
    );

    return (
        <div className="picnic-order-container">
            <table className="user-order-table">
                <thead>
                    <tr>
                        <th>Kategori</th>
                        {days.map((day, index) => (
                            <th key={index} title={weekDates[index]}>{day}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {renderRow('mainDish', 'Huvudrätt')}
                    {renderRow('beverages', 'Dryck')}
                    {renderRow('fruits', 'Frukt')}
                    {renderRow('allergic', 'Allergener', false)}
                    {renderRow('customInfo', 'Anpassad Info', false)}
                </tbody>
            </table>
            <div className="text-center mt-4">
                <button className="btn btn-success mb-5" onClick={handleSendOrder}>
                    Skicka Beställning
                </button>
                <button
                    className="btn btn-primary ms-3 mb-5"
                    onClick={() => {
                        console.log('Refresh clicked, picnicOrders:', picnicOrders);
                        if (isError && error?.response?.status === 404) {
                            toast("Inga Beställningar Hittades för denna användare");
                        } else if (picnicOrders?.data?.length === 0) {
                            toast("Inga Beställningar Hittades");
                        } else {
                            refetch().then(() => {
                                console.log('Refetch completed');
                                toast("Inga beställningar att visa");
                            }).catch((err) => {
                                console.error('Refetch failed:', err);
                                toast("Misslyckades att hämta beställningar");
                            });
                        }
                    }}
                    disabled={isLoading}
                >
                    {isLoading ? 'Laddar...' : 'Hämta Beställningar'}
                </button>
                {isLoading ? (
                    <p>Laddar beställningar...</p>
                ) : isError && error?.response?.status === 404 ? (
                    <p>Inga beställningar hittades för denna användare.</p>
                ) : picnicOrders?.data?.length > 0 ? (
                    <table className="user-order-table">
                        <thead>
                            <tr className="font-size-s">
                                <th>Veckonummer</th>
                                <th>Datum</th>
                                <th>Dag</th>
                                <th>Huvudrätt</th>
                                <th>Dryck</th>
                                <th>Frukt</th>
                                <th>Anpassad Info</th>
                                <th>Skapad</th>
                                <th>Åtgärd</th>
                            </tr>
                        </thead>
                        <tbody>
                            {picnicOrders?.data?.flatMap(order =>
                                order.days.map(dayOrder => (
                                    <tr key={dayOrder._id}>
                                        <td>{order.weekNumber}</td>
                                        <td>{dayOrder.date}</td>
                                        <td>{dayOrder.dayName}</td>
                                        <td>
                                            {dayOrder.mainDish.map(dish => (
                                                <>{dish.item}: {dish.quantity}<br /><hr /></>
                                            ))}
                                        </td>
                                        <td>
                                            {dayOrder.beverages.map(drink => (
                                                <>{drink.item}: {drink.quantity}<br /><hr /></>
                                            ))}
                                        </td>
                                        <td>
                                            {dayOrder.fruits.map(fruit => (
                                                <>{fruit.item}: {fruit.quantity}<br /><hr /></>
                                            ))}
                                        </td>
                                        <td className='text-start'>
                                            {dayOrder.customInfo && dayOrder.customInfo.length > 0 ? (
                                                dayOrder.customInfo.map((info, idx) => (
                                                    <div key={idx}>
                                                        Avdelning: {info.department}<br />
                                                        Hämtningstid: {info.pickupTime}<br />
                                                        Kontakt: {info.contactInfo}<br />
                                                        Kommentar: {info.comments}<br />
                                                        <hr />
                                                    </div>
                                                ))
                                            ) : (
                                                'Ingen anpassad info'
                                            )}
                                        </td>
                                        <td>{new Date(order.createdAt).toLocaleString()}</td>
                                        <td>
                                            <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() => handleDeleteOrder(order._id)}
                                            >
                                                <FontAwesomeIcon icon={faTrash} />
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                ) : (
                    <p>Inga beställningar att visa.</p>
                )}
            </div>
        </div>
    );
};

export default PicnicOrderTable;