import React, { useEffect, useState } from "react";
import { CreateSideTemplate, UpdateSideTemplate, getSideTemplate, getSideTemplates, DeleteOneSide } from "../../../../../hooks/admin/superiorAdmin";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { Modal, Row, Col, Button } from "react-bootstrap";
import Spinner from "../../../../../Partials/Spinner";

const SidesTemplate = () => {
    // Modal states
    const [showSideNew, setShowSideNew] = useState(false);
    const [showSideDelete, setShowSideDelete] = useState(false);
    const handleCloseSideNew = () => setShowSideNew(false);
    const handleShowSideNew = () => setShowSideNew(true);
    const handleCloseSideDelete = () => setShowSideDelete(false);
    const handleShowSideDelete = () => setShowSideDelete(true);

    // Component states
    const [sideId, setSideId] = useState('63793934371bc9e6500f982d');
    const [sideTemplateName, setTemplateName] = useState("");
    const [group, setGroup] = useState('');
    const [id, setId] = useState('63793934371bc9e6500f982d');
    const [isDeleted, setIsDeleted] = useState(false);

    // Separate form instances
    const mainForm = useForm();
    const modalForm = useForm();

    // Queries and mutations
    const { data: getSide, isLoading, error } = useQuery(
        ['getSideTemplate', { group, sideTemplateName }],
        () => getSideTemplate({ group, sideTemplateName }),
        { enabled: !!(group && sideTemplateName) }
    );

    const { data: getSides } = useQuery(
        ['getSideTemplates', group],
        () => getSideTemplates(group),
        { enabled: !!group }
    );
    const { mutate: createSides, isLoading: isCreating } = CreateSideTemplate();
    const { mutate: updateSides } = UpdateSideTemplate();
    const { mutate: deleteSides } = DeleteOneSide();

    // Watch group value from main form
    const groupValue = mainForm.watch('group');

    useEffect(() => {
        setId(getSide?.data[0]?._id);
        setGroup(groupValue);
    }, [getSide, groupValue]);

    const onSubmit = (data) => {
            updateSides({ id, data });
    };

    const handleNewTemplate = (data) => {
        console.log(data)
        createSides(data, {
            onSuccess: () => {
                handleCloseSideNew();
                modalForm.reset(); // Reset modal form after success
            },
            onError: (error) => {
                console.error('Error creating template:', error);
            }
        });
    };

    const handleSideDelete = () => {
        deleteSides({ id, sideId, isDeleted });
    };

    if (error) return <div>Nånting gick fel</div>;
    if (isLoading) return (
        <div style={{ height: '100vh' }}>
            <Spinner />
        </div>
    );

    return (
        <>
            <Row className="mb-2">
                <Col sm={{ span: 2, offset: 10 }}>
                    <button
                        className="mainButtonLite text-light"
                        onClick={handleShowSideNew}
                    >Ny</button>
                </Col>
            </Row>

            {/* Main Form */}
            <form onSubmit={mainForm.handleSubmit(onSubmit)} className="overflow">
                <div className="mt-5 mb-5 w-75 m-auto">
                    {mainForm.formState.errors.sideIngredient && (
                        <p className="ms-1 p-1 error fs-6" role="alert">
                            Fyll i tillbehörs namnet
                        </p>
                    )}
                    <div>
                        <select
                            {...mainForm.register("group", { required: true })}
                            name="group"
                            className="form-select mt-4 mb-4"
                        >
                            <option value="">Välj grupp</option>
                            <option value="junior">Skolan,Förskolan</option>
                            <option value="senior">Äldreomsorg/Matlåda</option>
                        </select>
                        <select
                            name="templateName"
                            className="form-select mt-4 mb-4"
                            onChange={(e) => setTemplateName(e.target.value)}
                        >
                            <option value="">Välj tillbehör mallen</option>
                            {getSides?.data?.map((item) => (
                                <option key={item._id} value={item.sideTemplateName}>
                                    {item.sideTemplateName}
                                </option>
                            ))}
                        </select>
                        <input
                            className="form-control mb-4"
                            type="text"
                            name="sideIngredient"
                            placeholder="Fyll i tillbehörs namnet"
                            {...mainForm.register("sideIngredient", { required: true })}
                        />
                        <input
                            className="form-control"
                            type="number"
                            step={0.01}
                            placeholder="Standard mängd per gäst"
                            {...mainForm.register("defaultPerGuest", { required: true })}
                        />
                        <select
                            {...mainForm.register("unit", { required: true })}
                            className="form-select mt-4 mb-4"
                        >
                            <option value="">Välj enhet</option>
                            <option value="Kilo">Kilo</option>
                            <option value="Liter">Liter</option>
                            <option value="Styck">Styck</option>
                        </select>
                    </div>
                    <button className="stats_card w-50 m-auto" type="submit">
                        <span>Bekräfta</span>
                    </button>
                </div>
            </form>

            <hr />

            {/* Sides List */}
            <div className="overflow">
                {getSide?.data[0]?.side?.map((item, index) => (
                    <Row className="m-auto" key={index}>
                        <Col xs={7}>
                            <button className="stats_card w-100 mb-3">
                                {item.sideIngredient}
                            </button>
                        </Col>
                        <Col xs={4}>
                            <button className="stats_card w-100 mb-3">
                                {item.defaultPerGuest}({item.unit})/pers
                            </button>
                        </Col>
                        <Col xs={1}>
                            <span
                                type="button"
                                onClick={() => {
                                    setSideId(item?._id);
                                    handleShowSideDelete();
                                }}
                            >
                                <i className="fa-solid fa-circle-xmark glow_red"></i>
                            </span>
                        </Col>
                    </Row>
                ))}
            </div>

            {/* Delete Modal */}
            <Modal show={showSideDelete} onHide={handleCloseSideDelete}>
                <Modal.Header>Radera den typ?</Modal.Header>
                <Modal.Body>
                    <Button
                        className="w-100 mb-2"
                        variant="danger"
                        onClick={() => {
                            setIsDeleted(true);
                            handleSideDelete();
                            handleCloseSideDelete();
                        }}
                    >Ja</Button>
                    <Button
                        className="w-100"
                        variant="secondary"
                        onClick={handleCloseSideDelete}
                    >Nej</Button>
                </Modal.Body>
            </Modal>

            {/* New Template Modal */}
            <Modal show={showSideNew} onHide={handleCloseSideNew}>
                <Modal.Header>Skaffa ny mall</Modal.Header>
                <Modal.Body>
                    <form onSubmit={modalForm.handleSubmit(handleNewTemplate)}>
                        <select
                            {...modalForm.register("group", { required: "Välj en grupp" })}
                            name="group"
                            className="form-select mt-4 mb-4"
                        >
                            <option value="">Välj grupp</option>
                            <option value="junior">Skolan,Förskolan</option>
                            <option value="senior">Äldreomsorg/Matlåda</option>
                        </select>
                        {modalForm.formState.errors.group && (
                            <p className="ms-1 p-1 error fs-6" role="alert">
                                {modalForm.formState.errors.group.message}
                            </p>
                        )}
                        <input
                            className="form-control mb-4"
                            type="text"
                            name="sideTemplateName"
                            placeholder="Fyll i mallen namnet"
                            {...modalForm.register("sideTemplateName", { required: "Fyll i mallens namn" })}
                        />
                        {modalForm.formState.errors.templateName && (
                            <p className="ms-1 p-1 error fs-6" role="alert">
                                {modalForm.formState.errors.templateName.message}
                            </p>
                        )}
                        <button
                            type="submit"
                            className="w-50 mb-2 mt-4 mainButton m-auto"
                            disabled={isCreating}
                        >
                            <span>{isCreating ? 'Skapar...' : 'Bekräfta'}</span>
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SidesTemplate;