import React from 'react';
import { Row, Col } from "react-bootstrap";

function MealOptionsControls({ lunchCount, setLunchCount, supperCount, setSupperCount, setWeeks }) {
  const increaseLunchCount = () => {
    setLunchCount(lunchCount + 1);
    setWeeks(prevWeeks => prevWeeks.map(week => ({
      ...week,
      days: week.days.map(day => ({
        ...day,
        lunch: [...day.lunch, { alt: day.lunch.length, dish: null, side: [] }]
      }))
    })));
  };

  const decreaseLunchCount = () => {
    if (lunchCount > 1) {
      setLunchCount(lunchCount - 1);
      setWeeks(prevWeeks => prevWeeks.map(week => ({
        ...week,
        days: week.days.map(day => ({
          ...day,
          lunch: day.lunch.slice(0, -1)
        }))
      })));
    }
  };

  const increaseSupperCount = () => {
    setSupperCount(supperCount + 1);
    setWeeks(prevWeeks => prevWeeks.map(week => ({
      ...week,
      days: week.days.map(day => ({
        ...day,
        supper: [...day.supper, { alt: day.supper.length, dish: null, side: [] }]
      }))
    })));
  };

  const decreaseSupperCount = () => {
    if (supperCount > 1) {
      setSupperCount(supperCount - 1);
      setWeeks(prevWeeks => prevWeeks.map(week => ({
        ...week,
        days: week.days.map(day => ({
          ...day,
          supper: day.supper.slice(0, -1)
        }))
      })));
    }
  };

  return (
    <Row className="mb-4">
      <Col>
        <div className="d-flex gap-3">
          <button className="mainButtonLite text-light w-25" onClick={decreaseLunchCount}>-</button>
          <div className="text-light font-size-xs">Lunch Alternativ: {lunchCount}</div>
          <button className="mainButtonLite text-light w-25" onClick={increaseLunchCount}>+</button>
        </div>
      </Col>
      <Col>
        <div className="d-flex gap-3">
          <button className="mainButtonLite text-light w-25" onClick={decreaseSupperCount}>-</button>
          <div className="text-light font-size-xs">Middag Alternativ: {supperCount}</div>
          <button className="mainButtonLite text-light w-25" onClick={increaseSupperCount}>+</button>
        </div>
      </Col>
    </Row>
  );
}

export default MealOptionsControls;