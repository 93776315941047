import React from 'react';
import { Modal, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";

function ActivationModal({
  show, setShow, cycleId, setCycleId, startDate, setStartDate, isLooping,
  setIsLooping, menuCycles, selectedGroup, updateCycleMutation
}) {
  const isMonday = (date) => date.getDay() === 1;

  const activateCycle = () => {
    if (isLooping) {
      const sameGroupCycles = menuCycles?.data?.MenuCycle.filter(c => c.group === selectedGroup && c._id !== cycleId && c.isLooping);
      if (sameGroupCycles?.length > 0) {
        toast.warn('Another cycle is already active in this group. Deactivating others first.');
        sameGroupCycles.forEach(cycle => {
          updateCycleMutation({ cycleId: cycle._id, updateData: { isLooping: false } });
        });
      }
    }
    const updateData = { startDate, isLooping };
    updateCycleMutation({ cycleId, updateData }, {
      onSuccess: () => toast.success('Cycle activation updated!'),
      onError: () => toast.error('Error updating activation'),
    });
  };

  return (
    <Modal size="md" show={show} centered onHide={() => setShow(false)} aria-labelledby="example-modal-sizes-title-sm">
      <Modal.Header closeButton>
        <Modal.Title>Active Datum</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <select className="form-control mb-3" onChange={(e) => setCycleId(e.target.value)} value={cycleId}>
              <option value="">Välj en cykel</option>
              {menuCycles?.data?.MenuCycle.map((cycle) => (
                <option key={cycle._id} value={cycle._id}>
                  {cycle?.cycleName} ({cycle.group})
                </option>
              ))}
            </select>
          </Col>
          <Col>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              filterDate={isMonday}
              placeholderText="Välj start datum"
              dateFormat="yyyy-MM-dd"
            />
            <label className="mt-3">
              Loopar:
              <input
                type="checkbox"
                className="ms-3"
                checked={isLooping}
                onChange={(e) => setIsLooping(e.target.checked)}
              />
            </label>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button type="submit" className="stats_card w-100 mt-3" onClick={activateCycle}>
          {isLooping ? "Activera" : "Inactivera"}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ActivationModal;