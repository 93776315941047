// src/components/PicnicLunchOrderForm.js
import React, { useState, useEffect, useContext } from 'react';
import { Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Spinner from '../../Partials/Spinner';
import { contextData } from "../../ContextApi";
import { getMenu, getMenusTemplates } from '../../hooks/menu/menu';
import PicnicOrderTable from './PicnicOrderTable';

const PicnicLunchOrderForm = () => {
    const { userdata } = useContext(contextData);
    const selectedGroup = userdata?.group;
    const [weekOffset, setWeekOffset] = useState(0);
    const [weekNumber, setWeekNumber] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [templateName, setTemplateName] = useState('templateName');

    // Fetch picnic packages
    const { data: menuTemplate } = useQuery(
        ['getMenuTemplates', selectedGroup],
        () => getMenusTemplates(selectedGroup),
        { enabled: true }
    );
    const { data: picnicPackages, isLoading: packagesLoading } = useQuery(
        ['getMenu', { selectedGroup, templateName }],
        () => getMenu({ selectedGroup, templateName }),
        {
            enabled: true,
            onSuccess: (data) => {
                if (data?.length && !selectedPackage) {
                    setSelectedPackage(data[0].menu);
                }
            }
        }
    );

    // Initialize weekNumber and weekOffset with current date as default
    useEffect(() => {
        const today = new Date();
        setStartDate(today);
        const firstWeekNumber = getWeekNumber(today);
        const msPerWeek = 7 * 24 * 60 * 60 * 1000;
        const weeksElapsed = Math.floor((today - today) / msPerWeek); // Will be 0 for current week
        setWeekNumber(firstWeekNumber + (weeksElapsed >= 0 ? weeksElapsed : 0));
        setWeekOffset(weeksElapsed >= 0 ? weeksElapsed : 0);
    }, []);

    const getWeekNumber = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    };

    const getWeekDateRange = (offset) => {
        const effectiveStartDate = startDate || new Date();
        const weekStart = new Date(effectiveStartDate);
        weekStart.setDate(weekStart.getDate() + offset * 7);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);

        const monthNames = [
            'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni',
            'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
        ];

        const startDay = weekStart.getDate();
        const startMonth = monthNames[weekStart.getMonth()];
        const endDay = weekEnd.getDate();
        const endMonth = monthNames[weekEnd.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}${weekStart.getFullYear() !== weekEnd.getFullYear() ? ` ${weekEnd.getFullYear()}` : ''}`;
    };

    const handlePrevWeek = () => {
        setWeekOffset(prev => {
            const newOffset = prev - 1;
            const weekStart = new Date(startDate || new Date());
            weekStart.setDate(weekStart.getDate() + newOffset * 7);
            setWeekNumber(getWeekNumber(weekStart));
            return newOffset;
        });
    };

    const handleNextWeek = () => {
        setWeekOffset(prev => {
            const newOffset = prev + 1;
            const weekStart = new Date(startDate || new Date());
            weekStart.setDate(weekStart.getDate() + newOffset * 7);
            setWeekNumber(getWeekNumber(weekStart));
            return newOffset;
        });
    };

    const handlePackageChange = (event) => {
        const selectedPkg = picnicPackages.data.find(pkg =>
            JSON.stringify(pkg.menu) === event.target.value
        );
        setSelectedPackage(selectedPkg ? selectedPkg.menu : null);
    };

    if (packagesLoading) {
        return <Spinner />;
    }

    return (
        <div className="m-auto">
            <Row className='d-flex mb-5'>
                <div className="carousel-header">
                    <button
                        className="mainButtonLite"
                        onClick={handlePrevWeek}
                    >
                        <i className="fa-solid fa-arrow-left text-light"></i>
                    </button>
                    <span className="font-size-md text-light">
                        {getWeekDateRange(weekOffset)} {weekNumber ? `(Vecka ${weekNumber})` : ''}
                    </span>
                    <button
                        className="mainButtonLite"
                        onClick={handleNextWeek}
                    >
                        <i className="fa-solid fa-arrow-right text-light"></i>
                    </button>
                </div>
            </Row>
            <Row>
                <select
                    id="packageSelect"
                    className="form-select w-25 m-auto"
                    onChange={(e) => setTemplateName(e.target.value)}
                >
                    <option>Välj mall</option>
                    {menuTemplate?.data?.map((pkg) => (
                        <option key={pkg?._id} value={pkg.templateName}>
                            {pkg.templateName || `Paket ${pkg._id}`}
                        </option>
                    ))}
                </select>
            </Row>
            {/* Package Selection */}
            {picnicPackages?.data?.length > 0 && (
                <Row className="mb-4">
                    <div className="w-25 m-auto">
                        <label htmlFor="packageSelect" className="form-label text-light">
                            Välj picknickpaket
                        </label>
                        <select
                            id="packageSelect"
                            className="form-select"
                            value={selectedPackage ? JSON.stringify(selectedPackage) : ''}
                            onChange={handlePackageChange}
                        ><option>Välj meny</option>
                            {picnicPackages?.data?.map((pkg) => (
                                <option key={pkg?._id} value={JSON.stringify(pkg.menu)}>
                                    {pkg.templateName || `Paket ${pkg._id}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </Row>
            )}

            {/* Child Component */}
            <PicnicOrderTable
                weekNumber={weekNumber}
                weekOffset={weekOffset}
                selectedPackage={selectedPackage}
            />
        </div>
    );
};

export default PicnicLunchOrderForm;