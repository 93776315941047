import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import JuniorOrderTable from '../plan/mealOrders/JuniorOrderTable';
import SeniorOrderTable from '../plan/mealOrders/SeniorOrderTable';
import { useQuery } from 'react-query';
import { getMenuCycle, getWeeklyMenus } from '../../hooks/menu/menu';

const OrderFormBase = () => {
    const [group, setGroup] = useState(null); // No default group
    const [weekOffset, setWeekOffset] = useState(0);
    const [weekNumber, setWeekNumber] = useState(null);

    // Fetch MenuCycle for startDate
    const { data: MenuCycle, isLoading: cycleLoading } = useQuery(
        ['getMenuCycle', group],
        () => getMenuCycle(group),
        { enabled: !!group }
    );
    const startDate = MenuCycle?.data?.startDate;
    const templateId = MenuCycle?.data?.templateId;
    const weeklyMenuId = MenuCycle?.data?.weeklyMenus;

    // Fetch weeklyMenuData
    const { data: weeklyMenuData, isLoading: menuLoading } = useQuery(
        ['getCombinedMenuForUser', { weeklyMenuId, templateId }],
        () => getWeeklyMenus(weeklyMenuId, templateId),
        { enabled: !!weeklyMenuId && !!templateId }
    );
    const getWeekNumber = (date) => {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    };

    // Initialize weekNumber and weekOffset with a default date, update on startDate
    useEffect(() => {
        const today = new Date();
        const defaultStartDate = startDate || today; // Fallback to today if startDate not yet available
        const cycleStartDate = new Date(defaultStartDate);
        const msPerWeek = 7 * 24 * 60 * 60 * 1000;
        const weeksElapsed = Math.floor((today - cycleStartDate) / msPerWeek);
        const firstWeekNumber = getWeekNumber(cycleStartDate);
        setWeekNumber(firstWeekNumber + (weeksElapsed >= 0 ? weeksElapsed : 0));
        setWeekOffset(weeksElapsed >= 0 ? weeksElapsed : 0);
    }, [startDate]);

    const getWeekDateRange = (offset) => {
        const effectiveStartDate = startDate || new Date(); // Fallback to today
        const weekStart = new Date(effectiveStartDate);
        weekStart.setDate(weekStart.getDate() + offset * 7);
        const weekEnd = new Date(weekStart);
        weekEnd.setDate(weekStart.getDate() + 6);

        const monthNames = [
            'Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni',
            'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'
        ];

        const startDay = weekStart.getDate();
        const startMonth = monthNames[weekStart.getMonth()];
        const endDay = weekEnd.getDate();
        const endMonth = monthNames[weekEnd.getMonth()];

        return `${startDay} ${startMonth} - ${endDay} ${endMonth}${weekStart.getFullYear() !== weekEnd.getFullYear() ? ` ${weekEnd.getFullYear()}` : ''}`;
    };

    const handlePrevWeek = () => {
        setWeekOffset(prev => {
            const newOffset = prev - 1;
            const weekStart = new Date(startDate || new Date());
            weekStart.setDate(weekStart.getDate() + newOffset * 7);
            setWeekNumber(getWeekNumber(weekStart));
            return newOffset;
        });
    };

    const handleNextWeek = () => {
        setWeekOffset(prev => {
            const newOffset = prev + 1;
            const weekStart = new Date(startDate || new Date());
            weekStart.setDate(weekStart.getDate() + newOffset * 7);
            setWeekNumber(getWeekNumber(weekStart));
            return newOffset;
        });
    };

    return (
        <div className="m-auto">
            <Row className='d-flex mb-5'>
                <div className="carousel-header">
                    <button className="nav-button prev" onClick={handlePrevWeek}>{"<"}</button>
                    <span className="font-size-md text-light">
                        {getWeekDateRange(weekOffset)} {weekNumber ? `(Vecka ${weekNumber})` : ''}
                    </span>
                    <button className="nav-button next" onClick={handleNextWeek}>{">"}</button>
                </div>
                <button
                    className={`stats_card w-75 m-auto mt-3 ${group === 'junior' ? 'active' : ''}`}
                    onClick={() => setGroup('junior')}
                >
                    Skolan/Förskolan
                </button>
                <button
                    className={`stats_card w-75 m-auto mt-3 ${group === 'senior' ? 'active' : ''}`}
                    onClick={() => setGroup('senior')}
                >
                    Äldreomsorg
                </button>
            </Row>
            {group === 'junior' && !cycleLoading && !menuLoading && (
                <JuniorOrderTable
                    weekNumber={weekNumber}
                    weekOffset={weekOffset}
                    weeklyMenuData={weeklyMenuData}
                    startDate={startDate}
                    group={group}
                />
            )}
            {group === 'senior' && !cycleLoading && !menuLoading && (
                <SeniorOrderTable
                    weekNumber={weekNumber}
                    weekOffset={weekOffset}
                    weeklyMenuData={weeklyMenuData}
                    startDate={startDate}
                    group={group}
                />
            )}
        </div>
    );
};

export default OrderFormBase;