import React from 'react';
import { Row, Col } from "react-bootstrap";
import { createEmptyWeek } from '../../../../../../utility/utility';

function CycleSelector({
  menuCycles, setCycleId, setWeeklyMenuId, setCycleName, setLoopInterval, setIsLooping,
  setTemplateId, setSelectedGroup, setNewCycle, newCycle, setWeeks, setTemplateName, setShowModal,
  lunchCount, supperCount
}) {
  const handleTemplateChange = (e) => {
    const selectedCycleId = e.target.value;
    const selectedCycle = menuCycles?.data?.MenuCycle.find(cycle => cycle._id === selectedCycleId);
    if (selectedCycle) {
      setCycleId(selectedCycle._id);
      setWeeklyMenuId(selectedCycle.weeklyMenus);
      setCycleName(selectedCycle.cycleName);
      setLoopInterval(selectedCycle.loopInterval);
      setIsLooping(selectedCycle.isLooping);
      setTemplateId(selectedCycle.templateId);
      setSelectedGroup(selectedCycle.group);
      setNewCycle(false);
    }
  };

  const toggleChange = () => {
    setNewCycle(prev => !prev);
    if (!newCycle) { // Now newCycle is a prop
      setWeeks([createEmptyWeek(1, lunchCount, supperCount)]);
      setCycleName('');
      setLoopInterval(6);
      setIsLooping(false);
      setCycleId('');
      setWeeklyMenuId('');
      setTemplateName('');
      setTemplateId('');
      setSelectedGroup(null);
    } else {
      setWeeks([]);
    }
  };

  return (
    <Row className="mb-4">
      <Col sm={6}>
        <select className="form-control" onChange={handleTemplateChange}>
          <option value="">Välj en loop</option>
          {menuCycles?.data?.MenuCycle.map((cycle) => (
            <option key={cycle._id} value={cycle._id}>
              {cycle.cycleName} ({cycle.group})
            </option>
          ))}
        </select>
      </Col>
      <Col sm={6} className="text-end d-flex gap-3">
        <button className="mainButtonLite text-light" onClick={toggleChange}>
          Ny
        </button>
        <button className="mainButtonLite text-light" onClick={() => setShowModal(true)}>
          Active & Datum
        </button>
      </Col>
    </Row>
  );
}

export default CycleSelector;